import { SaleDetailContext } from "../../contexts/SaleDetailContext";
import DateUtil from "../../utils/DateUtil";
import GeneralUtil from "../../utils/GeneralUtil";
import { useContext } from "react";
import { Button, Card, List, Table } from "antd";
import { useMediaQuery } from "react-responsive";

const SaleDetailConsumptions = () => {

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const { data, setOpenDetailModal, setModelDetail } = useContext(SaleDetailContext);

    return (
        <Card title={'Consumos registrados'}>
            {isMobile ?
                <>
                    <List
                        itemLayout="vertical"
                        dataSource={data}
                        renderItem={(model, index) => (
                            <List.Item key={index}>
                                <Card bordered={false}>
                                    <div className="RowContainer">
                                        <div className="ColumnContainer">
                                            <div><strong>Tipo:</strong> {model.type === 'ROOM' && 'HABITACIÓN'} {model.type === 'PRODUCT' && 'PRODUCTO'}</div>
                                            <div><strong>Monto:</strong> {GeneralUtil.getMoney(model.amount)}</div>
                                            <div><strong>Pagado:</strong> {model.paid ? 'PAGADO' : 'PENDIENTE'}</div>
                                            <div><strong>Registrado:</strong> {DateUtil.ToSimpleString(model.created_at)}</div>
                                        </div>
                                        <div className="ColumnContainer ColumnContainerVentas" style={{ width: '100%' }}>
                                            <Button onClick={() => {
                                                setModelDetail(model);
                                                setOpenDetailModal(true);
                                            }}>Ver Detalle</Button>
                                        </div>
                                    </div>
                                </Card>
                            </List.Item>
                        )}
                    />
                </> :
                <Table
                    pagination={false}
                    bordered
                    dataSource={data}
                    rowKey={(record) => record.consumption_id}
                    columns={[
                        {
                            title: 'Tipo', width: 100, key: 'type', render: (model) => {
                                return (
                                    <>
                                        {model.type === 'ROOM' && 'HABITACIÓN'}
                                        {model.type === 'PRODUCT' && 'PRODUCTO'}
                                    </>

                                );
                            }
                        },
                        {
                            title: 'Monto', width: 100, key: 'dates', render: (model) => {
                                return (
                                    <>{GeneralUtil.getMoney(model.amount)}</>
                                );
                            }
                        },
                        {
                            title: 'Pagado', width: 100, key: 'dates', render: (model) => {
                                return (
                                    <>{model.paid ? 'PAGADO' : 'PENDIENTE'}</>
                                );
                            }
                        },
                        {
                            title: 'Registrado', dataIndex: 'created_at', width: 200, key: 'created_at', render: (value) => {
                                return (<>{DateUtil.ToSimpleString(value)}</>);
                            }
                        },
                        {
                            title: 'Opciones', key: 'Acciones', width: 150, render: (value) => (
                                <Button onClick={() => {
                                    setModelDetail(value);
                                    setOpenDetailModal(true);
                                }}>Ver Detalle</Button>
                            )
                        },
                    ]}
                />
            }
        </Card>
    );

};

export default SaleDetailConsumptions;