import { Button, Form, Input, Modal, Select, Spin } from "antd";
import { MonitoringContext } from "../../contexts/MonitoringContext";
import { useContext, useState } from "react";
import RulesHelper from "../../helpers/RulesHelper";

const ModalExistGuest = () => {

    const {
        modelGuest,
        modalExit,
        setModalExit,
        setModelGuest,
        getExitGuestService,
        formSale,
        loading
    } = useContext(MonitoringContext);

    const [showExtraInputs, setShowExtraInputs] = useState(false);

    const onCancel = () => {
        setModalExit(false);
        setModelGuest(null);
        formSale.resetFields();
    };

    const onFinish = (values) => {
        getExitGuestService(values);
    };

    const onOk = () => {
        getExitGuestService({
            sale_type: 'BOLETA',
            type_of_transfer: 'YAPE'
        });
    };

    if (modelGuest && modelGuest.customer_account_paid) {
        return (
            <Modal
                title={'Registro de salida'}
                open={modalExit}
                onOk={onOk}
                onCancel={onCancel}
            >
                <p>Seguro que quiere registrar la salida de {modelGuest.customer_name} {modelGuest.customer_lastname} de la habitación {modelGuest.room_number}</p>
            </Modal>
        );
    }

    return (modelGuest && (
        <Modal
            title={'Registro de salida'}
            open={modalExit}
            footer={null}
            onCancel={onCancel}
        >
            <Spin spinning={loading}>
                <Form
                    form={formSale}
                    layout="vertical"
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item name={"sale_type"} label={"Tipo de venta"} rules={RulesHelper.required()}>
                        <Select
                            placeholder='Por favor, selecciona un tipo de pago.'
                            allowClear
                            options={[
                                { label: 'Factura', value: 'FACTURA' },
                                { label: 'Boleta', value: 'BOLETA' }
                            ]}
                            onChange={(value) => {
                                setShowExtraInputs(value === 'FACTURA');
                            }}
                        />
                    </Form.Item>
                    <Form.Item name={"type_of_transfer"} label={"Tipo de transferencia"} rules={RulesHelper.required()}>
                        <Select
                            placeholder='Por favor, selecciona un tipo de transferencia.'
                            allowClear
                            options={[
                                { label: 'YAPE', value: 'YAPE' },
                                { label: 'PLIN', value: 'PLIN' },
                                { label: 'TRANSFERENCIA', value: 'TRANSFERENCIA' },
                                { label: 'EFECTIVO', value: 'EFECTIVO' },
                                { label: 'TARJETA DEBITO', value: 'TARJETA DEBITO' },
                                { label: 'TARJETA CREDITO', value: 'TARJETA CREDITO' }
                            ]}
                        />
                    </Form.Item>
                    {showExtraInputs && (
                        <>
                            <Form.Item name={'ruc'} label='RUC' rules={RulesHelper.max(11)}>
                                <Input />
                            </Form.Item>
                            <Form.Item name={'razon_social'} label='Razón Social' rules={RulesHelper.max(250)}>
                                <Input />
                            </Form.Item>
                        </>
                    )}
                    <Form.Item>
                        <Button type="primary" htmlType="submit">Registrar</Button>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    ));

};

export default ModalExistGuest;