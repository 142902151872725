import { Modal } from "antd";
import { ConsumptionContext } from "../../contexts/ConsumptionContext";
import GeneralUtil from "../../utils/GeneralUtil";
import { useContext } from "react";
import DateUtil from "../../utils/DateUtil";

const ConsumptionDisableModal = () => {

    const {
        openDisableModal, setOpenDisableModal,
        disableModel, setDisableModel,
        disableConsumption
    } = useContext(ConsumptionContext);

    const onCancel = () => {
        setDisableModel(null);
        setOpenDisableModal(false);
    };

    const onOk = () => {
        disableConsumption();
    };

    return (disableModel &&
        <Modal
            title={'Eliminar consumo'}
            open={openDisableModal}
            onCancel={onCancel}
            onOk={onOk}
        >
            <p>¿Está seguro que quiere eliminar el consumo por
                {disableModel.type === 'ROOM' && ' ALOJAMIENTO '}
                {disableModel.type === 'PRODUCT' && ' PRODUCTO '}
                con monto {GeneralUtil.getMoney(disableModel.amount)}
                registrado el {DateUtil.ToSimpleString(disableModel.created_at)}?
            </p>
        </Modal>
    );

};

export default ConsumptionDisableModal;