import GeneralHelper from "../helpers/GeneralHelper";
import ApiService from "./ApiService";

class ProductService {
    pagination(params, page_number, page_size, product_active) {
        return ApiService.get('/products', { ...params, page_number, page_size, product_active }, true);
    }
    create(body) {
        return ApiService.post('/products', { ...body, created_at: GeneralHelper.getLimaPeruTime() }, true);
    }
    update(id, body) {
        return ApiService.patch(`/products/${id}`, { ...body, type: 'data' }, true);
    }
    updateActive(id, active) {
        return ApiService.patch(`/products/${id}`, { product_active: active, type: 'active' }, true);
    }
}

export default new ProductService();