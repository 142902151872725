import dayjs from "dayjs";
import ParseHelper from "../../helpers/ParseHelper";
import { Button, Card, Descriptions, Form, Input, message, Spin } from "antd";
import GuestFormRoomSection from "./GuestFormRoomSection";
import { useContext } from "react";
import GuestFormCustomerSection from "./GuestFormCustomerSection";
import { GuestFormContext } from "../../contexts/GuestFormContext";
import RulesHelper from "../../helpers/RulesHelper";

const GuestForm = () => {

    const {
        form, loading, setLoading,
        customerId, guest, booking,
        createGuest, updateGuest, createGuestByBooking,
        items, setOpenSaleModal, formSale
    } = useContext(GuestFormContext);

    const onFinish = (values) => {

        const {
            start_day, start_time, end_day, end_time, habitacion, habitacionPrecio,
            document_type, document_number, name, lastname, gender, phone, address, company,
            guest_vehicle_plate, guest_observations
        } = values;

        const customer_data = {
            id: customerId,
            document_type, document_number, name, lastname, gender,
            phone: ParseHelper.parseStringOrNull(phone),
            address: ParseHelper.parseStringOrNull(address),
            company: ParseHelper.parseStringOrNull(company)
        };

        const start_date_dayjs = dayjs(`${start_day.format('YYYY-MM-DD')} ${start_time.format('HH:mm:00')}`);

        const end_date_dayjs = dayjs(`${end_day.format('YYYY-MM-DD')} ${end_time.format('HH:mm:00')}`);

        if (dayjs().isBefore(start_date_dayjs)) {
            message.error('Si la fecha de inicio es mayor a la fecha actual, registra una reserva.');
            return;
        }

        if (end_date_dayjs.isBefore(start_date_dayjs)) {
            message.error('La fecha y hora de salida tienen que ser después de la fecha y hora de llegada.');
            setLoading(false);
            return;
        }

        const start_date = start_date_dayjs.format(`YYYY-MM-DD HH:mm:00`);

        const end_date = end_date_dayjs.format(`YYYY-MM-DD HH:mm:00`);

        const room_id = habitacion;

        const room_price = habitacionPrecio;

        if (booking) {

            const body = {
                booking_id: booking.id,
                guest_vehicle_plate,
                guest_observations
            };

            setLoading(true);

            createGuestByBooking(body);

            return;

        }

        const body = { customer_data, start_date, end_date, room_id, room_price, guest_vehicle_plate, guest_observations };

        setLoading(true);

        guest ? updateGuest(body) : createGuest(body);

    };

    return (
        <Spin spinning={loading}>
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                onFinish={onFinish}
            >

                <GuestFormRoomSection />

                <GuestFormCustomerSection />

                <Card title={'Datos adicionales de ingreso'} style={{ marginBottom: '20px' }}>
                    <Form.Item label="Placa del vehículo" name="guest_vehicle_plate" rules={RulesHelper.maxNotRequired(50)}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Observaciones" name="guest_observations" rules={RulesHelper.maxNotRequired(200)}>
                        <Input.TextArea rows={5} />
                    </Form.Item>
                </Card>

                <Card title={'Resumen'} style={{ marginBottom: '20px' }}>
                    <Descriptions layout="vertical" items={items} bordered />
                </Card>

                {!guest ? (
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '10px' }}>
                        <Button type="primary" size="large" onClick={async () => {
                            form.validateFields().then(() => {
                                formSale.resetFields();
                                setOpenSaleModal(true);
                            }).catch(error => {
                                console.log(error);
                            });
                        }} >REGISTRAR CON VENTA</Button>
                        <Button type="primary" size="large" htmlType="submit" >REGISTRAR</Button>
                    </div>
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <Button type="primary" size="large" htmlType="submit" >ACTUALIZAR</Button>
                    </div>
                )}

            </Form>
        </Spin>
    );

};

export default GuestForm;