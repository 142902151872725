import { createContext, useEffect, useState } from "react";
import GeneralHelper from "../helpers/GeneralHelper";
import SaleService from "../services/SaleService";
import { message } from "antd";

export const SaleContext = createContext();

export const SaleProvider = ({ children }) => {

    const [showView, setShowView] = useState('sales');

    const [saleSelected, setSaleSelected] = useState(null);

    const [data, setData] = useState([]);

    const [tableParams, setTableParams] = useState(GeneralHelper.getTablePaginationDefault);

    const [formFilters, setFormFilters] = useState({
        full_name: '',
        document_number: '',
        start_date: '',
        end_date: '',
        sale_type: ''
    });

    const getSalesDefault = () => {
        SaleService.pagination(
            formFilters,
            tableParams.pagination.current,
            tableParams.pagination.pageSize
        ).then(response => {
            setData(response.data);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    total: response.count
                }
            });
        }).catch(err => {
            message.error('Ocurrió un error en el servicio.');
        });
    };

    const getSalesByFilter = (values) => {
        SaleService.pagination(
            values,
            1,
            tableParams.pagination.pageSize
        ).then(response => {
            setData(response.data);
            setFormFilters(values);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    total: response.count,
                    current: 1,
                }
            });
        }).catch(err => {
            message.error('Ocurrion un error con el servicio.');
        });
    };

    const getSalesByPagination = (page, pageSize) => {
        SaleService.pagination(
            formFilters,
            page,
            pageSize
        ).then(response => {
            setData(response.data);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    pageSize: pageSize,
                    current: page,
                }
            });
        }).catch(err => {
            message.error('Ocurrió un error en el servicio.');
        });
    };

    useEffect(() => {
        getSalesDefault();
    }, []);

    return (
        <SaleContext.Provider value={{
            data, tableParams,
            getSalesByFilter, getSalesByPagination,
            showView, setShowView,
            saleSelected, setSaleSelected
        }}>
            {children}
        </SaleContext.Provider>
    )

};