import { useContext } from "react";
import { ProductContext } from "../../contexts/ProductContext";
import { Switch } from "antd";

const ProductChangeData = () => {
    const { setSwitchDataType, switchDataType } = useContext(ProductContext);
    const onChange = (value) => {
        setSwitchDataType(value);
    };
    return (
        <div style={{ textAlign: 'end', marginBottom: '10px' }}>
            <Switch
                checkedChildren="Activos"
                unCheckedChildren="Eliminados"
                checked={switchDataType}
                onChange={onChange}
            />
        </div>
    );
};

export default ProductChangeData;