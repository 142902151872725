import LaundryMonitoringContainer from "../components/LaundryMonitoringComponents/LaundryMonitoringContainer";
import { LaundryMonitoringProvider } from "../contexts/LaundryMonitoringContext";

const LaundryMonitoringPage = () => {
    return (
        <>
            <h1 className="TituloContenido">Monitoreo Lavandería</h1>
            <LaundryMonitoringProvider>
                <LaundryMonitoringContainer />
            </LaundryMonitoringProvider>
        </>
    )
}

export default LaundryMonitoringPage;