import { createContext, useEffect, useState } from "react";
import EnvConfig from "../config/EnvConfig";
import LocalStorageHelper from "../helpers/LocalStorageHelper";

export const CleaningMonitoringContext = createContext();

export const CleaningMonitoringProvider = ({ children }) => {

    const [websocketData, setWebsocketData] = useState({});

    const [websocket, setWebSocket] = useState(null);

    const [data, setData] = useState([]);

    const [showView, setShowView] = useState('cleaning_monitoring_records');

    const [updateStatusModel, setUpdateStatusModel] = useState(null);

    const [filters, setFilters] = useState({
        status: 'SUCIO'
    });

    const filterData = (values) => {

        const { status } = values;

        setFilters({ status });

        if (websocket) {

            const accessToken = LocalStorageHelper.getAccessTokenData();
            const service = 'GET_ALL_CURRENT_ROOM_CLEANING_RECORDS';
            const message = { accessToken, service, params: { status }, websocket_connection_id: websocketData.websocket_connection_id };

            websocket.send(JSON.stringify(message));

        }

    };

    useEffect(() => {

        const ws = new WebSocket(EnvConfig.getWebsocketUrl());

        ws.onopen = () => {
            const accessToken = LocalStorageHelper.getAccessTokenData();
            const service = 'GET_ALL_CURRENT_ROOM_CLEANING_RECORDS';
            ws.send(JSON.stringify({ accessToken, service, params: filters }));
        };

        ws.onmessage = (event) => {
            const { data } = event;
            const json_data = JSON.parse(data);
            setWebsocketData(json_data);
        };

        setWebSocket(ws);

        return () => {
            if (ws) ws.close();
        };

    }, []);

    useEffect(() => {

        const { event } = websocketData;

        if (event === "ALL_CURRENT_ROOM_CLEANING_RECORDS_DATA") setData(websocketData.data);

    }, [websocketData]);

    return (
        <CleaningMonitoringContext.Provider value={{
            data,
            showView, setShowView,
            updateStatusModel, setUpdateStatusModel,
            filterData
        }}>
            {children}
        </CleaningMonitoringContext.Provider>
    );

};