import GeneralHelper from "../helpers/GeneralHelper";
import ApiService from "./ApiService";

class RoomCleaningService {

    static updateStatusByCleaner(room_cleaning_record_id, cleaner_email, body) {
        return ApiService.post(`/room-cleaning-records/${room_cleaning_record_id}/update-status`, {
            ...body,
            cleaner_email,
            created_at: GeneralHelper.getLimaPeruTime()
        }, true);
    }

    static updateStatusByApplicant(room_cleaning_record_id, applicant_email, body) {
        return ApiService.post(`/room-cleaning-records/${room_cleaning_record_id}/update-status`, {
            ...body,
            applicant_email,
            created_at: GeneralHelper.getLimaPeruTime()
        }, true);
    }

    static getDetail(room_cleaning_record_id) {
        return ApiService.get(`/room-cleaning-records/${room_cleaning_record_id}/detail`, {}, true);
    }

}

export default RoomCleaningService;