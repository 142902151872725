import { useNavigate } from "react-router-dom";
import RoleKeyHelper from "../helpers/RoleKeyHelper";
import RoutePathHelper from "../helpers/RoutePathHelper";
import LocalStorageHelper from "../helpers/LocalStorageHelper";
import { Form, message } from "antd";
import { createContext, useEffect } from "react";
import AuthService from "../services/AuthService";

export const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const redirectByRole = (role) => {
        let route = '';
        switch (role) {
            case RoleKeyHelper.getHotelReceptionist(): {
                route = RoutePathHelper.getMonitoring();
                break;
            }
            case RoleKeyHelper.getLaundryReceptionist(): {
                route = RoutePathHelper.getLaundryMonitoring();
                break;
            }
            case RoleKeyHelper.getHotelCleaningStaff(): {
                route = RoutePathHelper.getCleaningMonitoring();
                break;
            }
            case RoleKeyHelper.getAdminKey(): {
                route = RoutePathHelper.getDashboard();
                break;
            }
            default: {
                console.log('SIN PAGINA INICIO');
                message.error('CONTACTAR AL ADMINISTRADOR DEL SISTEMA.');
                return;
            }
        }
        navigate(route);
    }
    const login = (values) => {
        AuthService.login(values).then(response => {
            const { role, full_name, accessToken, refreshToken, access_token_expiration_datetime, refresh_token_expiration_datetime, token_id } = response;
            const user = { role, full_name, email: values.email };
            const userString = JSON.stringify(user);
            LocalStorageHelper.updateAccessToken(accessToken);
            LocalStorageHelper.updateRefreshToken(refreshToken);
            LocalStorageHelper.updateAccessTokenExpiration(access_token_expiration_datetime);
            LocalStorageHelper.updateRefreshTokenExpiration(refresh_token_expiration_datetime);
            LocalStorageHelper.updateUser(userString);
            LocalStorageHelper.updateTokenId(token_id);
            redirectByRole(response.role);
        }).catch(error => {
            console.log(error);
            message.error('Ocurrió un error con el servicio de autentificación.');
        });
    };
    useEffect(() => {
        const localStorageUser = LocalStorageHelper.getUserData();
        if (localStorageUser) {
            const user = JSON.parse(localStorageUser);
            const { role } = user;
            redirectByRole(role);
        }
    }, []);
    return (
        <LoginContext.Provider value={{ form, login }}>
            {children}
        </LoginContext.Provider>
    );
};