import { Form, message } from "antd";
import { createContext, useEffect, useState } from "react";
import SettingService from "../services/SettingService";

export const SettingContext = createContext();

export const SettingProvider = ({ children }) => {
    const [dataSource, setDataSource] = useState([]);
    const [model, setModel] = useState(null);
    const [form] = Form.useForm();
    const [openModal, setOpenModal] = useState(false);
    const [filters, setFilters] = useState({ description: '' });
    const getSettingsDefault = () => {
        SettingService.getList({
            description: filters.description
        }).then(response => {
            setDataSource(response);
        }).catch(err => {
            message.error('Ocurrió un error con el servicio.');
        });
    };
    const getSettingsByFilters = (values) => {
        SettingService.getList(values).then(response => {
            setDataSource(response);
            setFilters(values);
        }).catch(err => {
            message.error('Ocurrió un error con el servicio.');
        });
    };
    const updateSetting = (setting_value) => {
        SettingService.update(model.id, { setting_key: model.setting_key, setting_value }).then(response => {
            getSettingsDefault();
            setOpenModal(false);
            setModel(null);
            form.resetFields();
            message.success('Se actualizó la configuración.');
        }).catch(err => {
            message.error('Ocurrió un error con el servicio.');
        });
    };
    const getMenu = (setting) => {
        const items = [
            {
                key: 'edit', label: 'Editar', onClick: () => {
                    setModel(setting);
                    form.setFieldsValue({ ...setting });
                    setOpenModal(true);
                }
            }
        ];
        const menu = { items };
        return menu;
    };
    useEffect(() => {
        getSettingsDefault();
    }, []);
    return (
        <SettingContext.Provider value={{
            form,
            dataSource,
            model, setModel,
            openModal, setOpenModal,
            filters, setFilters,
            getMenu, getSettingsByFilters, getSettingsDefault, updateSetting
        }}>
            {children}
        </SettingContext.Provider>
    );
};