import { createContext, useEffect, useState } from "react";
import GeneralHelper from "../helpers/GeneralHelper";
import ProductService from "../services/ProductService";
import { message } from "antd";
import GeneralUtil from "../utils/GeneralUtil";
import ConsumptionService from "../services/ConsumptionService";
import { useNavigate } from "react-router-dom";

export const ConsumptionProductContext = createContext();

export const ConsumptionProductProvider = ({ children, customerAccountModel, onBack }) => {

    const navigate = useNavigate();

    const [data, setData] = useState([]);

    const [dataSelected, setDataSelected] = useState([]);

    const [openDeleteProduct, setOpenDeleteProduct] = useState(false);

    const [modalProductSelected, setModalProductSelected] = useState(false);

    const [productSelected, setProductSelected] = useState(null);

    const [tableParams, setTableParams] = useState(GeneralHelper.getTablePaginationDefault());

    const [formFilters, setFormFilters] = useState({
        product_code: '',
        product_name: ''
    });

    const [items, setItems] = useState([
        { key: '1', label: 'Total', children: 'S/ 0.00' }
    ]);

    const getProductsDefault = () => {
        ProductService.pagination(
            formFilters,
            tableParams.pagination.current,
            tableParams.pagination.pageSize,
            true
        ).then(response => {
            setData(response.data);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    total: response.count
                }
            });
        }).catch(error => {
            console.log(error);
            message.error('Ocurrion un error con el servicio.');
        });
    };

    const getProductsByFilter = (values) => {
        ProductService.pagination(
            values,
            1,
            tableParams.pagination.pageSize,
            true
        ).then(response => {
            setData(response.data);
            setFormFilters(values);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    total: response.count,
                    current: 1,
                }
            });
        }).catch(error => {
            console.log(error);
            message.error('Ocurrion un error con el servicio.');
        });
    };

    const getProductsByPagination = (page, pageSize) => {
        ProductService.pagination(
            formFilters,
            page,
            pageSize,
            true
        ).then(response => {
            setData(response.data);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    pageSize: pageSize,
                    current: page,
                }
            });
        }).catch(error => {
            console.log(error);
            message.error('Ocurrion un error con el servicio.');
        });
    };

    const addProductSelected = (value) => {
        const tempDataSelected = [...dataSelected];
        tempDataSelected.push(value);
        const total = tempDataSelected.reduce((acc, item) => acc + Number(item.total), 0);
        setItems([
            { key: '1', label: 'Total', children: GeneralUtil.getMoney(total.toFixed(2)), amount: total }
        ]);
        setDataSelected(tempDataSelected);
        setModalProductSelected(false);
        setProductSelected(null);
    };

    const deleteProductSelected = (product_id) => {
        let tempDataSelected = [...dataSelected];
        tempDataSelected = tempDataSelected.filter(item => item.id !== product_id);
        const total = tempDataSelected.reduce((acc, item) => acc + Number(item.total), 0);
        setItems([
            { key: '1', label: 'Total', children: GeneralUtil.getMoney(total.toFixed(2)), amount: total }
        ]);
        setDataSelected(tempDataSelected);
        setOpenDeleteProduct(false);
        setProductSelected(null);
    };

    const updateProductSelected = (value) => {
        const tempDataSelected = [...dataSelected];
        const data = tempDataSelected.find(item => item.id === value.id);
        data.count = value.count;
        data.total = value.total;
        const total = tempDataSelected.reduce((acc, item) => acc + Number(item.total), 0);
        setItems([
            { key: '1', label: 'Total', children: GeneralUtil.getMoney(total.toFixed(2)), amount: total }
        ]);
        setDataSelected(tempDataSelected);
        setModalProductSelected(false);
        setProductSelected(null);
    };

    const handlerAddConsumption = () => {

        if (dataSelected.length === 0) {
            return message.warning('Por favor, agrega productos a la lista .');
        }

        const products = dataSelected.map(item => {
            return {
                product_id: item.id,
                stock: Number(item.stock) - Number(item.count),
                change_amount: Number(item.count),
                unit_price: item.product_price
            };
        });

        const body = {
            customer_account_id: customerAccountModel.id,
            customer_id: customerAccountModel.customer_id,
            amount: Number(items[0].amount).toFixed(2),
            products
        };

        ConsumptionService.createProductConsumption(body).then(response => {
            message.success('Se registró el consumo.');
            if (onBack) onBack();
        }).catch(error => {
            console.log(error);
            message.error('Ocurrion un error con el servicio.');
        })

    };

    useEffect(() => {
        getProductsDefault();
    }, []);

    return (
        <ConsumptionProductContext.Provider value={{
            data, tableParams, formFilters,
            dataSelected,
            getProductsDefault, getProductsByFilter, getProductsByPagination,
            modalProductSelected, setModalProductSelected,
            productSelected, setProductSelected,
            addProductSelected, updateProductSelected, deleteProductSelected,
            openDeleteProduct, setOpenDeleteProduct,
            items, handlerAddConsumption
        }}>
            {children}
        </ConsumptionProductContext.Provider>
    );

};