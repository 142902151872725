import { useContext } from "react";
import SaleFilter from "./SaleFilter";
import SaleRecords from "./SaleRecords";
import { SaleContext } from "../../contexts/SaleContext";
import { Breadcrumb, Card } from "antd";
import { SaleDetailProvider } from "../../contexts/SaleDetailContext";
import SaleDetailDescription from "../SaleDetailComponents/SaleDetailDescription";
import SaleDetailConsumptions from "../SaleDetailComponents/SaleDetailConsumptions";
import SaleDetailModal from "../SaleDetailComponents/SaleDetailModal";

const SaleContainer = () => {

    const {
        showView, setShowView,
        saleSelected, setSaleSelected
    } = useContext(SaleContext);

    return (
        <>
            {showView === 'sales' && (
                <>
                    <h1 className="TituloContenido">Ventas</h1>
                    <Card title={'LISTA DE VENTAS'}>
                        <SaleFilter />
                        <SaleRecords />
                    </Card>
                </>
            )}

            {showView === 'sale_detail' && (
                <>
                    <Breadcrumb
                        style={{ marginBottom: '20px' }}
                        items={[
                            {
                                title: <div style={{ cursor: 'pointer' }}>Ventas</div>,
                                onClick: () => {
                                    setSaleSelected(null);
                                    setShowView('sales');
                                }
                            },
                            {
                                title: 'Detalle de venta',
                            },
                        ]}
                    />

                    <SaleDetailProvider sale={saleSelected}>
                        <SaleDetailDescription />
                        <SaleDetailConsumptions />
                        <SaleDetailModal />
                    </SaleDetailProvider>
                </>
            )}

        </>
    );

};

export default SaleContainer;