import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { RoomContext } from "../../contexts/RoomContext";
import { Button, Card, Dropdown, List, Pagination, Table } from "antd";
import GeneralUtil from "../../utils/GeneralUtil";
import DateUtil from "../../utils/DateUtil";
import { SettingOutlined } from "@ant-design/icons";
import RoomTypeHelper from "../../helpers/RoomTypeHelper";

const RoomRecords = () => {

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const {
        data, tableParams, getRoomsByPagination,
        setModel, form, setOpen, switchDataType,
        setOpenDelete, setShowView
    } = useContext(RoomContext);

    const onChangeTable = (pagination, filters, sorter) => {
        getRoomsByPagination(pagination.current, pagination.pageSize);
    };

    const onChangeList = (page, pageSize) => {
        getRoomsByPagination(page, pageSize);
    };

    const onShowSizeChange = (current, size) => {
        getRoomsByPagination(current, size);
    };

    const getMenu = (value) => {

        const menu = { items: [] };

        const optionEdit = {
            key: 'edit', label: 'Editar', onClick: () => {
                setModel(value);
                form.setFieldsValue({ ...value });
                setOpen(true);
            }
        };

        const optionDelete = {
            key: 'delete', label: 'Eliminar', onClick: () => {
                setModel(value);
                setOpenDelete(true);
            }
        };

        const optionEnable = {
            key: 'enable', label: 'Recuperar', onClick: () => {
                setModel(value);
                setOpenDelete(true);
            }
        };

        const optionHistoric = {
            key: 'historic', label: 'Histórico', onClick: () => {
                setModel(value);
                setShowView('historic');
            }
        }

        if (switchDataType) {
            menu.items.push(optionEdit);
            menu.items.push(optionHistoric);
            menu.items.push(optionDelete);
        } else {
            menu.items.push(optionEnable);
        }

        return menu;

    };

    return (isMobile ?
        <>
            <List
                itemLayout="vertical"
                dataSource={data}
                renderItem={(model, index) => (
                    <List.Item>
                        <Card bordered={false}>
                            <div className="RowContainer">
                                <div className="ColumnContainer">
                                    <div><strong>Número:</strong> {model.room_number}</div>
                                    <div><strong>Precio:</strong> {GeneralUtil.getMoney(model.room_price)}</div>
                                    <div><strong>Tipo:</strong> {RoomTypeHelper.getDescription(model.room_type)}</div>
                                    <div><strong>Registrado:</strong> {DateUtil.ToSimpleString(model.created_at)}</div>
                                </div>
                                <div className="ColumnContainer ColumnContainerVentas" style={{ width: '100%' }}>
                                    <Dropdown menu={getMenu(model)} trigger={['click']} >
                                        <Button icon={<SettingOutlined />}>Acciones</Button>
                                    </Dropdown>
                                </div>
                            </div>
                        </Card>
                    </List.Item>
                )}
            />
            <Pagination
                pageSize={tableParams.pagination.pageSize}
                showSizeChanger={tableParams.pagination.showSizeChanger}
                onShowSizeChange={onShowSizeChange}
                total={tableParams.pagination.total}
                pageSizeOptions={tableParams.pagination.pageSizeOptions}
                current={tableParams.pagination.current}
                onChange={onChangeList}
            />
        </> :
        <Table
            pagination={tableParams.pagination}
            bordered
            dataSource={data}
            rowKey={(record) => record.id}
            onChange={onChangeTable}
            columns={[
                {
                    title: 'Habitación', key: 'room', render: (model) => {
                        return (
                            <>
                                <div><strong>Número:</strong> {model.room_number}</div>
                                <div><strong>Precio:</strong> {GeneralUtil.getMoney(model.room_price)}</div>
                                <div><strong>Registrado:</strong> {DateUtil.ToSimpleString(model.created_at)}</div>
                            </>
                        );
                    }
                },
                {
                    title: 'Tipo', dataIndex: 'room_type', key: 'room_type', render: (value) => {
                        return (<>{RoomTypeHelper.getDescription(value)}</>)
                    }
                },
                {
                    title: 'Opciones', key: 'Acciones', width: 150, render: (value) => (
                        <Dropdown trigger={['click']} menu={getMenu(value)}>
                            <Button icon={<SettingOutlined />}>Acciones</Button>
                        </Dropdown>
                    )
                },
            ]}
        />
    );
};

export default RoomRecords;