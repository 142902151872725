import { useContext } from "react";
import { ProductContext } from "../../contexts/ProductContext";
import { Modal } from "antd";

const ProductActiveModal = () => {
    const { switchDataType, openDelete, model, setOpenDelete, setModel, updateActive } = useContext(ProductContext);
    const onCancel = () => {
        setModel(null);
        setOpenDelete(false);
    };
    const onOk = () => {
        updateActive(!switchDataType);
    };
    return (model && (
        <Modal title={switchDataType ? 'Eliminar producto' : 'Recuperar producto'} open={openDelete} onCancel={onCancel} onOk={onOk}>
            <p>¿Está seguro que quiere {switchDataType ? 'eliminar' : 'recuperar'} el producto {model.product_name} con código {model.product_code}?</p>
        </Modal>
    ));
};

export default ProductActiveModal;