import { createContext, useEffect, useState } from "react";
import EnvConfig from "../config/EnvConfig";
import LocalStorageHelper from "../helpers/LocalStorageHelper";
import BookingService from "../services/BookingService";
import GuestService from "../services/GuestService";
import { Form, message } from "antd";
import RoomCleaningService from "../services/RoomCleaningService";

export const MonitoringContext = createContext();

export const MonitoringProvider = ({ children }) => {

    const [showView, setShowView] = useState('disponibilities');

    const [websocketData, setWebsocketData] = useState({});

    const [data, setData] = useState([]);

    const [formDataModel, setFormDataModel] = useState(null);

    const [modalExit, setModalExit] = useState(false);

    const [modelGuest, setModelGuest] = useState(null);

    const [roomCleaningModel, setRoomCleaningModel] = useState(null);

    const [formSale] = Form.useForm();

    const [loading, setLoading] = useState(false);

    useEffect(() => {

        const ws = new WebSocket(EnvConfig.getWebsocketUrl());

        ws.onopen = () => {
            const accessToken = LocalStorageHelper.getAccessTokenData();
            const service = 'GET_ALL_DISPONIBILITIES';
            const message = { accessToken, service };
            console.log('DATOS ENVIADOS: ', message);
            ws.send(JSON.stringify(message));
        };

        ws.onmessage = (event) => {
            const { data } = event;
            const json_data = JSON.parse(data);
            console.log(json_data);
            setWebsocketData(json_data);
        };

        return () => {
            if (ws) ws.close();
        };

    }, []);

    useEffect(() => {

        const { event } = websocketData;

        if (event === "ALL_DISPONIBILITIES_DATA") setData(websocketData.data);

    }, [websocketData]);

    const getFormDataByBookingIdService = (booking_id) => {
        BookingService.formData(booking_id).then(response => {
            setFormDataModel(response);
            setShowView('create_guest');
        }).catch(error => {
            console.log(error);
            message.error('Ocurrio un error con el servicio.');
        });
    };

    const getExitGuestService = (values) => {
        setLoading(true);
        GuestService.updateExit(modelGuest.guest_id, values).then(response => {
            message.success('Se registró la salida y la venta del huesped.');
            setModalExit(false);
            setModelGuest(null);
            formSale.resetFields();
        }).catch(error => {
            console.log(error);
            message.error('Ocurrio un error con el servicio.');
        }).finally(() => {
            setLoading(false);
        });
    };

    const getRoomCleaningDetail = (room_cleaning_record_id) => {
        RoomCleaningService.getDetail(room_cleaning_record_id).then(response => {
            setRoomCleaningModel(response);
            setShowView('room_cleaning_record_form');
        }).catch(error => {
            console.log(error);
            console.log('Ocurrio un error con el servicio.');
        });
    };

    return (
        <MonitoringContext.Provider value={{
            data,
            showView, setShowView,
            formDataModel, setFormDataModel,
            getFormDataByBookingIdService, getExitGuestService,
            modalExit, setModalExit,
            modelGuest, setModelGuest,
            roomCleaningModel, setRoomCleaningModel,
            getRoomCleaningDetail,
            formSale, loading
        }}>
            {children}
        </MonitoringContext.Provider>
    );

};