import { createContext, useEffect, useState } from "react";
import EnvConfig from "../config/EnvConfig";
import LocalStorageHelper from "../helpers/LocalStorageHelper";

export const DashbaordAdminContext = createContext();

export const DashboardAdminProvider = ({ children }) => {

    const [websocketData, setWebsocketData] = useState({});

    const [data, setData] = useState(null);

    useEffect(() => {

        const ws = new WebSocket(EnvConfig.getWebsocketUrl());

        ws.onopen = () => {
            const accessToken = LocalStorageHelper.getAccessTokenData();
            const service = 'SERVICE_ADMIN_DASHBOARD';
            ws.send(JSON.stringify({ accessToken, service }));
        };

        ws.onmessage = (event) => {
            const { data } = event;
            const json_data = JSON.parse(data);
            setWebsocketData(json_data);
        };

        return () => {
            if (ws) ws.close();
        };

    }, []);

    useEffect(() => {

        const { event } = websocketData;

        if (event === "ADMIN_DASHBOARD_DATA") {
            setData(websocketData.data);
            console.log(websocketData.data);
        }

    }, [websocketData]);

    return (
        <DashbaordAdminContext.Provider value={{
            data
        }}>
            {children}
        </DashbaordAdminContext.Provider>
    );

};