import { useContext } from "react";
import { BookingCalendarContext } from "../../contexts/BookingCalendarContext";
import dayjs from "dayjs";
import { useMediaQuery } from "react-responsive";
import { Breadcrumb, Button, Card, List, Select } from "antd";
import GeneralHelper from "../../helpers/GeneralHelper";
import BookingCalendarCreate from "./BookingCalendarCreate";
import { BookingFormProvider } from "../../contexts/BookingFormContext";
import BookingForm from "../BookingFormComponents/BookingForm";
import { ConsumptionProvider } from "../../contexts/ConsumptionContext";
import ConsumptionContainer from "../ConsumptionComponents/ConsumptionContainer";
import BookingCalendarDelete from "./BookingCalendarDelete";

const BookingCalendarContainer = () => {

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const {
        data, currentDate, setCurrentDate,
        setModel, setOpen,
        showView, setShowView,
        modelBooking, setModelBooking,
        getDefaultData
    } = useContext(BookingCalendarContext);

    const today = GeneralHelper.getDayJsLimaPeru();

    const months = dayjs.months();

    const years = Array.from({ length: 10 }, (_, i) => dayjs().year() - 5 + i);

    const daysInMonth = currentDate.daysInMonth();

    const firstDayOfMonth = currentDate.startOf('month').day();

    const adjustedFirstDay = (firstDayOfMonth === 0 ? 7 : firstDayOfMonth) - 1;

    const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);

    const handleMonthChange = (value) => {
        setCurrentDate(currentDate.month(value));
    };

    const handleYearChange = (value) => {
        setCurrentDate(currentDate.year(value));
    };

    const onClick = (match) => {
        setModel(match);
        setOpen(true);
    };

    return (
        <>
            {showView === 'calendar' && (
                <div className="calendar">

                    <BookingCalendarCreate />

                    <header className="calendar-header">
                        <Select value={currentDate.month()} onChange={handleMonthChange} style={{ width: '150px' }}>
                            {months.map((month, index) => (
                                <Select.Option key={index} value={index}>
                                    {month}
                                </Select.Option>
                            ))}
                        </Select>
                        <Select value={currentDate.year()} onChange={handleYearChange}>
                            {years.map((year) => (
                                <Select.Option key={year} value={year}>
                                    {year}
                                </Select.Option>
                            ))}
                        </Select>
                    </header>

                    <div className="calendar-grid">
                        {!isMobile && ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'].map((day, index) => (
                            <div key={index} className="day-name">{day}</div>
                        ))}
                        {!isMobile && Array.from({ length: adjustedFirstDay }).map((_, i) => (
                            <div key={i} className="empty"></div>
                        ))}
                        {isMobile && (
                            <List
                                itemLayout="vertical"
                                dataSource={daysArray}
                                renderItem={(model, index) => {

                                    const day = model;

                                    const isToday = today.date() === day && today.month() === currentDate.month() && today.year() === currentDate.year();

                                    const dayString = currentDate.format(`YYYY-MM-${day} 00:00:01`);

                                    const tempDayJs = dayjs(dayString);

                                    let match = null;

                                    const tempData = [];

                                    data.map(x => {

                                        const start_date = dayjs(x.start_date).set('hour', 0).set('minute', 0).set('second', 0);

                                        const end_date = dayjs(x.end_date).set('hour', 24).set('minute', 0).set('second', 0);

                                        if (tempDayJs.isBefore(end_date) && tempDayJs.isAfter(start_date)) {
                                            match = true;
                                            tempData.push(x);
                                        }

                                    });

                                    if (match) {
                                        match = tempData;
                                    }

                                    return (
                                        <List.Item key={index}>
                                            <Card bordered={false} title={`Día ${model}`} style={{ width: '100%', height: '150px', borderColor: isToday ? '#f5e39d' : '', borderStyle: isToday ? 'solid' : 'none' }}>
                                                {match && (
                                                    <Button style={{ width: '100%' }} onClick={() => {
                                                        onClick(match)
                                                    }}>Ver Reservas</Button>
                                                )}
                                            </Card>
                                        </List.Item>
                                    )
                                }}
                            />
                        )}
                        {!isMobile && daysArray.map((day) => {

                            const isToday = today.date() === day && today.month() === currentDate.month() && today.year() === currentDate.year();

                            const dayString = currentDate.format(`YYYY-MM-${day} 00:00:01`);

                            const tempDayJs = dayjs(dayString);

                            let match = null;

                            const tempData = [];

                            data.map(x => {

                                const start_date = dayjs(x.start_date).set('hour', 0).set('minute', 0).set('second', 0);

                                const end_date = dayjs(x.end_date).set('hour', 24).set('minute', 0).set('second', 0);

                                if (tempDayJs.isBefore(end_date) && tempDayJs.isAfter(start_date)) {
                                    match = true;
                                    tempData.push(x);
                                }

                            });

                            if (match) {
                                match = tempData;
                            }

                            return (
                                <div key={day} className="day">
                                    <Card bordered={false} style={{ width: '100%', height: '150px', borderColor: isToday ? '#f5e39d' : '', borderStyle: isToday ? 'solid' : 'none' }} title={day}>
                                        {match && (
                                            <Button onClick={() => { onClick(match) }}>Ver Reservas</Button>
                                        )}
                                    </Card>
                                </div>
                            );
                        })}
                    </div>

                    <BookingCalendarDelete />

                </div>
            )}

            {showView === 'booking_form' && (
                <>
                    <Breadcrumb
                        style={{ marginBottom: '20px' }}
                        items={[
                            {
                                title: <div style={{ cursor: 'pointer' }}>Calendario</div>,
                                onClick: () => {
                                    setShowView('calendar');
                                    setModelBooking(null);
                                }
                            },
                            {
                                title: <div style={{ cursor: 'default' }}>Formulario Reservas</div>,
                            },
                        ]}
                    />

                    <BookingFormProvider
                        booking={modelBooking}
                        onBack={() => {
                            setShowView('calendar');
                            setModelBooking(null);
                            getDefaultData();
                        }}
                    >
                        <BookingForm />
                    </BookingFormProvider>

                </>
            )}

            {showView === 'consumptions' && (
                <>
                    <Breadcrumb
                        style={{ marginBottom: '20px' }}
                        items={[
                            {
                                title: <div style={{ cursor: 'pointer' }}>Calendario</div>,
                                onClick: () => {
                                    setShowView('calendar');
                                    setModelBooking(null);
                                }
                            },
                            {
                                title: <div style={{ cursor: 'default' }}>Consumos</div>,
                            },
                        ]}
                    />

                    <ConsumptionProvider customer_account_id={modelBooking.customer_account_id}>
                        <ConsumptionContainer />
                    </ConsumptionProvider>
                </>
            )}
        </>
    );
};

export default BookingCalendarContainer;