import { useMediaQuery } from "react-responsive";
import { GuestContext } from "../../contexts/GuestContext";
import { useContext } from "react";
import DateUtil from "../../utils/DateUtil";
import { Button, Card, Dropdown, List, Pagination, Table } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import ParseHelper from "../../helpers/ParseHelper";

const GuestRecords = () => {

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const { data, getMenu, tableParams, getGuestsByPagination } = useContext(GuestContext);

    const onChangeTable = (pagination, filters, sorter) => {
        getGuestsByPagination(pagination.current, pagination.pageSize);
    };

    const onChangeList = (page, pageSize) => {
        getGuestsByPagination(page, pageSize);
    };

    const onShowSizeChange = (current, size) => {
        getGuestsByPagination(current, size);
    };

    return (isMobile ?
        <>
            <List
                itemLayout="vertical"
                dataSource={data}
                renderItem={(model, index) => (
                    <List.Item key={index}>
                        <Card bordered={false}>
                            <div className="RowContainer">
                                <div className="ColumnContainer">
                                    <div><strong>Habitación:</strong> {model.room_number}</div>
                                    <div><strong>Cuenta:</strong> {model.customer_account_code}</div>
                                    <div><strong>Ingreso:</strong> {DateUtil.ToSimpleString(model.start_date)}</div>
                                    <div><strong>Salida:</strong> {DateUtil.ToSimpleString(model.end_date)}</div>
                                    <div><strong>Cliente</strong>: {model.name} {model.lastname}</div>
                                    <div><strong>Pago:</strong> {model.customer_account_paid ? 'PAGADO' : 'PENDIENTE'}</div>
                                    <div><strong>{model.document_type}</strong>: {model.document_number}</div>
                                    <div><strong>Empresa</strong>: {ParseHelper.parseString(model.company)}</div>
                                    <div><strong>Estado</strong>: {model.guest_status}</div>
                                    <div><strong>Registrado:</strong> {DateUtil.ToSimpleString(model.created_at)}</div>
                                </div>
                                <div className="ColumnContainer ColumnContainerVentas" style={{ width: '100%' }}>
                                    <Dropdown menu={getMenu(model)} trigger={['click']} >
                                        <Button icon={<SettingOutlined />}>Acciones</Button>
                                    </Dropdown>
                                </div>
                            </div>
                        </Card>
                    </List.Item>
                )}
            />
            <Pagination
                pageSize={tableParams.pagination.pageSize}
                showSizeChanger={tableParams.pagination.showSizeChanger}
                onShowSizeChange={onShowSizeChange}
                total={tableParams.pagination.total}
                pageSizeOptions={tableParams.pagination.pageSizeOptions}
                current={tableParams.pagination.current}
                onChange={onChangeList}
            />
        </> :
        <Table
            pagination={tableParams.pagination}
            bordered
            dataSource={data}
            rowKey={(record) => record.id}
            onChange={onChangeTable}
            columns={[
                { title: 'Habitación', width: '100px', dataIndex: 'room_number', key: 'room_number' },
                {
                    title: 'Detalle', key: 'details', render: (model) => {
                        return (
                            <>
                                <div><strong>Cuenta:</strong> {model.customer_account_code}</div>
                                <div><strong>INGRESO:</strong> {DateUtil.ToSimpleString(model.start_date)}</div>
                                <div><strong>SALIDA:</strong> {DateUtil.ToSimpleString(model.end_date)}</div>
                                <div><strong>{model.document_type}</strong>: {model.document_number}</div>
                                <div><strong>NOMBRE COMPLETO</strong>: {model.name} {model.lastname}</div>
                                {model.company && <div><strong>EMPRESA</strong>: {model.company}</div>}
                                <div><strong>Pago:</strong> {model.customer_account_paid ? 'PAGADO' : 'PENDIENTE'}</div>
                                <div><strong>Registrado:</strong> {DateUtil.ToSimpleString(model.created_at)}</div>
                            </>
                        )
                    }
                },
                {
                    title: 'Estado', dataIndex: 'guest_status', key: 'guest_status'
                },
                {
                    title: 'Opciones', key: 'Acciones', width: 150, render: (value) => (
                        <Dropdown trigger={['click']} menu={getMenu(value)}>
                            <Button icon={<SettingOutlined />}>Acciones</Button>
                        </Dropdown>
                    )
                },
            ]}
        />
    );
};

export default GuestRecords;