import { useMediaQuery } from "react-responsive";
import { BookingContext } from "../../contexts/BookingContext";
import { useContext } from "react";
import DateUtil from "../../utils/DateUtil";
import { Button, Card, Dropdown, List, Pagination, Table } from "antd";
import { SettingOutlined } from "@ant-design/icons";

const BookingRecords = () => {

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const { data, getMenu, tableParams, getBookingsByPagination } = useContext(BookingContext);

    const onChangeTable = (pagination, filters, sorter) => {
        getBookingsByPagination(pagination.current, pagination.pageSize);
    };

    const onChangeList = (page, pageSize) => {
        getBookingsByPagination(page, pageSize);
    };

    const onShowSizeChange = (current, size) => {
        getBookingsByPagination(current, size);
    };

    return (isMobile ?
        <>
            <List
                itemLayout="vertical"
                dataSource={data}
                renderItem={(model, index) => (
                    <List.Item key={index}>
                        <Card bordered={false}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <div><strong>Habitación:</strong> {model.room_number}</div>
                                    <div><strong>Cuenta:</strong> {model.customer_account_code}</div>
                                    <div><strong>Ingreso:</strong> {DateUtil.ToSimpleString(model.start_date)}</div>
                                    <div><strong>Salida:</strong> {DateUtil.ToSimpleString(model.end_date)}</div>
                                    <div><strong>Cliente</strong>: {model.name} {model.lastname}</div>
                                    <div><strong>{model.document_type}</strong>: {model.document_number}</div>
                                    {model.company && (<div><strong>Empresa</strong>: {model.company}</div>)}
                                    <div><strong>Estado</strong>: {model.booking_status}</div>
                                    <div><strong>Registrado:</strong> {DateUtil.ToSimpleString(model.created_at)}</div>
                                </div>
                                <div className="ColumnContainer ColumnContainerVentas" style={{ width: '100%' }}>
                                    <Dropdown menu={getMenu(model)} trigger={['click']} >
                                        <Button icon={<SettingOutlined />}>Acciones</Button>
                                    </Dropdown>
                                </div>
                            </div>
                        </Card>
                    </List.Item>
                )}
            />
            <Pagination
                pageSize={tableParams.pagination.pageSize}
                showSizeChanger={tableParams.pagination.showSizeChanger}
                onShowSizeChange={onShowSizeChange}
                total={tableParams.pagination.total}
                pageSizeOptions={tableParams.pagination.pageSizeOptions}
                current={tableParams.pagination.current}
                onChange={onChangeList}
            />
        </> :
        <Table
            pagination={tableParams.pagination}
            bordered
            dataSource={data}
            rowKey={(record) => record.id}
            onChange={onChangeTable}
            columns={[
                {
                    title: 'Cliente', key: 'customer', render: (model) => {
                        return (
                            <>
                                <div><strong>Cuenta:</strong> {model.customer_account_code}</div>
                                <div><strong>Nombre Completo:</strong> {model.name} {model.lastname}</div>
                                <div><strong>{model.document_type}:</strong> {model.document_number}</div>
                                <div><strong>Habitación:</strong> {model.room_number}</div>
                                {model.company && (<div><strong>Empresa:</strong> {model.company}</div>)}
                                <div><strong>Registrado:</strong> {DateUtil.ToSimpleString(model.created_at)}</div>
                            </>
                        )
                    }
                },
                {
                    title: 'Ingreso/Salida', key: 'dates', render: (value) => {
                        return (
                            <>
                                <div><strong>Ingreso:</strong> {DateUtil.ToSimpleString(value.start_date)}</div>
                                <div><strong>Salida:</strong> {DateUtil.ToSimpleString(value.end_date)}</div>
                            </>
                        )
                    }
                },
                {
                    title: 'Estado', dataIndex: 'booking_status', key: 'booking_status'
                },
                {
                    title: 'Opciones', key: 'Acciones', width: 150, render: (value) => (
                        <Dropdown trigger={['click']} menu={getMenu(value)}>
                            <Button icon={<SettingOutlined />}>Acciones</Button>
                        </Dropdown>
                    )
                },
            ]}
        />
    );
};

export default BookingRecords;