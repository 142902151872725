import { useContext } from "react";
import { Modal } from "antd";
import DateUtil from "../../utils/DateUtil";
import { GuestContext } from "../../contexts/GuestContext";

const GuestActiveModal = () => {

    const { switchDataType, openDelete, model, setOpenDelete, setModel, updateGuestActive } = useContext(GuestContext);

    const onCancel = () => {
        setModel(null);
        setOpenDelete(false);
    };

    const onOk = () => {
        updateGuestActive(!switchDataType);
    };

    return (model &&
        <Modal title={`${switchDataType ? 'Eliminar' : 'Recuperar'} al huesped`} open={openDelete} onCancel={onCancel} onOk={onOk}>
            <p>¿Está seguro que quiere {switchDataType ? 'eliminar' : 'recuperar'} al huesped {model.name} {model.lastname} del {DateUtil.ToSimpleString(model.start_date)} al {DateUtil.ToSimpleString(model.end_date)} en la habitación {model.room_number}?</p>
        </Modal>
    );

};

export default GuestActiveModal;