import { SettingProvider } from "../contexts/SettingContext";
import SettingFilter from "../components/SettingComponents/SettingFilter";
import SettingModal from "../components/SettingComponents/SettingModal";
import SettingRecords from "../components/SettingComponents/SettingRecords";
import { Card } from "antd";

const SettingPage = () => {
    return (
        <SettingProvider>
            <h1 className="TituloContenido">Configuraciones</h1>
            <Card title={'LISTA DE CONFIGURACIONES'}>
                <SettingFilter />
                <SettingRecords />
            </Card>
            <SettingModal />
        </SettingProvider>
    );
};

export default SettingPage;