import { useContext } from "react";
import { GuestContext } from "../../contexts/GuestContext";
import { Button } from "antd";

const GuestCreate = () => {

    const { setModel, setShowView } = useContext(GuestContext);

    const onClick = () => {
        setModel(null);
        setShowView('create');
    };

    return (
        <div style={{ textAlign: 'right', marginBottom: '10px' }}>
            <Button type="primary" onClick={onClick}>Nuevo Registro</Button>
        </div>
    );

};

export default GuestCreate;