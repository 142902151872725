import { useContext } from "react";
import { ProductContext } from "../../contexts/ProductContext";
import RulesHelper from "../../helpers/RulesHelper";
import { Form, Input, Modal } from "antd";

const ProductModal = () => {
    const { form, model, open, setOpen, setModel, updateProduct, createProduct } = useContext(ProductContext);
    const onCancel = () => {
        setOpen(false);
        setModel(null);
        form.resetFields();
    };
    const onFinish = (values) => {
        !model ? createProduct(values) : updateProduct(values);
    };
    return (
        <Modal title={model ? 'Actualizar Producto' : 'Crear Producto'} open={open} onCancel={onCancel} onOk={() => form.submit()}>
            <Form form={form} name="basic" layout="vertical" onFinish={onFinish} autoComplete="off">
                <Form.Item label="Código" name="product_code" rules={RulesHelper.max(50)}>
                    <Input disabled={model ? true : false} />
                </Form.Item>
                <Form.Item label="Nombre" name="product_name" rules={RulesHelper.max(50)}>
                    <Input />
                </Form.Item>
                <Form.Item label="Precio" name="product_price" rules={RulesHelper.money()}>
                    <Input prefix={'S/'} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ProductModal;