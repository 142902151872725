import { useContext } from "react";
import { SettingContext } from "../../contexts/SettingContext";
import { useMediaQuery } from "react-responsive";
import { Button, Card, Dropdown, List, Table } from "antd";
import { SettingOutlined } from "@ant-design/icons";

const SettingRecords = () => {

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const { dataSource, getMenu } = useContext(SettingContext);

    return (isMobile ?
        <List itemLayout="vertical" dataSource={dataSource} renderItem={(model, index) => (
            <List.Item>
                <Card bordered={false}>
                    <div className="RowContainer">
                        <div className="ColumnContainer">
                            <div><strong>Configuración:</strong> {model.setting_description}</div>
                            <div><strong>Valor:</strong> {model.setting_value}</div>
                        </div>
                        <div className="ColumnContainer ColumnContainerVentas" style={{ width: '100%' }}>
                            <Dropdown
                                menu={getMenu(model)}
                                trigger={['click']}
                            >
                                <Button icon={<SettingOutlined />}>Acciones</Button>
                            </Dropdown>
                        </div>
                    </div>
                </Card>
            </List.Item>
        )} />
        :
        <Table
            pagination={false}
            bordered
            dataSource={dataSource}
            rowKey={(record) => record.id}
            columns={[
                { title: 'CONFIGURACION', dataIndex: 'setting_description', key: 'setting_description' },
                {
                    title: 'VALOR', key: 'setting_value', render: (record) => {
                        return (<>{record.setting_value}</>);
                    }
                },
                {
                    title: 'Opciones', key: 'Acciones', render: (record) => (
                        <Dropdown
                            menu={getMenu(record)}
                            trigger={['click']}
                        >
                            <Button icon={<SettingOutlined />}>Acciones</Button>
                        </Dropdown>
                    )
                },
            ]}
        />
    )
};

export default SettingRecords;