import CleaningMonitoringContainer from "../components/CleaningMonitoringComponents/CleaningMonitoringContainer";
import { CleaningMonitoringProvider } from "../contexts/CleaningMonitoringContext";

const CleaningMonitoringPage = () => {
    return (
        <CleaningMonitoringProvider>
            <CleaningMonitoringContainer />
        </CleaningMonitoringProvider>
    )
};

export default CleaningMonitoringPage;