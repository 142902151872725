import { useContext, useEffect, useState } from "react";
import RoomTypeHelper from "../../helpers/RoomTypeHelper";
import DateUtil from "../../utils/DateUtil";
import { Card, Descriptions } from "antd";
import { CleaningMonitoringFormContext } from "../../contexts/CleaningMonitoringFormContext";

const CleaningMonitoringFormDetail = () => {

    const { room_cleaning_record } = useContext(CleaningMonitoringFormContext);

    const [items, setItems] = useState([]);

    useEffect(() => {

        if (room_cleaning_record) {

            const dataDefault = [
                { key: 1, label: 'Número', children: `${room_cleaning_record.room_number}` },
                { key: 2, label: 'Tipo', children: `${RoomTypeHelper.getDescription(room_cleaning_record.room_type)}` },
                { key: 3, label: 'Estado', children: `${room_cleaning_record.status}` },
                { key: 4, label: 'Registrado', children: `${DateUtil.ToSimpleString(room_cleaning_record.created_at)}` }
            ];

            if (room_cleaning_record.cleaner) {
                dataDefault.push({ key: 5, label: 'Encargado', children: `${room_cleaning_record.cleaner}` });
            }

            setItems(dataDefault);

        }

    }, [room_cleaning_record]);

    return (
        <>
            {items.length > 0 &&
                <div style={{ marginBottom: '20px' }}>
                    <Card bordered={false} title={'DETALLE DE LA HABITACIÓN'}>
                        <Descriptions items={items} bordered />
                    </Card>
                </div>
            }
        </>
    );

};

export default CleaningMonitoringFormDetail;