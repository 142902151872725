import React from "react";
import { Route, Routes } from "react-router-dom";
import AppLayout from "./layouts/AppLayout";
import ProtectedRoute from "./ProtectedRoute";
import NotFoundPage from "./pages/NotFoundPage";
import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import MonitoringPage from "./pages/MonitoringPage";
import CleaningMonitoringPage from "./pages/CleaningMonitoringPage";
import LaundryMonitoringPage from "./pages/LaundryMonitoringPage";
import RoleKeyHelper from "./helpers/RoleKeyHelper";
import UserPage from "./pages/UserPage";
import SettingPage from "./pages/SettingPage";
import RoomPage from "./pages/RoomPage";
import ProductPage from "./pages/ProductPage";
import RedirectRoute from "./RedirectRoute";
import RoutePathHelper from "./helpers/RoutePathHelper";
import BookingPage from "./pages/BookingPage";
import CustomerAccountPage from "./pages/CustomerAccountPage";
import ConsumptionPage from "./pages/ConsumptionPage";
import BookingCalendarPage from "./pages/BookingCalendarPage";
import GuestPage from "./pages/GuestPage";
import SalePage from "./pages/SalePage";

const AppRoutes = () => {

    const routes = [
        { path: RoutePathHelper.getDashboard(), element: <DashboardPage />, allowedRoles: [] },
        { path: RoutePathHelper.getMonitoring(), element: <MonitoringPage />, allowedRoles: [RoleKeyHelper.getHotelReceptionist()] },
        { path: RoutePathHelper.getCleaningMonitoring(), element: <CleaningMonitoringPage />, allowedRoles: [RoleKeyHelper.getHotelCleaningStaff()] },
        { path: RoutePathHelper.getLaundryMonitoring(), element: <LaundryMonitoringPage />, allowedRoles: [RoleKeyHelper.getLaundryReceptionist()] },
        { path: RoutePathHelper.getUser(), element: <UserPage />, allowedRoles: [] },
        { path: RoutePathHelper.getRoom(), element: <RoomPage />, allowedRoles: [] },
        { path: RoutePathHelper.getProduct(), element: <ProductPage />, allowedRoles: [] },
        { path: RoutePathHelper.getSetting(), element: <SettingPage />, allowedRoles: [] },
        { path: RoutePathHelper.getBooking(), element: <BookingPage />, allowedRoles: [RoleKeyHelper.getHotelReceptionist()] },
        { path: RoutePathHelper.getCustomerAccount(), element: <CustomerAccountPage />, allowedRoles: [RoleKeyHelper.getHotelReceptionist()] },
        { path: RoutePathHelper.getConsumption(), element: <ConsumptionPage />, allowedRoles: [RoleKeyHelper.getHotelReceptionist()] },
        { path: RoutePathHelper.getBookingCalendar(), element: <BookingCalendarPage />, allowedRoles: [RoleKeyHelper.getHotelReceptionist()] },
        { path: RoutePathHelper.getGuest(), element: <GuestPage />, allowedRoles: [RoleKeyHelper.getHotelReceptionist()] },
        { path: RoutePathHelper.getSale(), element: <SalePage />, allowedRoles: [RoleKeyHelper.getHotelReceptionist()] }
    ];

    return (
        <Routes>
            <Route index element={<RedirectRoute />} />
            <Route path={'/login'} caseSensitive element={<LoginPage />} />
            {routes.map((item, index) => (
                <Route key={index} path={item.path} caseSensitive element={(
                    <ProtectedRoute path={item.path} allowedRoles={item.allowedRoles}>
                        <AppLayout>{item.element}</AppLayout>
                    </ProtectedRoute>
                )} />
            ))}
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
};

export default AppRoutes;
