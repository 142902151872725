import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { Form, Input, Modal, Select } from "antd";
import RulesHelper from "../../helpers/RulesHelper";
import RoleKeyHelper from "../../helpers/RoleKeyHelper";

const UserModal = () => {
    const { form, model, open, setOpen, setModel, updateUser, createUser } = useContext(UserContext);
    const onCancel = () => {
        setOpen(false);
        setModel(null);
        form.resetFields();
    };
    const onFinish = (values) => {
        !model ? createUser(values) : updateUser(values)
    };
    return (
        <Modal title={model ? 'Actualizar Usuario' : 'Registrar Usuario'} open={open} onCancel={onCancel} onOk={() => form.submit()}>
            {model ?
                (
                    <Form layout="vertical" form={form} onFinish={onFinish} autoComplete="off">
                        <Form.Item label={'Correo Electrónico'} name={'email'}>
                            <Input disabled />
                        </Form.Item>
                        <Form.Item label={'Nombre Completo'} name={'full_name'} rules={RulesHelper.fullName()}>
                            <Input />
                        </Form.Item>
                        <Form.Item label={'Rol'} name={'role'} rules={RulesHelper.required()}>
                            <Select options={RoleKeyHelper.getSelectOptions()} disabled={'admin@hotel.com' === form.getFieldValue('email')} />
                        </Form.Item>
                        <Form.Item label={'Teléfono'} name={'phone'} rules={RulesHelper.phone()}>
                            <Input />
                        </Form.Item>
                    </Form>
                ) :
                (
                    <Form layout="vertical" form={form} onFinish={onFinish} autoComplete="off">
                        <Form.Item label={'Correo Electrónico'} name={'email'} rules={RulesHelper.email()}>
                            <Input />
                        </Form.Item>
                        <Form.Item label={'Nombre Completo'} name={'full_name'} rules={RulesHelper.fullName()}>
                            <Input />
                        </Form.Item>
                        <Form.Item label={'Rol'} name={'role'} rules={RulesHelper.required()}>
                            <Select options={RoleKeyHelper.getSelectOptions()} />
                        </Form.Item>
                        <Form.Item label={'Teléfono'} name={'phone'} rules={RulesHelper.phone()}>
                            <Input />
                        </Form.Item>
                        <Form.Item label={'Contraseña'} name={'password'} rules={RulesHelper.password()}>
                            <Input.Password />
                        </Form.Item>
                    </Form>
                )}
        </Modal>
    );
};

export default UserModal;