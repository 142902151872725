import { RoomProvider } from "../contexts/RoomContext";
import RoomContainer from "../components/RoomComponents/RoomContainer";

const RoomPage = () => {
    return (
        <RoomProvider>
            <RoomContainer />
        </RoomProvider>
    );

};

export default RoomPage;