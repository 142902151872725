import { Form, Input, Modal, Select } from "antd";
import { RoomContext } from "../../contexts/RoomContext";
import RoomTypeHelper from "../../helpers/RoomTypeHelper";
import RulesHelper from "../../helpers/RulesHelper";
import { useContext } from "react";

const RoomModal = () => {
    const { form, model, open, setOpen, setModel, updateRoom, createRoom } = useContext(RoomContext);
    const onCancel = () => {
        setOpen(false);
        setModel(null);
        form.resetFields();
    };
    const onFinish = (values) => {
        !model ? createRoom(values) : updateRoom(values)
    };
    return (
        <Modal title={model ? 'Actualizar Habitación' : 'Registrar Habitación'} open={open} onCancel={onCancel} onOk={() => form.submit()}>
            <Form form={form} name="basic" layout="vertical" onFinish={onFinish} autoComplete="off">
                <Form.Item label="Número de la habitación" name="room_number" rules={RulesHelper.max(50)}>
                    <Input disabled={model ? true : false} />
                </Form.Item>
                <Form.Item label="Precio del habitacion" name="room_price" rules={RulesHelper.money()}>
                    <Input prefix={'S/'} />
                </Form.Item>
                <Form.Item label="Tipo" name="room_type" rules={RulesHelper.required()}>
                    <Select placeholder="Selecciona el tipo de habitación" allowClear options={RoomTypeHelper.getSelectOptions()} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default RoomModal;