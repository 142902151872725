import { Card, Form } from "antd";
import { useContext, useState } from "react";
import { DashbaordAdminContext } from "../../contexts/DashboardAdminContext";
import { PieChart } from '@mui/x-charts/PieChart';
import { useMediaQuery } from "react-responsive";
import dayjs from "dayjs";
import YearlySalesChart from "./YearlySalesChart";
import MonthlySalesChart from "./MonthlySalesChart";
import DateUtil from "../../utils/DateUtil";
import SaleService from "../../services/SaleService";

const DahsboardAdminRecords = () => {

    const { data } = useContext(DashbaordAdminContext);

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const isDesktop = useMediaQuery({ minWidth: 1200 });

    const [form] = Form.useForm();

    const [monthlySalesChartForm] = Form.useForm();

    const [dataYear, setDataYear] = useState([]);
    const [dataMonth, setDataMonth] = useState([]);

    const registrosPorYearService = (year) => {

        SaleService.registrosPorYear(year).then(response => {

            const spanishMonths = DateUtil.GetMonthsSpanish();

            const tempDataYear = [];

            for (let index = 0; index < spanishMonths.length; index++) {

                const finded = response.find(x => Number(x.mes) - 1 === index);

                if (finded === undefined) {
                    tempDataYear.push(0);
                } else {
                    tempDataYear.push(finded.total_monto);
                }

            }

            setDataYear(tempDataYear);

        });

    };

    const registrosPorMesService = (month, year) => {

        SaleService.registrosPorMes(month, year).then(response => {

            const days = DateUtil.GeyDaysByMonthSelect(month, year);

            const tempDataMonth = [];

            for (let index = 1; index <= days.length; index++) {

                const finded = response.find(x => Number(x.dia) === index);

                if (finded === undefined) {
                    tempDataMonth.push(0);
                } else {
                    tempDataMonth.push(finded.total_monto);
                }

            }

            setDataMonth(tempDataMonth);

        });

    }

    useState(() => {
        const current_year = dayjs().year();
        const current_month = dayjs().month() + 1;

        form.setFieldValue('year', current_year);

        monthlySalesChartForm.setFieldValue('month', current_month);
        monthlySalesChartForm.setFieldValue('year', current_year);

        registrosPorMesService(current_month, current_year);

        registrosPorYearService(current_year);

    }, []);

    return (
        <>

            {data && (
                <>
                    <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', justifyContent: 'center', marginBottom: '10px' }}>

                        <Card style={{ width: '300px' }} title={'CUENTAS CLIENTES'}>
                            <div style={{ textAlign: 'center' }}>
                                <span>PENDIENTES</span>
                                <div style={{ fontSize: '20px' }} >{data.customer_accounts_count}</div>
                            </div>
                        </Card>

                        <Card style={{ width: '300px' }} title={'HUESPEDES'}>
                            <div style={{ textAlign: 'center' }}>
                                <span>HOSPEDADOS</span>
                                <div style={{ fontSize: '20px' }} >{data.guests_count}</div>
                            </div>
                        </Card>

                        <Card style={{ width: '300px' }} title={'LAVANDERÍA'}>
                            <div style={{ textAlign: 'center' }}>
                                <span>SOLICITUDES</span>
                                <div style={{ fontSize: '20px' }} >{data.laundry_records_counts}</div>
                            </div>
                        </Card>

                    </div>

                    <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', justifyContent: 'center', marginBottom: '10px' }}>

                        <Card title={`PRODUCTOS: ${Number(data.product_in_stock_count) + Number(data.product_out_of_stock_count)}`}>
                            <div style={{ marginBottom: '20px' }}>
                                <PieChart
                                    series={[
                                        {
                                            arcLabel: (item) => `${item.value === 0 ? '' : `${item.value}`}`,
                                            data: [
                                                { id: 0, value: data.product_in_stock_count, label: 'PRODUCTOS CON STOCK', color: '#038512' },
                                                { id: 1, value: data.product_out_of_stock_count, label: 'PRODUCTOS SIN STOCK', color: '#a30505' },
                                            ],
                                        },
                                    ]}
                                    margin={{ right: 5 }}
                                    slotProps={{ legend: { hidden: true } }}
                                    width={250}
                                    height={isMobile ? 100 : 150}
                                />
                            </div>
                            <div>
                                <span style={{ paddingTop: '1px', paddingRight: '15px', backgroundColor: '#038512', marginRight: '5px' }}></span> PRODUCTOS CON STOCK <br />
                                <span style={{ paddingTop: '1px', paddingRight: '15px', backgroundColor: '#a30505', marginRight: '5px' }}></span> PRODUCTOS SIN STOCK
                            </div>
                        </Card>

                        <Card title={`HABITACIONES: ${Number(data.clean_rooms_count) + Number(data.dirty_rooms_count)}`}>
                            <div style={{ marginBottom: '20px' }}>
                                <PieChart
                                    series={[
                                        {
                                            arcLabel: (item) => `${item.value === 0 ? '' : `${item.value}`}`,
                                            data: [
                                                { id: 0, value: data.clean_rooms_count, label: 'HABITACIONES LIMPIAS', color: '#038512' },
                                                { id: 1, value: data.dirty_rooms_count, label: 'HABITACIONES SUCIAS', color: '#a30505' },
                                            ],
                                        },
                                    ]}
                                    margin={{ right: 5 }}
                                    slotProps={{ legend: { hidden: true } }}
                                    width={250}
                                    height={isMobile ? 100 : 150}
                                />
                            </div>
                            <div>
                                <span style={{ paddingTop: '1px', paddingRight: '15px', backgroundColor: '#038512', marginRight: '5px' }}></span> HABITACIONES LIMPIAS <br />
                                <span style={{ paddingTop: '1px', paddingRight: '15px', backgroundColor: '#a30505', marginRight: '5px' }}></span> HABITACIONES SUCIAS
                            </div>
                        </Card>

                        <Card title={`RESERVAS: ${Number(data.booking_count_by_status_pending) + Number(data.booking_count_by_status_register)}`}>
                            <div style={{ marginBottom: '20px' }}>
                                <PieChart
                                    series={[
                                        {
                                            arcLabel: (item) => `${item.value === 0 ? '' : `${item.value}`}`,
                                            data: [
                                                { id: 0, value: data.booking_count_by_status_pending, label: 'RESERVAS PENDIENTES', color: '#038512' },
                                                { id: 1, value: data.booking_count_by_status_register, label: 'RESERVAS POR REGISTRAR', color: '#8d9603' },
                                            ],
                                        },
                                    ]}
                                    margin={{ right: 5 }}
                                    slotProps={{ legend: { hidden: true } }}
                                    width={250}
                                    height={isMobile ? 100 : 150}
                                />
                            </div>
                            <div>
                                <span style={{ paddingTop: '1px', paddingRight: '15px', backgroundColor: '#038512', marginRight: '5px' }}></span> RESERVAS PENDIENTES <br />
                                <span style={{ paddingTop: '1px', paddingRight: '15px', backgroundColor: '#8d9603', marginRight: '5px' }}></span> RESERVAS POR REGISTRAR
                            </div>
                        </Card>

                    </div>
                </>
            )}

            <YearlySalesChart isDesktop={isDesktop} data={dataYear} form={form} registrosPorYearService={registrosPorYearService} />

            <div style={{ padding: '10px' }}></div>

            <MonthlySalesChart isDesktop={isDesktop} data={dataMonth} form={monthlySalesChartForm} registrosPorMesService={registrosPorMesService} />

        </>
    );

}

export default DahsboardAdminRecords;