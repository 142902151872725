import { useContext } from "react";
import ParseHelper from "../../helpers/ParseHelper";
import DateUtil from "../../utils/DateUtil";
import { Button, DatePicker, Form, Input, Select } from "antd";
import GeneralHelper from "../../helpers/GeneralHelper";
import { BookingContext } from "../../contexts/BookingContext";

const BookingFilter = () => {
    const { getBookingsByFilter } = useContext(BookingContext);
    const onFinish = (values) => {
        getBookingsByFilter({
            full_name: ParseHelper.parseString(values.full_name),
            company: ParseHelper.parseString(values.company),
            document_type: ParseHelper.parseString(values.document_type),
            document_number: ParseHelper.parseString(values.document_number),
            start_date: DateUtil.ToServerDateString(values.start_date),
            end_date: DateUtil.ToServerEndString(values.end_date),
            room_code: ParseHelper.parseString(values.room_code),
            booking_status: values.booking_status,
        });
    };
    return (
        <Form
            className="FilterContainer"
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
            initialValues={{
                booking_status: 'PENDIENTE'
            }}
        >
            <Form.Item name={"full_name"} label={"Filtro por nombre completo del cliente"}>
                <Input />
            </Form.Item>
            <Form.Item name={"company"} label={"Filtro por empresa"}>
                <Input />
            </Form.Item>
            <Form.Item name={"document_type"} label={"Filtro por tipo de documento"}>
                <Select allowClear options={GeneralHelper.getCustomerDocumentSelectOptions()} />
            </Form.Item>
            <Form.Item name={"document_number"} label={"Filtro por número de documento"}>
                <Input />
            </Form.Item>
            <Form.Item name={"room_code"} label={"Filtro por código de habitación"}>
                <Input />
            </Form.Item>
            <Form.Item name={"start_date"} label={"Filtro por fecha inicio"}>
                <DatePicker format="DD/MM/YYYY" style={{ width: '250px' }} />
            </Form.Item>
            <Form.Item name={"end_date"} label={"Filtro por fecha fin"}>
                <DatePicker format="DD/MM/YYYY" style={{ width: '250px' }} />
            </Form.Item>
            <Form.Item name={"booking_status"} label={"Filtro por estado"}>
                <Select
                    style={{ width: '200px' }}
                    options={[
                        { label: 'PENDIENTE', value: 'PENDIENTE' },
                        { label: 'POR REGISTRAR HUESPED', value: 'POR REGISTRAR HUESPED' },
                        { label: 'COMPLETADO', value: 'COMPLETADO' },
                        { label: 'VENCIDO', value: 'VENCIDO' }
                    ]}
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">Buscar</Button>
            </Form.Item>
        </Form>
    );
};

export default BookingFilter;