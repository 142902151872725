import { useContext, useEffect, useState } from "react";
import { PartialPaymentContext } from "../../contexts/PartialPaymentContext";
import GeneralUtil from "../../utils/GeneralUtil";
import DateUtil from "../../utils/DateUtil";
import { Card, Descriptions, Tag } from "antd";

const PartialPaymentConsumptionDetail = () => {

    const { consumption, partialPayment } = useContext(PartialPaymentContext);

    const [consumptionItems, setConsumptionItems] = useState([]);

    const [partialPaymentItems, setPartialPaymentItems] = useState([]);

    useEffect(() => {

        if (consumption) {

            const dataDefault = [
                { key: 1, label: 'Tipo', children: `HABITACIÓN` },
                { key: 2, label: 'Monto', children: `${GeneralUtil.getMoney(consumption.amount)}` },
                { key: 3, label: 'Registrado', children: `${DateUtil.ToSimpleString(consumption.created_at)}` }
            ];

            setConsumptionItems(dataDefault);

        }

    }, [consumption]);

    useEffect(() => {

        if (partialPayment && consumption) {

            let amount = Number(consumption.amount) - Number(partialPayment.total_paid);

            let isNegative = false;

            if (amount < 0) {

                isNegative = true;

                amount = amount * -1;

            }

            const dataDefault = [
                { key: 1, label: 'Monto Pagado', children: `${GeneralUtil.getMoney(partialPayment.total_paid)}` },
                {
                    key: 2, label: 'Monto Deuda', children: (
                        <>
                            {isNegative ? (
                                <Tag color="red">{`Devolver, ${GeneralUtil.getMoney(amount.toFixed(2))}`}</Tag>
                            ) : (
                                <Tag color="yellow">{`Falta, ${GeneralUtil.getMoney(amount.toFixed(2))}`}</Tag>
                            )}
                        </>
                    )
                },
                { key: 3, label: 'Registrado', children: `${DateUtil.ToSimpleString(partialPayment.created_at)}` }
            ];

            setPartialPaymentItems(dataDefault);

        }

    }, [partialPayment]);

    return (
        <>
            {consumptionItems.length > 0 &&
                <div style={{ marginBottom: '20px' }}>
                    <Card bordered={false} title={'DETALLE CONSUMO'}>
                        <Descriptions items={consumptionItems} bordered />
                    </Card>
                </div>
            }

            {partialPaymentItems.length > 0 &&
                <div style={{ marginBottom: '20px' }}>
                    <Card bordered={false} title={'DETALLE PAGO PARCIAL'}>
                        <Descriptions items={partialPaymentItems} bordered />
                    </Card>
                </div>
            }
        </>
    );

}

export default PartialPaymentConsumptionDetail;