class RoomTypeHelper {
    getSingleRoomKey() {
        return 'SINGLE_ROOM';
    }
    getTwinRoomKey() {
        return 'TWIN_ROOM';
    }
    getTripleRoomKey() {
        return 'TRIPLE_ROOM';
    }
    getDoubleRoomKey() {
        return 'DOUBLE_ROOM';
    }
    getDoubleRoomWithBalcony() {
        return 'DOUBLE_ROOM_WITH_BALCONY';
    }
    getAllKeys() {
        return [
            this.getSingleRoomKey(),
            this.getTwinRoomKey(),
            this.getTripleRoomKey(),
            this.getDoubleRoomKey(),
            this.getDoubleRoomWithBalcony()
        ];
    }
    getSelectOptions() {
        return [
            { value: this.getSingleRoomKey(), label: 'SIMPLE' },
            { value: this.getTwinRoomKey(), label: 'DOBLE' },
            { value: this.getTripleRoomKey(), label: 'TRIPLE' },
            { value: this.getDoubleRoomKey(), label: 'MATRIMONIAL' },
            { value: this.getDoubleRoomWithBalcony(), label: 'MATRIMONIAL CON BALCÓN' },
        ];
    }
    getDescription(key) {
        switch (key) {
            case this.getSingleRoomKey(): {
                return 'SIMPLE';
            }
            case this.getTwinRoomKey(): {
                return 'DOBLE';
            }
            case this.getTripleRoomKey(): {
                return 'TRIPLE';
            }
            case this.getDoubleRoomKey(): {
                return 'MATRIMONIAL';
            }
            case this.getDoubleRoomWithBalcony(): {
                return 'MATRIMONIAL CON BALCÓN';
            }
            default: {
                return '';
            }
        }
    }
}

export default new RoomTypeHelper();