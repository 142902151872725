import ValidationHelper from "../helpers/ValidationHelper";

class EnvConfig {
    getUrl() {
        const url = process.env.REACT_APP_API_URL;
        if (!ValidationHelper.hasData(url)) {
            return "http://localhost:4000/api";
        }
        return url;
    }
    getWebsocketUrl() {
        const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL;
        if (!ValidationHelper.hasData(websocketUrl)) {
            return "ws://localhost:4000/ws";
        }
        return websocketUrl;
    }
}

export default new EnvConfig();