import { useContext, useEffect, useState } from "react";
import { RoomHistoricCleaningContext } from "../../contexts/RoomHistoricCleaningContext";
import GeneralUtil from "../../utils/GeneralUtil";
import RoomTypeHelper from "../../helpers/RoomTypeHelper";
import DateUtil from "../../utils/DateUtil";
import { Card, Descriptions } from "antd";

const RoomHistoricCleaningDetail = () => {

    const { room } = useContext(RoomHistoricCleaningContext);

    const [items, setItems] = useState([]);

    useEffect(() => {

        if (room) {

            const dataDefault = [
                { key: 1, label: 'Número', children: `${room.room_number}` },
                { key: 2, label: 'Precio', children: `${GeneralUtil.getMoney(room.room_price)}` },
                { key: 3, label: 'Tipo', children: `${RoomTypeHelper.getDescription(room.room_type)}` },
                { key: 4, label: 'Registrado', children: `${DateUtil.ToSimpleString(room.created_at)}` }
            ];

            setItems(dataDefault);

        }

    }, [room]);

    return (
        <>
            {items.length > 0 &&
                <div style={{ marginBottom: '20px' }}>
                    <Card bordered={false} title={'DETALLE HABITACIÓN'}>
                        <Descriptions items={items} bordered />
                    </Card>
                </div>
            }
        </>
    );

};

export default RoomHistoricCleaningDetail;
