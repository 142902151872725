import { useMediaQuery } from "react-responsive";
import { ProductContext } from "../../contexts/ProductContext";
import { useContext } from "react";
import { Button, Card, Dropdown, List, Pagination, Table } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import GeneralUtil from "../../utils/GeneralUtil";
import DateUtil from "../../utils/DateUtil";

const ProductRecords = () => {

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const { data, getMenu, tableParams, getProductsByPagination } = useContext(ProductContext);

    const onChangeTable = (pagination, filters, sorter) => {
        getProductsByPagination(pagination.current, pagination.pageSize);
    };

    const onChangeList = (page, pageSize) => {
        getProductsByPagination(page, pageSize);
    };

    const onShowSizeChange = (current, size) => {
        getProductsByPagination(current, size);
    };

    return (isMobile ?
        (
            <>
                <List
                    itemLayout="vertical"
                    dataSource={data}
                    renderItem={(model, index) => (
                        <List.Item key={index}>
                            <Card bordered={false}>
                                <div className="RowContainer">
                                    <div className="ColumnContainer">
                                        <div><strong>Código:</strong> {model.product_code}</div>
                                        <div><strong>Nombre:</strong> {model.product_name}</div>
                                        <div><strong>Precio:</strong> {GeneralUtil.getMoney(model.product_price)}</div>
                                        <div><strong>Stock:</strong> {model.stock}</div>
                                        <div><strong>Registrado:</strong> {DateUtil.ToSimpleString(model.created_at)}</div>
                                    </div>
                                    <div className="ColumnContainer ColumnContainerVentas" style={{ width: '100%' }}>
                                        <Dropdown menu={getMenu(model)} trigger={['click']} >
                                            <Button icon={<SettingOutlined />}>Acciones</Button>
                                        </Dropdown>
                                    </div>
                                </div>
                            </Card>
                        </List.Item>
                    )}
                />
                <Pagination
                    pageSize={tableParams.pagination.pageSize}
                    showSizeChanger={tableParams.pagination.showSizeChanger}
                    onShowSizeChange={onShowSizeChange}
                    total={tableParams.pagination.total}
                    pageSizeOptions={tableParams.pagination.pageSizeOptions}
                    current={tableParams.pagination.current}
                    onChange={onChangeList}
                />
            </>
        ) :
        (
            <Table
                pagination={tableParams.pagination}
                bordered
                dataSource={data}
                rowKey={(record) => record.id}
                onChange={onChangeTable}
                columns={[
                    {
                        title: 'Producto', key: 'product', render: (model) => {
                            return (
                                <>
                                    <div><strong>Código:</strong> {model.product_code}</div>
                                    <div><strong>Nombre:</strong> {model.product_name}</div>
                                    <div><strong>Registrado:</strong> {DateUtil.ToSimpleString(model.created_at)}</div>
                                </>
                            );
                        }
                    },
                    { title: 'Precio', dataIndex: 'product_price', key: 'price', render: (value) => GeneralUtil.getMoney(value) },
                    { title: 'Stock', dataIndex: 'stock', key: 'stock' },
                    {
                        title: 'Opciones', key: 'Acciones', width: 150, render: (value) => (
                            <Dropdown trigger={['click']} menu={getMenu(value)}>
                                <Button icon={<SettingOutlined />}>Acciones</Button>
                            </Dropdown>
                        )
                    },
                ]}
            />
        )
    );
};

export default ProductRecords;