import { useContext } from "react";
import { LaundryMonitoringContext } from "../../contexts/LaundryMonitoringContext";
import LaundryMonitoringRecords from "./LaundryMonitoringRecords";
import { LaundryRecordFormProvider } from "../../contexts/LaundryRecordFormContext";
import { Breadcrumb, Card } from "antd";
import LaundryRecordForm from "../LaundryRecordFormComponents/LaundryRecordForm";
import LaundryRecordFormDetail from "../LaundryRecordFormComponents/LaundryRecordFormDetail";

const LaundryMonitoringContainer = () => {

    const { showView, setShowView, laundryRecordModel, setLaundryRecordModel } = useContext(LaundryMonitoringContext);

    return (
        <>
            {showView === 'laundry_monitoring_records' && (
                <Card>
                    <LaundryMonitoringRecords />
                </Card>
            )}

            {showView === 'laundry_record_update' && (
                <>
                    <Breadcrumb
                        style={{ marginBottom: '20px' }}
                        items={[
                            {
                                title: <div style={{ cursor: 'pointer' }}>Monitoreo de lavado</div>,
                                onClick: () => {
                                    setShowView('laundry_monitoring_records');
                                    setLaundryRecordModel(null);
                                }
                            },
                            {
                                title: <div style={{ cursor: 'default' }}>Actualizar registro de lavado</div>,
                            },
                        ]}
                    />

                    <LaundryRecordFormProvider
                        laundry_record={laundryRecordModel}
                        onBack={() => {
                            setShowView('laundry_monitoring_records');
                            setLaundryRecordModel(null);
                        }}
                    >
                        <LaundryRecordFormDetail />
                        <LaundryRecordForm />
                    </LaundryRecordFormProvider>
                </>
            )}
        </>
    );

};

export default LaundryMonitoringContainer;