import React, { useEffect } from "react";

const NotFoundPage = () => {

    useEffect(() => {
        console.log('ingreso aqui');
    }, []);

    return (
        <div>PAGINA NO ENCONTRADA</div>
    );

};
export default NotFoundPage;