import { Navigate } from 'react-router-dom';
import LocalStorageHelper from './helpers/LocalStorageHelper';
import RoutePathHelper from './helpers/RoutePathHelper';

const RedirectRoute = () => {
    const route = LocalStorageHelper.getRouteData();

    if (RoutePathHelper.getAllPaths().includes(route)) {
        return <Navigate to={route} />
    }

    return <Navigate to={'/login'} />;
};

export default RedirectRoute;