import { Button } from "antd";
import { PartialPaymentContext } from "../../contexts/PartialPaymentContext";
import { useContext } from "react";

const PartialPaymentCreate = () => {

    const { setShowCreatePartialPaymentDetail } = useContext(PartialPaymentContext);

    const onClick = () => {
        setShowCreatePartialPaymentDetail(true);
    };

    return (
        <div style={{ textAlign: 'right', marginBottom: '10px' }}>
            <Button type="primary" onClick={onClick}>Nuevo Pago Parcial</Button>
        </div>
    );

};

export default PartialPaymentCreate;