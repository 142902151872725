import { useMediaQuery } from "react-responsive";
import { ProductStockContext } from "../../contexts/ProductStockContext";
import { Button, Card, Dropdown, List, Pagination, Table, Tag } from "antd";
import DateUtil from "../../utils/DateUtil";
import { SettingOutlined } from "@ant-design/icons";
import { useContext } from "react";

const ProductStockRecords = () => {

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const { data, getMenu, tableParams, getProductStocksByPagination } = useContext(ProductStockContext);

    const onChangeTable = (pagination, filters, sorter) => {
        getProductStocksByPagination(pagination.current, pagination.pageSize);
    };

    const onChangeList = (page, pageSize) => {
        getProductStocksByPagination(page, pageSize);
    };

    const onShowSizeChange = (current, size) => {
        getProductStocksByPagination(current, size);
    };

    return (isMobile ?
        <>
            <List
                itemLayout="vertical"
                dataSource={data}
                renderItem={(model, index) => (
                    <List.Item key={index}>
                        <Card bordered={false}>
                            <div className="RowContainer">
                                <div className="ColumnContainer">
                                    <div><strong>Histórico:</strong> {model.is_current ? 'Actual' : 'Pasado'}</div>
                                    <div><strong>Nota:</strong> {model.note}</div>
                                    <div><strong>Modificación:</strong>
                                        {model.change_type && model.change_type === 'add' && (
                                            <Tag color="green">{`+${model.change_amount}`}</Tag>
                                        )}
                                        {model.change_type && model.change_type === 'reduce' && (
                                            <Tag color="red">{`-${model.change_amount}`}</Tag>
                                        )}
                                    </div>
                                    <div><strong>Stock:</strong> {model.stock}</div>
                                    <div><strong>Registrado:</strong> {DateUtil.ToSimpleString(model.created_at)}</div>
                                </div>
                                <div className="ColumnContainer ColumnContainerVentas" style={{ width: '100%' }}>
                                    <Dropdown menu={getMenu(model)} trigger={['click']} >
                                        <Button icon={<SettingOutlined />}>Acciones</Button>
                                    </Dropdown>
                                </div>
                            </div>
                        </Card>
                    </List.Item>
                )}
            />
            <Pagination
                pageSize={tableParams.pagination.pageSize}
                showSizeChanger={tableParams.pagination.showSizeChanger}
                onShowSizeChange={onShowSizeChange}
                total={tableParams.pagination.total}
                pageSizeOptions={tableParams.pagination.pageSizeOptions}
                current={tableParams.pagination.current}
                onChange={onChangeList}
            />
        </> :
        <Table
            pagination={tableParams.pagination}
            bordered
            dataSource={data}
            rowKey={(record) => record.id}
            onChange={onChangeTable}
            columns={[
                {
                    title: 'Histórico', key: 'is_current', width: '100px', render: (record) => {
                        return (
                            <>{record.is_current ? 'Actual' : 'Pasado'}</>
                        );
                    }
                },
                { title: 'Nota', dataIndex: 'note', key: 'note' },
                {
                    title: 'Modificación', width: '100px', key: 'change_amount', render: (record) => {
                        return (
                            <>
                                {record.change_type && record.change_type === 'add' && (
                                    <Tag color="green">{`+${record.change_amount}`}</Tag>
                                )}
                                {record.change_type && record.change_type === 'reduce' && (
                                    <Tag color="red">{`-${record.change_amount}`}</Tag>
                                )}
                            </>
                        );
                    }
                },
                { title: 'Stock', dataIndex: 'stock', width: '100px', key: 'stock' },
                {
                    title: 'Registrado', dataIndex: 'created_at', width: 200, key: 'created_at', render: (value) => {
                        return (<>{DateUtil.ToSimpleString(value)}</>);
                    }
                },
                {
                    title: 'Opciones', key: 'Acciones', width: 150, render: (value) => {
                        return (
                            <>
                                <Dropdown trigger={['click']} menu={getMenu(value)}>
                                    <Button icon={<SettingOutlined />}>Acciones</Button>
                                </Dropdown>
                            </>
                        );
                    }
                },
            ]}
        />
    );
};

export default ProductStockRecords;