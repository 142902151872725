import { useContext } from "react";
import ParseHelper from "../../helpers/ParseHelper";
import DateUtil from "../../utils/DateUtil";
import { Button, DatePicker, Form, Input, Select } from "antd";
import GeneralHelper from "../../helpers/GeneralHelper";
import { GuestContext } from "../../contexts/GuestContext";

const GuestFilter = () => {

    const { getGuestsByFilter } = useContext(GuestContext);

    const onFinish = (values) => {
        getGuestsByFilter({
            full_name: ParseHelper.parseString(values.full_name),
            company: ParseHelper.parseString(values.company),
            document_type: ParseHelper.parseString(values.document_type),
            document_number: ParseHelper.parseString(values.document_number),
            start_date: DateUtil.ToServerDateString(values.start_date),
            end_date: DateUtil.ToServerEndString(values.end_date),
            room_code: ParseHelper.parseString(values.room_code),
            guest_status: values.guest_status
        });
    };

    return (
        <Form
            className="FilterContainer"
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
            initialValues={{
                guest_status: 'HOSPEDADO'
            }}
        >
            <Form.Item name={"full_name"} label={"Filtro por nombre completo"}>
                <Input />
            </Form.Item>
            <Form.Item name={"company"} label={"Filtro por empresa"}>
                <Input />
            </Form.Item>
            <Form.Item name={"document_type"} label={"Filtro por tipo de documento"}>
                <Select allowClear options={GeneralHelper.getCustomerDocumentSelectOptions()} />
            </Form.Item>
            <Form.Item name={"document_number"} label={"Filtro por número de documento"}>
                <Input />
            </Form.Item>
            <Form.Item name={"room_code"} label={"Filtro por habitación"}>
                <Input />
            </Form.Item>
            <Form.Item name={"start_date"} label={"Filtro por fecha de ingreso"}>
                <DatePicker format="DD/MM/YYYY" style={{ width: '250px' }} />
            </Form.Item>
            <Form.Item name={"end_date"} label={"Filtro por fecha de salida"}>
                <DatePicker format="DD/MM/YYYY" style={{ width: '250px' }} />
            </Form.Item>
            <Form.Item name={"guest_status"} label={"Filtro por estado"}>
                <Select
                    options={[
                        { label: 'HOSPEDADO', value: 'HOSPEDADO' },
                        { label: 'SALIDA', value: 'SALIDA' }
                    ]}
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">Buscar</Button>
            </Form.Item>
        </Form>
    );

};

export default GuestFilter;