import { useContext } from "react";
import LaundryConsumptionDetailContext from "../../contexts/LaundryConsumptionDetailContext";
import { useMediaQuery } from "react-responsive";
import DateUtil from "../../utils/DateUtil";
import { Card, List, Table } from "antd";

const LaundryConsumptionDetailRecords = () => {

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const { data } = useContext(LaundryConsumptionDetailContext);

    return (
        <Card
            title={'ESTADO DE LAVADO'}
            style={{ marginBottom: '20px' }}
        >
            {isMobile ?
                <>
                    <List
                        itemLayout="vertical"
                        dataSource={data}
                        renderItem={(model, index) => (
                            <List.Item key={index}>
                                <Card bordered={false}>
                                    <div className="RowContainer">
                                        <div className="ColumnContainer">
                                            <div><strong>Estado:</strong> {model.laundry_status}</div>
                                            <div><strong>Histórico:</strong> {model.is_current ? 'ACTUAL' : 'PASADO'}</div>
                                            <div><strong>Nota:</strong> {model.laundry_note}</div>
                                            <div><strong>Registrado:</strong> {DateUtil.ToSimpleString(model.created_at)}</div>
                                        </div>
                                    </div>
                                </Card>
                            </List.Item>
                        )}
                    />
                </> :
                <Table
                    pagination={false}
                    bordered
                    dataSource={data}
                    rowKey={(record) => record.id}
                    columns={[
                        {
                            title: 'Registrado', key: 'created_at', render: (model) => {
                                return (<>{DateUtil.ToSimpleString(model.created_at)}</>);
                            }
                        },
                        {
                            title: 'Histórico', key: 'historic', render: (model) => {
                                return (<>{model.is_current ? 'ACTUAL' : 'PASADO'}</>);
                            }
                        },
                        {
                            title: 'Responsable', key: 'laundry_by', render: (model) => {
                                return (<>{model.full_name}</>);
                            }
                        },
                        {
                            title: 'Nota', key: 'laundry_by', render: (model) => {
                                return (<>{model.laundry_note}</>);
                            }
                        },
                        {
                            title: 'Estado', key: 'status', render: (model) => {
                                return (<>{model.laundry_status}</>);
                            }
                        }
                    ]}
                />
            }
        </Card>
    );

};

export default LaundryConsumptionDetailRecords;