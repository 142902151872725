import GeneralHelper from "../helpers/GeneralHelper";
import ApiService from "./ApiService";

class BookingService {

    static pagination(params, pageNumber, pageSize, active) {
        return ApiService.get('/bookings', { ...params, page_number: pageNumber, page_size: pageSize, booking_active: active }, true);
    }

    static create(body) {
        return ApiService.post('/bookings', { ...body, created_at: GeneralHelper.getLimaPeruTime() }, true);
    }

    static update(id, body) {
        return ApiService.patch(`/bookings/${id}`, { ...body, type: 'data', created_at: GeneralHelper.getLimaPeruTime() }, true);
    }

    static updateActive(id, value) {
        return ApiService.patch(`/bookings/${id}`, { booking_active: value, type: 'active' }, true);
    }

    static listByMonthAndYear(params) {
        return ApiService.get('/bookings/listByMonthAndYear', params, true);
    }

    static formData(id) {
        return ApiService.get(`/bookings/formData/${id}`, {}, true);
    }

}

export default BookingService;