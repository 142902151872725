import { Layout, Menu } from "antd";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import MenuHelper from "../../helpers/MenuHelper";
import { LayoutContext } from "../../contexts/LayoutContext";
import { useContext } from "react";

const LayoutSider = () => {

    const { userMenu, setCollapseMenu } = useContext(LayoutContext);

    const defaultSelectedKey = MenuHelper.getMenuKeyByRoute(LocalStorageHelper.getRouteData());

    const onCollapse = (collapse, type) => {
        if (!collapse) {
            setCollapseMenu(collapse);
            return;
        }
        setTimeout(() => {
            setCollapseMenu(collapse);
        }, 200);
    };

    return (
        <Layout.Sider onCollapse={onCollapse} breakpoint="lg" collapsedWidth="0" width={'250px'}>
            <div className='LogoBox'>
                <h1>Hotel Admin</h1>
            </div>
            <Menu defaultSelectedKeys={[defaultSelectedKey]} theme="dark" mode="inline" items={userMenu} />
        </Layout.Sider>
    );
}

export default LayoutSider;