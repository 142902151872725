import ValidationHelper from "../helpers/ValidationHelper";

class GeneralUtil {
    getMoney(money) {
        const firstValidation = ValidationHelper.hasData(money);
        if (!firstValidation) return '';
        const secondValidation = ValidationHelper.isValidMoney(money);
        if (!secondValidation) return '';
        return `S/ ${Number(money).toFixed(2)}`;
    }
}

export default new GeneralUtil();