import { useContext } from "react";
import { LoginContext } from "../../contexts/LoginContext";
import { Button, Form, Input } from "antd";
import RulesHelper from "../../helpers/RulesHelper";

const LoginForm = () => {
    const { form, login } = useContext(LoginContext);
    const onFinish = (values) => {
        login(values);
    };
    return (
        <Form form={form} onFinish={onFinish} autoComplete="off" layout="vertical">
            <Form.Item label={'Email'} name={'email'} rules={RulesHelper.email()}>
                <Input />
            </Form.Item>
            <Form.Item label={'Contraseña'} name={'password'} rules={RulesHelper.required()}>
                <Input.Password />
            </Form.Item>
            <Form.Item>
                <Button style={{ width: '100%' }} type="primary" htmlType="submit">Ingresar</Button>
            </Form.Item>
        </Form>
    );
};

export default LoginForm;