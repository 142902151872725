import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc.js';
import timezone from 'dayjs/plugin/timezone.js';

class GeneralHelper {
    getMilisecondsByDays(days) {
        return (1000 * 60 * 60 * 24 * Number(days));
    }
    getMilisecondsByHours(hours) {
        return (1000 * 60 * 60 * Number(hours));
    }
    getLimaPeruTime() {
        dayjs.extend(utc);
        dayjs.extend(timezone);
        return dayjs().tz('America/Lima').format('YYYY-MM-DD HH:mm:ss');
    }
    getDayJsLimaPeru() {
        dayjs.extend(utc);
        dayjs.extend(timezone);
        return dayjs().tz('America/Lima');
    }
    getGenderSelectOptions() {
        return [
            { value: 'MASCULINO', label: 'MASCULINO' },
            { value: 'FEMENINO', label: 'FEMENINO' },
        ];
    }
    getTablePaginationDefault() {
        const table = {
            pagination: {
                current: 1,
                pageSize: 10,
                pageSizeOptions: ['10', '25', '50', '100'],
                showSizeChanger: true,
                total: 0
            }
        };
        return table;
    }
    getCustomerDocumentSelectOptions() {
        return [
            { value: 'DNI', label: 'DNI' },
            { value: 'CARNET DE EXTRANJERÍA', label: 'CARNET DE EXTRANJERÍA' },
            { value: 'PASAPORTE', label: 'PASAPORTE' },
        ];
    }
}

export default new GeneralHelper();