import { Button, DatePicker, Form, Input } from "antd";
import { RoomHistoricCleaningContext } from "../../contexts/RoomHistoricCleaningContext";
import RulesHelper from "../../helpers/RulesHelper";
import ParseHelper from "../../helpers/ParseHelper";
import { useContext } from "react";
import DateUtil from "../../utils/DateUtil";

const RoomHistoricCleaningFilter = () => {

    const { getHistoricByFilter } = useContext(RoomHistoricCleaningContext);

    const onFinish = (values) => {

        const cleaner_full_name = ParseHelper.parseString(values.cleaner_full_name);

        const applicant_full_name = ParseHelper.parseString(values.applicant_full_name);

        const start_date = DateUtil.ToServerString(values.start_date);

        const end_date = DateUtil.ToServerEndString(values.end_date);

        getHistoricByFilter({
            cleaner_full_name,
            applicant_full_name,
            start_date,
            end_date
        });

    };

    return (
        <Form className="FilterContainer" layout="vertical" onFinish={onFinish}>
            <Form.Item name={"start_date"} label={"Filtro por fecha de inicio"}>
                <DatePicker format="DD/MM/YYYY" style={{ width: '250px' }} />
            </Form.Item>
            <Form.Item name={"end_date"} label={"Filtro por fecha fin"}>
                <DatePicker format="DD/MM/YYYY" style={{ width: '250px' }} />
            </Form.Item>
            <Form.Item name={"applicant_full_name"} label={"Filtro por solicitante"} rules={RulesHelper.maxNotRequired(250)}>
                <Input />
            </Form.Item>
            <Form.Item name={"cleaner_full_name"} label={"Filtro por responsable"} rules={RulesHelper.maxNotRequired(250)}>
                <Input />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">Buscar</Button>
            </Form.Item>
        </Form>
    );

};

export default RoomHistoricCleaningFilter;