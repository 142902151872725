import { createContext, useState, useEffect } from "react";
import LocalStorageHelper from "../helpers/LocalStorageHelper";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/AuthService";
import TokenHelper from "../helpers/TokenHelper";
import ApiService from "../services/ApiService";
import MenuHelper from "../helpers/MenuHelper";
import RoleKeyHelper from "../helpers/RoleKeyHelper";
import EnvConfig from "../config/EnvConfig";

export const LayoutContext = createContext();

export const LayoutProvider = ({ children }) => {

    const navigate = useNavigate();

    const user = JSON.parse(LocalStorageHelper.getUserData());

    const [userMenu, setUserMenu] = useState([]);
    const [collapseMenu, setCollapseMenu] = useState(true);
    const [websocketData, setWebsocketData] = useState({});
    const [loading, setLoading] = useState(true);
    const [showLayout, setShowLayout] = useState(false);

    const logoutService = () => {
        AuthService.logout(LocalStorageHelper.getTokenId()).catch(err => { });
    }

    const logoutEvent = () => {
        localStorage.clear();
        navigate('/login');
    }

    const handlerTokenExpiration = () => {

        const result = TokenHelper.checkTokenExpiration();

        if (result === 'logout') {
            logoutEvent();
            return;
        }

        if (result === 'update') {
            ApiService.updateTokens();
            return;
        }

    }

    useEffect(() => {
        const intervalId = setInterval(handlerTokenExpiration, 1000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {

        if (user.role === RoleKeyHelper.getAdminKey()) {
            const userMenuData = MenuHelper.getAdmin(navigate);
            setUserMenu(userMenuData);
        } else if (user.role === RoleKeyHelper.getHotelCleaningStaff()) {
            const userMenuData = MenuHelper.getCleaningStaff(navigate);
            setUserMenu(userMenuData);
        } else if (user.role === RoleKeyHelper.getLaundryReceptionist()) {
            const userMenuData = MenuHelper.getLaundryReceptionist(navigate);
            setUserMenu(userMenuData);
        } else if (user.role === RoleKeyHelper.getHotelReceptionist()) {
            const userMenuData = MenuHelper.getHotelReceptionist(navigate);
            setUserMenu(userMenuData);
        }

    }, []);

    useEffect(() => {
        const ws = new WebSocket(EnvConfig.getWebsocketUrl());
        ws.onopen = () => {
            const accessToken = LocalStorageHelper.getAccessTokenData();
            ws.send(JSON.stringify({ accessToken, service: 'USER_ACTIVE_VERIFICATION' }));
        };
        ws.onmessage = (event) => {
            const { data } = event;
            const json_data = JSON.parse(data);
            setWebsocketData(json_data);
        };
        return () => {
            if (ws) ws.close();
        };
    }, []);

    useEffect(() => {
        const { event } = websocketData;
        if (event === "USER_LOGOUT") logoutEvent();
    }, [websocketData]);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    useEffect(() => {
        if (!loading) setShowLayout(true);
    }, [loading]);

    return (
        <LayoutContext.Provider value={{
            userMenu, loading, showLayout,
            collapseMenu, setCollapseMenu,
            logoutService, logoutEvent
        }}>
            {children}
        </LayoutContext.Provider>
    );
};