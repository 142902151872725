import { Button, Form, Input, Modal, Select } from "antd";
import RulesHelper from "../../helpers/RulesHelper";
import { ConsumptionContext } from "../../contexts/ConsumptionContext";
import { useContext, useState } from "react";

const ConsumptionSaleModal = () => {

    const {
        modalSale, setModalSale,
        createSaleByConsumptions
    } = useContext(ConsumptionContext);

    const [form] = Form.useForm();

    const [showExtraInputs, setShowExtraInputs] = useState(false);

    const onCancel = () => {
        setModalSale(false);
        form.resetFields();
    };

    const onFinish = (values) => {
        createSaleByConsumptions(values);
    };

    return (
        <Modal
            title={'Nueva Venta'}
            open={modalSale}
            footer={null}
            onCancel={onCancel}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item name={"sale_type"} label={"Tipo de venta"} rules={RulesHelper.required()}>
                    <Select
                        placeholder='Por favor, selecciona un tipo de pago.'
                        allowClear
                        options={[
                            { label: 'Factura', value: 'FACTURA' },
                            { label: 'Boleta', value: 'BOLETA' }
                        ]}
                        onChange={(value) => {
                            setShowExtraInputs(value === 'FACTURA');
                        }}
                    />
                </Form.Item>
                <Form.Item name={"type_of_transfer"} label={"Tipo de transferencia"} rules={RulesHelper.required()}>
                    <Select
                        placeholder='Por favor, selecciona un tipo de transferencia.'
                        allowClear
                        options={[
                            { label: 'YAPE', value: 'YAPE' },
                            { label: 'PLIN', value: 'PLIN' },
                            { label: 'TRANSFERENCIA', value: 'TRANSFERENCIA' },
                            { label: 'EFECTIVO', value: 'EFECTIVO' },
                            { label: 'TARJETA DEBITO', value: 'TARJETA DEBITO' },
                            { label: 'TARJETA CREDITO', value: 'TARJETA CREDITO' }
                        ]}
                    />
                </Form.Item>
                {showExtraInputs && (
                    <>
                        <Form.Item name={'ruc'} label='RUC' rules={RulesHelper.max(11)}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={'razon_social'} label='Razón Social' rules={RulesHelper.max(250)}>
                            <Input />
                        </Form.Item>
                    </>
                )}
                <Form.Item>
                    <Button type="primary" htmlType="submit">Registrar</Button>
                </Form.Item>
            </Form>
        </Modal>
    );

};

export default ConsumptionSaleModal;