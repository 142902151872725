import GeneralHelper from "../helpers/GeneralHelper";
import ApiService from "./ApiService";

class ConsumptionService {

    getListByCustomerAccountId(params) {
        return ApiService.get('/consumptions', params, true);
    }

    createProductConsumption(body) {
        return ApiService.post('/consumptions', { ...body, created_at: GeneralHelper.getLimaPeruTime() }, true);
    }

    disable(id) {
        return ApiService.post(`/consumptions/disable/${id}`, {}, true);
    }

    getPartialPaymentsDetails(consumptionId) {
        return ApiService.get(`/consumptions/${consumptionId}/partialPaymentsDetails`, {}, true);
    }

    createPartialPaymentsDetail(consumptionId, amount) {
        return ApiService.post(`/consumptions/${consumptionId}/partialPaymentsDetails/create`, {
            amount,
            created_at: GeneralHelper.getLimaPeruTime()
        }, true);
    }

    deletePartialPaymentsDetail(consumptionId, partialPaymentDetailId) {
        return ApiService.post(`/consumptions/${consumptionId}/partialPaymentsDetails/${partialPaymentDetailId}/delete`, {}, true);
    }

    disableLaundryConsumption(consumption_id) {
        return ApiService.post(`/consumptions/${consumption_id}/laundry-consumption/disable`, {}, true);
    }

    laundryConsumptionDetail(consumption_id) {
        return ApiService.get(`/consumptions/${consumption_id}/laundry-consumption/detail`, {}, true);
    }

}

export default new ConsumptionService();