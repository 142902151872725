import { createContext, useEffect, useState } from "react";
import GeneralHelper from "../helpers/GeneralHelper";
import RoomService from "../services/RoomService";
import { message } from "antd";

export const RoomHistoricCleaningContext = createContext();

export const RoomHistoricCleaningProvider = ({ children, room }) => {

    const [data, setData] = useState([]);

    const [tableParams, setTableParams] = useState(GeneralHelper.getTablePaginationDefault);

    const [formFilters, setFormFilters] = useState({
        start_date: '',
        end_date: '',
        cleaner_full_name: '',
        applicant_full_name: ''
    });

    const getHistoricDefault = () => {
        RoomService.historic(
            formFilters,
            tableParams.pagination.current,
            tableParams.pagination.pageSize,
            room.id
        ).then(response => {

            setData(response.data);

            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    total: response.count
                }
            });

        }).catch(error => {

            console.log(error);

            message.error('Ocurrion un error con el servicio.');

        });
    };

    const getHistoricByFilter = (values) => {
        RoomService.historic(
            values,
            1,
            tableParams.pagination.pageSize,
            room.id
        ).then(response => {

            setData(response.data);

            setFormFilters(values);

            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    total: response.count,
                    current: 1,
                }
            });

        }).catch(error => {

            console.log(error);

            message.error('Ocurrion un error con el servicio.');

        });
    };

    const getHistoricByPagination = (page, pageSize) => {
        RoomService.historic(
            formFilters,
            page,
            pageSize,
            room.id
        ).then(response => {

            setData(response.data);

            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    pageSize: pageSize,
                    current: page,
                }
            });

        }).catch(error => {

            console.log(error);

            message.error('Ocurrion un error con el servicio.');

        });
    };

    useEffect(() => {
        getHistoricDefault();
    }, []);

    return (
        <RoomHistoricCleaningContext.Provider value={{
            data, getHistoricByFilter, getHistoricByPagination,
            room, tableParams
        }}>
            {children}
        </RoomHistoricCleaningContext.Provider>
    );

};
