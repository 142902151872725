import { createContext, useEffect, useState } from "react";
import ConsumptionService from "../services/ConsumptionService";
import { message } from "antd";

export const LaundryConsumptionDetailContext = createContext();

export const LaundryConsumptionDetailProvider = ({ children, consumption_id }) => {

    const [data, setData] = useState([]);

    const [consumption, setConsumption] = useState(null);

    const [laundry, setLaundry] = useState(null);

    const getLaundryConsumptionDetail = () => {
        ConsumptionService.laundryConsumptionDetail(consumption_id).then(response => {
            setData(response.laundry_records);
            setConsumption(response.consumption);
            setLaundry(response.laundry);
        }).catch(error => {
            console.log(error);
            message.error('Ocurrió un error en el servicio.');
        });
    };

    useEffect(() => {
        if (consumption_id) getLaundryConsumptionDetail();
    }, [consumption_id]);

    return (
        <LaundryConsumptionDetailContext.Provider value={{
            data, consumption, laundry,
            getLaundryConsumptionDetail
        }}>
            {children}
        </LaundryConsumptionDetailContext.Provider>
    );

}

export default LaundryConsumptionDetailContext;