import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { SaleContext } from "../../contexts/SaleContext";
import { Button, Card, List, Pagination, Table } from "antd";
import GeneralUtil from "../../utils/GeneralUtil";
import DateUtil from "../../utils/DateUtil";

const SaleRecords = () => {

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const {
        data, tableParams, getSalesByPagination,
        setShowView, setSaleSelected
    } = useContext(SaleContext);

    const onChangeTable = (pagination, filters, sorter) => {
        getSalesByPagination(pagination.current, pagination.pageSize);
    };

    const onChangeList = (page, pageSize) => {
        getSalesByPagination(page, pageSize);
    };

    const onShowSizeChange = (current, size) => {
        getSalesByPagination(current, size);
    };

    const handlerShowDetail = (model) => {
        setSaleSelected(model);
        setShowView('sale_detail');
    };

    return (isMobile ?
        <>
            <List
                itemLayout="vertical"
                dataSource={data}
                renderItem={(model, index) => (
                    <List.Item>
                        <Card bordered={false}>
                            <div className="RowContainer">
                                <div className="ColumnContainer">
                                    <div><strong>Código:</strong> {model.code}</div>
                                    <div><strong>Total:</strong> {GeneralUtil.getMoney(model.total)}</div>
                                    <div><strong>Cliente:</strong> {model.name} {model.lastname}</div>
                                    <div><strong>{model.document_type}:</strong> {model.document_number}</div>
                                    <div><strong>Tipo:</strong> {model.type}</div>
                                    <div><strong>Registrado:</strong> {DateUtil.ToSimpleString(model.created_at)}</div>
                                </div>
                                <div className="ColumnContainer ColumnContainerVentas" style={{ width: '100%' }}>
                                    <Button onClick={() => handlerShowDetail(model)}>Ver Detalle</Button>
                                </div>
                            </div>
                        </Card>
                    </List.Item>
                )}
            />
            <Pagination
                pageSize={tableParams.pagination.pageSize}
                showSizeChanger={tableParams.pagination.showSizeChanger}
                onShowSizeChange={onShowSizeChange}
                total={tableParams.pagination.total}
                pageSizeOptions={tableParams.pagination.pageSizeOptions}
                current={tableParams.pagination.current}
                onChange={onChangeList}
            />
        </> :
        <Table
            pagination={tableParams.pagination}
            bordered
            dataSource={data}
            rowKey={(record) => record.id}
            onChange={onChangeTable}
            columns={[
                { title: 'Código', width: '100px', dataIndex: 'code', key: 'code', fixed: true },
                {
                    title: 'Cliente', key: 'customer', render: (model) => {
                        return (
                            <>
                                <div><strong>Nombre Completo:</strong> {model.name} {model.lastname}</div>
                                <div><strong>{model.document_type}:</strong> {model.document_number}</div>
                            </>
                        );
                    }
                },
                {
                    title: 'Tipo', dataIndex: 'type', width: '200px', key: 'type', render: (value) => {
                        return (<>{value}</>);
                    }
                },
                {
                    title: 'Total', width: '100px', dataIndex: 'total', key: 'total', render: (value) => {
                        return (<>{GeneralUtil.getMoney(value)}</>);
                    }
                },
                {
                    title: 'Registrado', key: 'created_at', render: (model) => {
                        return (<>{DateUtil.ToSimpleString(model.created_at)}</>);
                    }
                },
                {
                    title: 'Opciones', key: 'Acciones', width: 150, render: (model) => (
                        <Button onClick={() => handlerShowDetail(model)}>Ver Detalle</Button>
                    )
                }
            ]}
        />
    );
};

export default SaleRecords;