import { useParams } from "react-router-dom";
import { ConsumptionProvider } from "../contexts/ConsumptionContext";
import ConsumptionContainer from "../components/ConsumptionComponents/ConsumptionContainer";

const ConsumptionPage = () => {

    const { customer_account_id } = useParams();

    return (
        <>
            <ConsumptionProvider customer_account_id={customer_account_id}>
                <h1 className="TituloContenido">Consumos del cliente</h1>
                <ConsumptionContainer />
            </ConsumptionProvider>
        </>
    );
};

export default ConsumptionPage;