import { useContext, useEffect, useState } from "react";
import LaundryConsumptionDetailContext from "../../contexts/LaundryConsumptionDetailContext";
import GeneralUtil from "../../utils/GeneralUtil";
import DateUtil from "../../utils/DateUtil";
import { Card, Descriptions } from "antd";

const LaundryConsumptionDetail = () => {

    const { consumption, laundry } = useContext(LaundryConsumptionDetailContext);

    const [items, setItems] = useState([]);

    useEffect(() => {

        if (consumption && laundry) {

            const dataDefault = [
                { key: 1, label: 'Tipo', children: `LAVANDERÍA` },
                { key: 2, label: 'Monto', children: `${GeneralUtil.getMoney(consumption.amount)}` },
                { key: 3, label: 'Peso(k)', children: `${laundry.laundry_weight}` },
                { key: 4, label: 'Registrado', children: `${DateUtil.ToSimpleString(consumption.created_at)}` }
            ];

            setItems(dataDefault);

        }

    }, [consumption, laundry]);

    return (
        <>
            {items.length > 0 &&
                <div style={{ marginBottom: '20px' }}>
                    <Card bordered={false} title={'DETALLE CONSUMO'}>
                        <Descriptions items={items} bordered />
                    </Card>
                </div>
            }
        </>
    );

};

export default LaundryConsumptionDetail;