import { Switch } from "antd";
import { RoomContext } from "../../contexts/RoomContext";
import { useContext } from "react";

const RoomChangeData = () => {

    const { setSwitchDataType, switchDataType } = useContext(RoomContext);

    const onChange = (value) => {
        setSwitchDataType(value);
    };

    return (
        <div style={{ textAlign: 'right', marginBottom: '10px' }}>
            <Switch checkedChildren="Activos" unCheckedChildren="Eliminados" checked={switchDataType} onChange={onChange} />
        </div>
    );
};

export default RoomChangeData;