import { Button, Form, Input, Modal } from "antd";
import { PartialPaymentContext } from "../../contexts/PartialPaymentContext";
import RulesHelper from "../../helpers/RulesHelper";
import { useContext } from "react";

const PartialPaymentCreateModal = () => {

    const {
        formCreatePartialPaymentDetail,
        createPartialPaymentDetail,
        showCreatePartialPaymentDetail,
        setShowCreatePartialPaymentDetail,
    } = useContext(PartialPaymentContext);

    const onCancel = () => {
        setShowCreatePartialPaymentDetail(false);
        formCreatePartialPaymentDetail.resetFields();
    };

    const onFinish = () => {
        createPartialPaymentDetail();
    };

    return (
        <Modal
            title={'Nueva Pago Parcial'}
            open={showCreatePartialPaymentDetail}
            footer={null}
            onCancel={onCancel}
        >
            <Form
                form={formCreatePartialPaymentDetail}
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item name={"amount"} label={"Monto"} rules={RulesHelper.money()}>
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Registrar</Button>
                </Form.Item>
            </Form>
        </Modal>
    );

};

export default PartialPaymentCreateModal;