import { Form, message } from "antd";
import { createContext, useEffect, useState } from "react";
import ServiceError from "../errors/ServiceError";
import ProductService from "../services/ProductService";
import GeneralHelper from "../helpers/GeneralHelper";

export const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
    const [form] = Form.useForm();
    const [showView, setShowView] = useState('products');
    const [switchDataType, setSwitchDataType] = useState(true);
    const [model, setModel] = useState(null);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [tableParams, setTableParams] = useState(GeneralHelper.getTablePaginationDefault());
    const [formFilters, setFormFilters] = useState({ product_code: '', product_name: '' });
    const [openDelete, setOpenDelete] = useState(false);

    const getProductsDefault = () => {
        ProductService.pagination(
            formFilters,
            tableParams.pagination.current,
            tableParams.pagination.pageSize,
            switchDataType
        ).then(response => {
            setData(response.data);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    total: response.count
                }
            });
        }).catch(error => {
            console.log(error);
            message.error('Ocurrion un error con el servicio.');
        });
    };

    const getProductsByFilter = (values) => {
        ProductService.pagination(
            values,
            1,
            tableParams.pagination.pageSize,
            switchDataType
        ).then(response => {
            setData(response.data);
            setFormFilters(values);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    total: response.count,
                    current: 1,
                }
            });
        }).catch(error => {
            console.log(error);
            message.error('Ocurrion un error con el servicio.');
        });
    };

    const getProductsByPagination = (page, pageSize) => {
        ProductService.pagination(
            formFilters,
            page,
            pageSize,
            switchDataType
        ).then(response => {
            setData(response.data);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    pageSize: pageSize,
                    current: page,
                }
            });
        }).catch(error => {
            console.log(error);
            message.error('Ocurrion un error con el servicio.');
        });
    };

    const createProduct = (values) => {
        ProductService.create(values).then(response => {
            getProductsDefault();
            setOpen(false);
            setModel(null);
            form.resetFields();
            message.success('Se agregó el producto.');
        }).catch(err => {
            if (err instanceof ServiceError) {
                if (err.statusCode === 422) {
                    message.warning('Existe un producto con el mismo código.');
                } else {
                    message.error('Ocurrion un error con el servicio.');
                }
            }
        });
    };
    const updateProduct = (values) => {
        ProductService.update(model.id, values).then(response => {
            getProductsDefault();
            setOpen(false);
            setModel(null);
            form.resetFields();
            message.success('Se actualizó el producto.');
        }).catch(err => {
            message.error('Ocurrion un error con el servicio.');
        });
    };
    const updateActive = (value) => {
        ProductService.updateActive(model.id, value).then(response => {
            getProductsDefault();
            setOpenDelete(false);
            setModel(null);
            message.success(`Se ${value ? 'recuperó' : 'eliminó'} el producto.`);
        }).catch(err => {
            message.error('Ocurrion un error con el servicio.');
        });
    };
    const getMenu = (value) => {
        let items = {};
        if (switchDataType) {
            items = [
                {
                    key: 'edit', label: 'Editar', onClick: () => {
                        setModel(value);
                        form.setFieldsValue({ ...value });
                        setOpen(true);
                    }
                },
                {
                    key: 'stock', label: 'Stock', onClick: () => {
                        setModel(value);
                        setShowView('stocks');
                    }
                },
                {
                    key: 'delete', label: 'Eliminar', onClick: () => {
                        setModel(value);
                        setOpenDelete(true);
                    }
                }
            ];
        } else {
            items = [
                {
                    key: 'enable', label: 'Recuperar', onClick: () => {
                        setModel(value);
                        setOpenDelete(true);
                    }
                }
            ];
        }
        const menu = { items };
        return menu;
    };
    useEffect(() => {
        getProductsDefault();
    }, [switchDataType]);
    return (
        <ProductContext.Provider value={{
            form,
            openDelete, setOpenDelete,
            data, setData,
            tableParams, setTableParams,
            formFilters, setFormFilters,
            model, setModel,
            switchDataType, setSwitchDataType,
            open, setOpen,
            showView, setShowView,
            getProductsByFilter, getProductsByPagination,
            createProduct, updateProduct, updateActive, getMenu, getProductsDefault
        }}>
            {children}
        </ProductContext.Provider>
    );
};