import { Navigate } from 'react-router-dom';
import LocalStorageHelper from './helpers/LocalStorageHelper';
import RoleKeyHelper from './helpers/RoleKeyHelper';

const ProtectedRoute = ({ path, allowedRoles, children }) => {

    const user = JSON.parse(LocalStorageHelper.getUserData());

    if (user === null) {
        localStorage.clear();
        return <Navigate to="/login" />;
    }

    const { role } = user;

    if (!allowedRoles.includes(role)) {
        switch (role) {
            case RoleKeyHelper.getHotelReceptionist(): {
                LocalStorageHelper.updateRoute("/monitoreo");
                return <Navigate to="/monitoreo" />;
            }
            case RoleKeyHelper.getLaundryReceptionist(): {
                LocalStorageHelper.updateRoute("/monitoreo-lavanderia");
                return <Navigate to="/monitoreo-lavanderia" />;
            }
            case RoleKeyHelper.getHotelCleaningStaff(): {
                LocalStorageHelper.updateRoute("/monitoreo-limpieza");
                return <Navigate to="/monitoreo-limpieza" />;
            }
            case RoleKeyHelper.getAdminKey(): {
                break;
            }
            default: {
                localStorage.clear();
                return <Navigate to="/login" />;
            }
        }
    }

    LocalStorageHelper.updateRoute(path);

    return (<>{children}</>);
};

export default ProtectedRoute;