import { useContext } from "react";
import { RoomContext } from "../../contexts/RoomContext";
import RoomCreate from "./RoomCreate";
import RoomChangeData from "./RoomChangeData";
import RoomFilter from "./RoomFilter";
import RoomRecords from "./RoomRecords";
import RoomModal from "./RoomModal";
import RoomActiveModal from "./RoomActiveModal";
import { Breadcrumb, Card } from "antd";
import RoomHistoricCleaningDetail from "../RoomHistoricCleaningComponents/RoomHistoricCleaningDetail";
import { RoomHistoricCleaningProvider } from "../../contexts/RoomHistoricCleaningContext";
import RoomHistoricCleaningRecords from "../RoomHistoricCleaningComponents/RoomHistoricCleaningRecords";
import RoomHistoricCleaningFilter from "../RoomHistoricCleaningComponents/RoomHistoricCleaningFilter";

const RoomContainer = () => {

    const {
        showView, setShowView,
        model, setModel
    } = useContext(RoomContext);

    return (
        <>
            {showView === 'rooms' && (
                <>
                    <h1 className="TituloContenido">Habitaciones</h1>
                    <RoomChangeData />
                    <RoomCreate />
                    <Card title='LISTA DE HABITACIONES'>
                        <RoomFilter />
                        <RoomRecords />
                    </Card>
                    <RoomModal />
                    <RoomActiveModal />
                </>
            )}

            {showView === 'historic' && (
                <>
                    <Breadcrumb
                        style={{ marginBottom: '20px' }}
                        items={[
                            {
                                title: <div style={{ cursor: 'pointer' }}>Habitaciones</div>,
                                onClick: () => {
                                    setShowView('rooms');
                                    setModel(null);
                                }
                            },
                            {
                                title: 'Histórico',
                            },
                        ]}
                    />
                    <RoomHistoricCleaningProvider room={model}>
                        <RoomHistoricCleaningDetail />
                        <Card title='HISTORIAL DE LIMPIEZA'>
                            <RoomHistoricCleaningFilter />
                            <RoomHistoricCleaningRecords />
                        </Card>
                    </RoomHistoricCleaningProvider>
                </>
            )}
        </>
    );

};

export default RoomContainer;