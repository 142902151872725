import { createContext, useEffect, useState } from "react";
import GeneralHelper from "../helpers/GeneralHelper";
import BookingService from "../services/BookingService";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import RoutePathHelper from "../helpers/RoutePathHelper";

export const BookingContext = createContext();

export const BookingProvider = ({ children }) => {

    const [showView, setShowView] = useState('bookings');

    const [data, setData] = useState([]);

    const [model, setModel] = useState(null);

    const [openDelete, setOpenDelete] = useState(false);

    const [switchDataType, setSwitchDataType] = useState(true);

    const [tableParams, setTableParams] = useState(GeneralHelper.getTablePaginationDefault);

    const navigate = useNavigate();

    const [formFilters, setFormFilters] = useState({
        full_name: '',
        company: '',
        document_type: '',
        document_number: '',
        start_date: '',
        end_date: '',
        room_code: '',
        booking_status: 'PENDIENTE',
    });

    const getBookingsDefault = () => {
        BookingService.pagination(formFilters, tableParams.pagination.current, tableParams.pagination.pageSize, switchDataType).then(response => {
            setData(response.data);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    total: response.count
                }
            });
        }).catch(err => {
            message.error('Ocurrió un error en el servicio.');
        });
    };
    const getBookingsByFilter = (values) => {
        BookingService.pagination(values, 1, tableParams.pagination.pageSize, switchDataType).then(response => {
            setData(response.data);
            setFormFilters(values);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    total: response.count,
                    current: 1,
                }
            });
        }).catch(err => {
            message.error('Ocurrion un error con el servicio.');
        });
    };
    const getBookingsByPagination = (page, pageSize) => {
        BookingService.pagination(formFilters, page, pageSize, switchDataType).then(response => {
            setData(response.data);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    pageSize: pageSize,
                    current: page,
                }
            });
        }).catch(err => {
            message.error('Ocurrió un error en el servicio.');
        });
    };

    const updateBookingActive = (value) => {
        BookingService.updateActive(model.id, value).then(response => {
            getBookingsDefault();
            setOpenDelete(false);
            setModel(null);
            message.success(`Se ${value ? 'recuperó' : 'eliminó'} la reserva.`);
        }).catch(err => {
            message.error('Ocurrió un error en el servicio.');
        });
    };

    const getMenu = (value) => {

        let items = [];

        if (switchDataType) {

            const optionEdit = {
                key: 'edit', label: 'Editar', onClick: () => {
                    setModel(value);
                    setShowView('create');
                }
            };

            const optionDelete = {
                key: 'delete', label: 'Eliminar', onClick: () => {
                    setModel(value);
                    setOpenDelete(true);
                }
            };

            const optionConsumptions = {
                key: 'consumption', label: 'Ver Consumos', onClick: () => {
                    navigate(RoutePathHelper.getConsumptionByCustomerAccountId(value.customer_account_id));
                }
            };

            if (value.booking_status !== 'COMPLETADO') {
                items.push(optionEdit);
            }

            items.push(optionConsumptions);
            items.push(optionDelete);

        } else {

            const optionEnable = {
                key: 'enable', label: 'Recuperar', onClick: () => {
                    setModel(value);
                    setOpenDelete(true);
                }
            };

            items.push(optionEnable);

        }

        const menu = { items };

        return menu;

    };

    useEffect(() => {
        getBookingsDefault();
    }, [switchDataType]);
    return (
        <BookingContext.Provider value={{
            openDelete, setOpenDelete,
            data, setData,
            tableParams, setTableParams,
            formFilters, setFormFilters,
            model, setModel,
            switchDataType, setSwitchDataType,
            showView, setShowView,
            getBookingsDefault, getBookingsByFilter, getBookingsByPagination,
            updateBookingActive, getMenu
        }}>
            {children}
        </BookingContext.Provider>
    );
};