import { createContext, useEffect, useState } from "react";
import GeneralHelper from "../helpers/GeneralHelper";
import { Form, message } from "antd";
import ProductStocksService from "../services/ProductStocksService";

export const ProductStockContext = createContext();

export const ProductStockProvider = ({ product, children }) => {
    const [form] = Form.useForm();
    const [currentRecord, setCurrentRecord] = useState(null);
    const [model, setModel] = useState(null);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [tableParams, setTableParams] = useState(GeneralHelper.getTablePaginationDefault());
    const [formFilters, setFormFilters] = useState({ start_datetime: '', end_datetime: '', stock: '' });
    const [createType, setCreateType] = useState('');
    const getProductStocksDefault = () => {
        ProductStocksService.pagination(product.id, {
            start_datetime: formFilters.start_datetime,
            end_datetime: formFilters.end_datetime,
            stock: formFilters.stock
        }, tableParams.pagination.current, tableParams.pagination.pageSize).then(response => {
            const current = response.data.find(x => x.is_current === 1);
            if (current) setCurrentRecord(current);
            setData(response.data);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    total: response.count
                }
            });
        }).catch(err => {
            message.error('Ocurrion un error con el servicio.');
        });
    }
    const getProductStocksByFilter = (values) => {
        ProductStocksService.pagination(product.id, values, 1, tableParams.pagination.pageSize).then(response => {
            const current = response.data.find(x => x.is_current === 1);
            if (current) setCurrentRecord(current);
            setData(response.data);
            setFormFilters(values);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    total: response.count,
                    current: 1,
                }
            });
        }).catch(err => {
            message.error('Ocurrion un error con el servicio.');
        });
    };
    const getProductStocksByPagination = (page, pageSize) => {
        ProductStocksService.pagination(product.id, {
            start_datetime: formFilters.start_datetime,
            end_datetime: formFilters.end_datetime,
            stock: formFilters.stock
        }, page, pageSize).then(response => {
            setData(response.data);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    pageSize: pageSize,
                    current: page,
                }
            });
        }).catch(err => {
            message.error('Ocurrion un error con el servicio.');
        });
    };
    const createProductStock = (values) => {
        let stock_data = 0;
        switch (createType) {
            case 'add': {
                stock_data = Number(values.new_stock) + Number(values.current_stock);
                break;
            }
            case 'reduce': {
                stock_data = Number(values.current_stock) - Number(values.new_stock);
                if (stock_data < 0) {
                    message.warning('El stock no puede ser negativo.');
                    return;
                }
                break;
            }
            default: {
                message.warning('Tipo de creación incorrecto.');
                return;
            }
        }
        ProductStocksService.create(product.id, {
            note: values.note,
            stock: stock_data,
            change_type: createType,
            change_amount: values.new_stock
        }).then(response => {
            getProductStocksDefault();
            setOpen(false);
            setModel(null);
            form.resetFields();
            message.success('Se actualizó el stock.');
        }).catch((err) => {
            message.error('Ocurrion un error con el servicio.');
        });
    };
    const updateProductStock = (values) => {
        ProductStocksService.update(model.id, values).then(response => {
            getProductStocksDefault();
            setOpen(false);
            setModel(null);
            form.resetFields();
            message.success('Se actualizó el registro.');
        }).catch((err) => {
            message.error('Ocurrion un error con el servicio.');
        });
    };
    const getMenu = (value) => {
        const menu = {
            items: [
                {
                    key: 'edit', label: 'Editar', onClick: () => {
                        setModel(value);
                        setCreateType('');
                        form.setFieldsValue({ ...value, current_stock: value.stock });
                        setOpen(true);
                    }
                }
            ]
        };
        return menu;
    };
    useEffect(() => {
        if (product) getProductStocksDefault();
    }, [product]);
    return (
        <ProductStockContext.Provider value={{
            form, product, currentRecord,
            data, setData,
            tableParams, setTableParams,
            formFilters, setFormFilters,
            model, setModel,
            open, setOpen,
            createType, setCreateType,
            getProductStocksByPagination, getProductStocksByFilter,
            createProductStock, updateProductStock,
            getMenu
        }}>
            {children}
        </ProductStockContext.Provider>
    );
};