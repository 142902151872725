import { useContext } from "react";
import { BookingCalendarContext } from "../../contexts/BookingCalendarContext";
import { Button } from "antd";

const BookingCalendarCreate = () => {

    const { setShowView } = useContext(BookingCalendarContext);

    const onClick = () => {
        setShowView('booking_form');
    };

    return (
        <div style={{ textAlign: 'right', marginBottom: '10px' }}>
            <Button type="primary" onClick={onClick}>Nueva Reserva</Button>
        </div>
    );

};

export default BookingCalendarCreate;