import { useContext } from "react";
import { CleaningMonitoringFormContext } from "../../contexts/CleaningMonitoringFormContext";
import { Button, Card, Form, Input, Spin } from "antd";
import RulesHelper from "../../helpers/RulesHelper";

const CleaningMonitoringForm = () => {

    const { form, room_cleaning_record, updateStatus, loading } = useContext(CleaningMonitoringFormContext);

    const onFinish = () => {
        updateStatus();
    };

    return (
        <Card title={'DETALLE DE ACTIVIDAD'}>
            <Spin spinning={loading}>
                <Form
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    onFinish={onFinish}
                >
                    <Form.Item label="NUEVO ESTADO" name="status">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item label="NOTA ANTERIOR" name="old_note">
                        <Input.TextArea disabled />
                    </Form.Item>
                    <Form.Item label="NOTA" name="note" rules={RulesHelper.maxNotRequired(250)}>
                        <Input.TextArea rows={5} />
                    </Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <Button type="primary" size="large" htmlType="submit" >
                            {room_cleaning_record.status === 'SUCIO' && 'LIMPIAR'}
                            {room_cleaning_record.status === 'LIMPIO' && 'SOLICITAR LIMPIEZA'}
                            {room_cleaning_record.status === 'EN LIMPIEZA' && 'FINALIZAR LIMPIEZA'}
                        </Button>
                    </div>
                </Form>
            </Spin>
        </Card>
    );

};

export default CleaningMonitoringForm;