import { Button } from "antd"
import { ConsumptionContext } from "../../contexts/ConsumptionContext";
import { useContext } from "react";

const ConsumptionSale = () => {

    const { setModalSale } = useContext(ConsumptionContext);

    const onClick = () => {
        setModalSale(true);
    };

    return (
        <div style={{ textAlign: 'end' }}>
            <Button type="primary" size="large" onClick={onClick}>Generar Venta</Button>
        </div>
    );

};

export default ConsumptionSale;