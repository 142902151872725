import dayjs from "dayjs";

class ValidationHelper {
    email(value) {
        try {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(value);
        } catch (err) {
            return false;
        }
    }
    isBoolean(value) {
        if (value === 'true' || value === 'false' || value === '1' || value === '0' || value === true || value === false) {
            return true;
        }
        return false;
    }
    hasData(value) {
        try {
            return (value !== undefined && value !== '' && value !== null);
        } catch (err) {
            return false;
        }
    }
    maxLength(value, length) {
        try {
            return (value.length <= length);
        } catch (err) {
            return false;
        }
    }
    isPositiveInteger(value) {
        const regex = /^\d+$/;

        if (!regex.test(value)) {
            return false;
        }

        const num = parseInt(value, 10);

        return num > 0;
    }
    isValidDateTime(value) {
        const format = 'YYYY-MM-DD HH:mm:ss';
        const date = dayjs(value, format, true);
        return date.isValid();
    }
    isValidMoney(value) {
        const regex = /^\d+(\.\d{2})?$/;
        return regex.test(value);
    }
    fieldsValidation(fields) {
        const errors = {};
        let has_errors = false;
        for (let indexField = 0; indexField < fields.length; indexField++) {
            const { value, name, required, format, extra_rules } = fields[indexField];
            errors[name] = [];
            const has_data = this.hasData(value);
            let validateFormat = true;
            let validateExtraRules = true;
            if (!has_data && !required) {
                validateFormat = false;
            }
            if (!has_data && required) {
                errors[name].push({ type: 'required', message: 'Field required.' });
                validateFormat = false;
            }
            if (validateFormat) {
                const formatError = { type: format, message: 'Invalid format.' };
                if (format === 'email') {
                    const validation = this.email(value);
                    if (!validation) errors[name].push(formatError);
                    validateExtraRules = false;
                }
                if (Array.isArray(format)) {
                    const formatIndex = format.findIndex(x => x === value);
                    if (formatIndex === -1) errors[name].push(formatError);
                    validateExtraRules = false;
                }
                if (format === 'boolean') {
                    const validation = this.isBoolean(value);
                    if (!validation) errors[name].push(formatError);
                    validateExtraRules = false;
                }
                if (format === 'positive_real_number') {
                    const validation = this.isPositiveInteger(value);
                    if (!validation) errors[name].push(formatError);
                    validateExtraRules = false;
                }
                if (format === 'date_time') {
                    const validation = this.isValidDateTime(value);
                    if (!validation) errors[name].push(formatError);
                    validateExtraRules = false;
                }
                if (format === 'money') {
                    const validation = this.isValidMoney(value);
                    if (!validation) errors[name].push(formatError);
                    validateExtraRules = false;
                }
            }
            if (validateExtraRules && extra_rules) {
                for (let index = 0; index < extra_rules.length; index++) {
                    const { type, length } = extra_rules[index];
                    switch (type) {
                        case 'max': {
                            const validation = this.maxLength(value, length);
                            if (!validation) errors[name].push({ type: type, message: 'Invalid max length.' });
                            break;
                        }
                    }
                }
            }
            if (errors[name].length > 0) {
                has_errors = true;
            } else {
                delete errors[name];
            }
        }
        return { errors, has_errors };
    }
}

export default new ValidationHelper();