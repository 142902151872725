import { useContext } from "react";
import { ProductStockContext } from "../../contexts/ProductStockContext";
import { Button, DatePicker, Form, Input } from "antd";
import ParseHelper from "../../helpers/ParseHelper";
import DateUtil from "../../utils/DateUtil";

export const ProductStockFilter = () => {
    const { getProductStocksByFilter } = useContext(ProductStockContext);
    const onFinish = (values) => {
        const start_datetime = DateUtil.ToServerString(values.start_datetime);
        const end_datetime = DateUtil.ToServerEndString(values.end_datetime);
        const stock = ParseHelper.parseString(values.stock);
        getProductStocksByFilter({ start_datetime, end_datetime, stock });
    };
    return (
        <Form className="FilterContainer" layout="vertical" onFinish={onFinish} autoComplete="off">
            <Form.Item name={"start_datetime"} label={"Filtro por fecha de inicio"}>
                <DatePicker format="DD/MM/YYYY" style={{ width: '250px' }} />
            </Form.Item>
            <Form.Item name={"end_datetime"} label={"Filtro por fecha fin"}>
                <DatePicker format="DD/MM/YYYY" style={{ width: '250px' }} />
            </Form.Item>
            <Form.Item name={"stock"} label={"Filtro por stock"}>
                <Input style={{ width: '250px' }} />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">Buscar</Button>
            </Form.Item>
        </Form>
    );
};