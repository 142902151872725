import GeneralHelper from "../helpers/GeneralHelper";
import ApiService from "./ApiService";

class ProductStocksService {
    pagination(productId, params, page_number, page_size) {
        return ApiService.get('/productStockRecords', { ...params, product_id: productId, page_number, page_size }, true);
    }
    create(productId, body) {
        return ApiService.post('/productStockRecords', { ...body, product_id: productId, created_at: GeneralHelper.getLimaPeruTime() }, true);
    }
    update(id, body) {
        return ApiService.patch(`/productStockRecords/${id}`, body, true);
    }
}

export default new ProductStocksService();