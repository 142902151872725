import DahsboardAdminRecords from "../components/DashboardAdminComponents/DahsboardAdminRecords";
import { DashboardAdminProvider } from "../contexts/DashboardAdminContext";

const DashboardPage = () => {

    return (
        <>
            <h1 className="TituloContenido">Dashboard</h1>
            <DashboardAdminProvider>
                <DahsboardAdminRecords />
            </DashboardAdminProvider>
        </>
    );

};

export default DashboardPage;