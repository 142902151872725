import { useMediaQuery } from "react-responsive";
import { ConsumptionProductContext } from "../../contexts/ConsumptionProductContext";
import { useContext } from "react";
import { Button, Card, Dropdown, List, message, Pagination, Table } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import GeneralUtil from "../../utils/GeneralUtil";

const ConsumptionProductRecords = () => {

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const {
        data, dataSelected, tableParams, getProductsByPagination,
        setModalProductSelected, setProductSelected
    } = useContext(ConsumptionProductContext);

    const onChangeTable = (pagination, filters, sorter) => {
        getProductsByPagination(pagination.current, pagination.pageSize);
    };

    const onChangeList = (page, pageSize) => {
        getProductsByPagination(page, pageSize);
    };

    const onShowSizeChange = (current, size) => {
        getProductsByPagination(current, size);
    };

    const handleAddProduct = (value) => {

        const productFinded = dataSelected.find(item => item.id === value.id);

        if (productFinded) return message.warning('El producto ya se agregó.');

        setModalProductSelected(true);

        setProductSelected({ ...value, type: 'create' });

    };

    return (isMobile ?
        (
            <>
                <List
                    itemLayout="vertical"
                    dataSource={data}
                    renderItem={(model, index) => (
                        <List.Item key={index}>
                            <Card bordered={false}>
                                <div className="RowContainer">
                                    <div className="ColumnContainer">
                                        <div><strong>Código:</strong> {model.product_code}</div>
                                        <div><strong>Nombre:</strong> {model.product_name}</div>
                                        <div><strong>Precio:</strong> {GeneralUtil.getMoney(model.product_price)}</div>
                                        <div><strong>Stock:</strong> {model.stock}</div>
                                    </div>
                                    <div className="ColumnContainer ColumnContainerVentas" style={{ width: '100%' }}>
                                        <Button onClick={() => { handleAddProduct(model); }}>Agregar</Button>
                                    </div>
                                </div>
                            </Card>
                        </List.Item>
                    )}
                />
                <Pagination
                    pageSize={tableParams.pagination.pageSize}
                    showSizeChanger={tableParams.pagination.showSizeChanger}
                    onShowSizeChange={onShowSizeChange}
                    total={tableParams.pagination.total}
                    pageSizeOptions={tableParams.pagination.pageSizeOptions}
                    current={tableParams.pagination.current}
                    onChange={onChangeList}
                />
            </>
        ) :
        (
            <Table
                pagination={tableParams.pagination}
                bordered
                dataSource={data}
                rowKey={(record) => record.id}
                onChange={onChangeTable}
                columns={[
                    { title: 'Nombre', dataIndex: 'product_name', key: 'product_name', fixed: true, width: 300 },
                    { title: 'Código', dataIndex: 'product_code', key: 'product_code' },
                    { title: 'Precio', dataIndex: 'product_price', key: 'price', render: (value) => GeneralUtil.getMoney(value) },
                    { title: 'Stock', dataIndex: 'stock', key: 'stock' },
                    {
                        title: 'Opciones', key: 'Acciones', width: 150, render: (model) => (
                            <Button onClick={() => { handleAddProduct(model); }}>Agregar</Button>
                        )
                    },
                ]}
            />
        )
    );

};

export default ConsumptionProductRecords;