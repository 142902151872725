import { IdcardOutlined, HomeOutlined, DashboardOutlined, LineChartOutlined, SettingOutlined, TeamOutlined, AppstoreAddOutlined, ShoppingCartOutlined, CalendarOutlined, DollarOutlined } from "@ant-design/icons";
import RoutePathHelper from "./RoutePathHelper";

class MenuHelper {

    getMenuKeyByRoute(route) {
        switch (route) {
            case RoutePathHelper.getDashboard(): {
                return 'menu_dashboard_key';
            }
            case RoutePathHelper.getMonitoring(): {
                return 'menu_monitoring_key';
            }
            case RoutePathHelper.getCleaningMonitoring(): {
                return 'menu_cleaning_monitoring_key';
            }
            case RoutePathHelper.getLaundryMonitoring(): {
                return 'menu_laundry_monitoring_key';
            }
            case RoutePathHelper.getRoom(): {
                return 'menu_rooms_key';
            }
            case RoutePathHelper.getUser(): {
                return 'menu_users_key';
            }
            case RoutePathHelper.getProduct(): {
                return 'menu_products_key';
            }
            case RoutePathHelper.getSetting(): {
                return 'menu_settings_key';
            }
            case RoutePathHelper.getBooking(): {
                return 'menu_booking_form_key';
            }
            case RoutePathHelper.getCustomerAccount(): {
                return 'menu_customer_account_key';
            }
            case RoutePathHelper.getBookingCalendar(): {
                return 'menu_booking_calendar_key';
            }
            case RoutePathHelper.getGuest(): {
                return 'menu_guest_key';
            }
            case RoutePathHelper.getSale(): {
                return 'menu_sale_key';
            }
            default: {
                return '';
            }
        }
    }

    getCleaningStaff(navigate) {
        return [
            {
                key: this.getMenuKeyByRoute(RoutePathHelper.getCleaningMonitoring()),
                label: 'Monitoreo Limpieza',
                icon: (<DashboardOutlined />),
                onClick: () => { navigate(RoutePathHelper.getCleaningMonitoring()); }
            }
        ];
    }

    getLaundryReceptionist(navigate) {
        return [
            {
                key: this.getMenuKeyByRoute(RoutePathHelper.getLaundryMonitoring()),
                label: 'Monitoreo Lavanderia',
                icon: (<DashboardOutlined />),
                onClick: () => { navigate(RoutePathHelper.getLaundryMonitoring()); }
            }
        ];
    }

    getHotelReceptionist(navigate) {
        return [
            {
                key: this.getMenuKeyByRoute(RoutePathHelper.getMonitoring()),
                label: 'Monitoreo',
                icon: (<DashboardOutlined />),
                onClick: () => { navigate(RoutePathHelper.getMonitoring()); }
            },
            {
                key: this.getMenuKeyByRoute(RoutePathHelper.getBookingCalendar()),
                label: 'Reservas Calendario',
                icon: (<CalendarOutlined />),
                onClick: () => { navigate(RoutePathHelper.getBookingCalendar()); }
            },
            {
                key: this.getMenuKeyByRoute(RoutePathHelper.getBooking()),
                label: 'Reservas',
                icon: (<CalendarOutlined />),
                onClick: () => { navigate(RoutePathHelper.getBooking()); }
            },
            {
                key: this.getMenuKeyByRoute(RoutePathHelper.getGuest()),
                label: 'Huespedes',
                icon: (<HomeOutlined />),
                onClick: () => { navigate(RoutePathHelper.getGuest()); }
            },
            {
                key: this.getMenuKeyByRoute(RoutePathHelper.getCustomerAccount()),
                label: 'Cuentas Clientes',
                icon: (<IdcardOutlined />),
                onClick: () => { navigate(RoutePathHelper.getCustomerAccount()); }
            },
            {
                key: this.getMenuKeyByRoute(RoutePathHelper.getSale()),
                label: 'Ventas',
                icon: (<DollarOutlined />),
                onClick: () => { navigate(RoutePathHelper.getSale()); }
            }
        ];
    }

    getAdmin(navigate) {
        return [
            {
                key: this.getMenuKeyByRoute(RoutePathHelper.getDashboard()),
                label: 'Dashboard',
                icon: (<LineChartOutlined />),
                onClick: () => { navigate(RoutePathHelper.getDashboard()); },
            },
            {
                key: this.getMenuKeyByRoute(RoutePathHelper.getMonitoring()),
                label: 'Monitoreo',
                icon: (<DashboardOutlined />),
                onClick: () => { navigate(RoutePathHelper.getMonitoring()); }
            },
            {
                key: this.getMenuKeyByRoute(RoutePathHelper.getCleaningMonitoring()),
                label: 'Monitoreo Limpieza',
                icon: (<DashboardOutlined />),
                onClick: () => { navigate(RoutePathHelper.getCleaningMonitoring()); }
            },
            {
                key: this.getMenuKeyByRoute(RoutePathHelper.getLaundryMonitoring()),
                label: 'Monitoreo Lavanderia',
                icon: (<DashboardOutlined />),
                onClick: () => { navigate(RoutePathHelper.getLaundryMonitoring()); }
            },
            {
                key: this.getMenuKeyByRoute(RoutePathHelper.getRoom()),
                label: 'Habitaciones',
                icon: (<AppstoreAddOutlined />),
                onClick: () => { navigate(RoutePathHelper.getRoom()); }
            },
            {
                key: this.getMenuKeyByRoute(RoutePathHelper.getUser()),
                label: 'Usuarios',
                icon: (<TeamOutlined />),
                onClick: () => { navigate(RoutePathHelper.getUser()); }
            },
            {
                key: this.getMenuKeyByRoute(RoutePathHelper.getProduct()),
                label: 'Productos',
                icon: (<ShoppingCartOutlined />),
                onClick: () => { navigate(RoutePathHelper.getProduct()); }
            },
            {
                key: this.getMenuKeyByRoute(RoutePathHelper.getBooking()),
                label: 'Reservas',
                icon: (<CalendarOutlined />),
                onClick: () => { navigate(RoutePathHelper.getBooking()); }
            },
            {
                key: this.getMenuKeyByRoute(RoutePathHelper.getGuest()),
                label: 'Huespedes',
                icon: (<HomeOutlined />),
                onClick: () => { navigate(RoutePathHelper.getGuest()); }
            },
            {
                key: this.getMenuKeyByRoute(RoutePathHelper.getBookingCalendar()),
                label: 'Reservas Calendario',
                icon: (<CalendarOutlined />),
                onClick: () => { navigate(RoutePathHelper.getBookingCalendar()); }
            },
            {
                key: this.getMenuKeyByRoute(RoutePathHelper.getCustomerAccount()),
                label: 'Cuentas Clientes',
                icon: (<IdcardOutlined />),
                onClick: () => { navigate(RoutePathHelper.getCustomerAccount()); }
            },
            {
                key: this.getMenuKeyByRoute(RoutePathHelper.getSale()),
                label: 'Ventas',
                icon: (<DollarOutlined />),
                onClick: () => { navigate(RoutePathHelper.getSale()); }
            },
            {
                key: this.getMenuKeyByRoute(RoutePathHelper.getSetting()),
                label: 'Configuraciones',
                icon: (<SettingOutlined />),
                onClick: () => { navigate(RoutePathHelper.getSetting()); }
            }
        ];
    };
}

export default new MenuHelper();