import { useContext } from "react";
import { ConsumptionProductContext } from "../../contexts/ConsumptionProductContext";
import ParseHelper from "../../helpers/ParseHelper";
import { Button, Form, Input } from "antd";

const ConsumptionProductFilter = () => {

    const { getProductsByFilter } = useContext(ConsumptionProductContext);

    const onFinish = (values) => {
        const product_code = ParseHelper.parseString(values.product_code);
        const product_name = ParseHelper.parseString(values.product_name);
        getProductsByFilter({ product_code, product_name });
    };

    return (
        <Form
            className="FilterContainer"
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item name={"product_code"} label={"Filtro por código de producto"}>
                <Input />
            </Form.Item>
            <Form.Item name={"product_name"} label={"Filtro por nombre de producto"}>
                <Input />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">Buscar</Button>
            </Form.Item>
        </Form>
    );

};

export default ConsumptionProductFilter;