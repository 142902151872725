import { useContext } from "react";
import { BookingContext } from "../../contexts/BookingContext";
import { Button } from "antd";

const BookingCreate = () => {
    const { setModel, setShowView } = useContext(BookingContext);
    const onClick = () => {
        setModel(null);
        setShowView('create');
    };
    return (
        <div style={{ textAlign: 'right', marginBottom: '10px' }}>
            <Button type="primary" onClick={onClick}>Nuevo registro</Button>
        </div>
    );
};

export default BookingCreate;