import { Button, Card, Form, Input } from "antd";
import { useContext } from "react";
import { LaundryRecordFormContext } from "../../contexts/LaundryRecordFormContext";
import RulesHelper from "../../helpers/RulesHelper";

const LaundryRecordForm = () => {

    const { form, updateStatus, laundry_record } = useContext(LaundryRecordFormContext);

    const onFinish = (values) => {
        updateStatus(values);
    };

    return (
        <Card title={'DETALLE DE ACTIVIDAD'}>
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                onFinish={onFinish}
            >
                <Form.Item label="NUEVO ESTADO" name="laundry_status">
                    <Input disabled />
                </Form.Item>
                <Form.Item label="NOTA ANTERIOR" name="old_note">
                    <Input.TextArea disabled />
                </Form.Item>
                <Form.Item label="NOTA" name="laundry_note" rules={RulesHelper.maxNotRequired(250)}>
                    <Input.TextArea rows={5} />
                </Form.Item>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button type="primary" size="large" htmlType="submit" >
                        {laundry_record.laundry_status === 'PENDIENTE' && 'INICIAR LAVADO'}
                        {laundry_record.laundry_status === 'EN LAVADO' && 'FINALIZAR LAVADO'}
                    </Button>
                </div>
            </Form>
        </Card>
    );

};

export default LaundryRecordForm;