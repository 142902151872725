import { useContext } from "react";
import ConsumptionCustomerAccount from "./ConsumptionCustomerAccount";
import ConsumptionDetailModel from "./ConsumptionDetailModel";
import ConsumptionButtonsCreate from "./ConsumptionButtonsCreate";
import ConsumptionRecords from "./ConsumptionRecords";
import { ConsumptionContext } from "../../contexts/ConsumptionContext";
import { Breadcrumb, Card } from "antd";
import { ConsumptionProductProvider } from "../../contexts/ConsumptionProductContext";
import ConsumptionProductRecords from "../ConsumptionProductComponents/ConsumptionProductRecords";
import ConsumptionProductFilter from "../ConsumptionProductComponents/ConsumptionProductFilter";
import ConsumptionProductSelected from "../ConsumptionProductComponents/ConsumptionProductSelected";
import ConsumptionProductModal from "../ConsumptionProductComponents/ConsumptionProductModal";
import ConsumptionProductDelete from "../ConsumptionProductComponents/ConsumptionProductDelete";
import ConsumptionProductDescription from "../ConsumptionProductComponents/ConsumptionProductDescription";
import ConsumptionProductAdd from "../ConsumptionProductComponents/ConsumptionProductAdd";
import ConsumptionSaleModal from "./ConsumptionSaleModal";
import ConsumptionSale from "./ConsumptionSale";
import ConsumptionDisableModal from "./ConsumptionDisableModal";
import { PartialPaymentProvider } from "../../contexts/PartialPaymentContext";
import PartialPaymentConsumptionDetail from "../PartialPaymentComponents/PartialPaymentConsumptionDetail";
import PartialPaymentDetailRecords from "../PartialPaymentComponents/PartialPaymentDetailRecords";
import PartialPaymentCreateModal from "../PartialPaymentComponents/PartialPaymentCreateModal";
import PartialPaymentCreate from "../PartialPaymentComponents/PartialPaymentCreate";
import PartialPaymentDeleteModal from "../PartialPaymentComponents/PartialPaymentDeleteModal";
import { LaundryConsumptionProvider } from "../../contexts/LaundryConsumptionContext";
import LaundryConsumptionForm from "../LaundryConsumptionComponents/LaundryConsumptionForm";
import ConsumptionDisableLaundryModal from "./ConsumptionDisableLaundryModal";
import { LaundryConsumptionDetailProvider } from "../../contexts/LaundryConsumptionDetailContext";
import LaundryConsumptionDetail from "../LaundryConsumptionDetailComponents/LaundryConsumptionDetail";
import LaundryConsumptionDetailRecords from "../LaundryConsumptionDetailComponents/LaundryConsumptionDetailRecords";

const ConsumptionContainer = () => {

    const {
        showView, setShowView,
        customerAccountModel, getDataDefault,
        consumptionModel, setConsumptionModel
    } = useContext(ConsumptionContext);

    return (
        <>
            {showView === 'consumptions' && (
                <>
                    <ConsumptionButtonsCreate />
                    <ConsumptionCustomerAccount />
                    <ConsumptionRecords />
                    <ConsumptionSale />
                    <ConsumptionDetailModel />
                    <ConsumptionSaleModal />
                    <ConsumptionDisableModal />
                    <ConsumptionDisableLaundryModal />
                </>
            )}

            {showView === 'product' && (
                <>
                    <Breadcrumb
                        style={{ marginBottom: '20px' }}
                        items={[
                            {
                                title: <div style={{ cursor: 'pointer' }}>Consumos</div>,
                                onClick: () => {
                                    setShowView('consumptions');
                                }
                            },
                            {
                                title: <div style={{ cursor: 'default' }}>Nuevo consumo de producto</div>,
                            },
                        ]}
                    />

                    <ConsumptionProductProvider customerAccountModel={customerAccountModel} onBack={() => {
                        setShowView('consumptions');
                        getDataDefault();
                    }}>
                        <Card title='Lista de productos' style={{ marginBottom: '20px' }}>
                            <ConsumptionProductFilter />
                            <ConsumptionProductRecords />
                        </Card>
                        <Card title='Lista de productos seleccionados' style={{ marginBottom: '20px' }}>
                            <ConsumptionProductSelected />
                        </Card>
                        <Card title='Detalle de consumo' style={{ marginBottom: '20px' }}>
                            <ConsumptionProductDescription />
                        </Card>
                        <ConsumptionProductAdd />
                        <ConsumptionProductModal />
                        <ConsumptionProductDelete />
                    </ConsumptionProductProvider>

                </>
            )}

            {showView === 'partial_payments' && consumptionModel && (

                <>
                    <Breadcrumb
                        style={{ marginBottom: '20px' }}
                        items={[
                            {
                                title: <div style={{ cursor: 'pointer' }}>Consumos</div>,
                                onClick: () => {
                                    setShowView('consumptions');
                                    setConsumptionModel(null);
                                }
                            },
                            {
                                title: <div style={{ cursor: 'default' }}>Pagos Parciales</div>,
                            },
                        ]}
                    />

                    <PartialPaymentProvider consumption_id={consumptionModel.consumption_id}>
                        <PartialPaymentConsumptionDetail />
                        <PartialPaymentCreate />
                        <PartialPaymentDetailRecords />
                        <PartialPaymentCreateModal />
                        <PartialPaymentDeleteModal />
                    </PartialPaymentProvider>
                </>
            )}

            {showView === 'laundry' && (
                <>
                    <Breadcrumb
                        style={{ marginBottom: '20px' }}
                        items={[
                            {
                                title: <div style={{ cursor: 'pointer' }}>Consumos</div>,
                                onClick: () => {
                                    setShowView('consumptions');
                                }
                            },
                            {
                                title: <div style={{ cursor: 'default' }}>Nuevo consumo de lavandería</div>,
                            },
                        ]}
                    />

                    <LaundryConsumptionProvider
                        customer_account_id={customerAccountModel.id}
                        onBack={() => {
                            setShowView('consumptions');
                            getDataDefault();
                        }}
                    >
                        <LaundryConsumptionForm />
                    </LaundryConsumptionProvider>
                </>
            )}

            {showView === 'laundry_detail' && (
                <>
                    <Breadcrumb
                        style={{ marginBottom: '20px' }}
                        items={[
                            {
                                title: <div style={{ cursor: 'pointer' }}>Consumos</div>,
                                onClick: () => {
                                    setShowView('consumptions');
                                    setConsumptionModel(null);
                                }
                            },
                            {
                                title: <div style={{ cursor: 'default' }}>Detalle de lavandería</div>,
                            },
                        ]}
                    />

                    <LaundryConsumptionDetailProvider consumption_id={consumptionModel.consumption_id}>
                        <LaundryConsumptionDetail />
                        <LaundryConsumptionDetailRecords />
                    </LaundryConsumptionDetailProvider>
                </>
            )}
        </>
    );

};

export default ConsumptionContainer;