import GeneralHelper from "../helpers/GeneralHelper";
import ApiService from "./ApiService";

class LaundryRecordService {

    static async updateStatus(id, body) {

        return ApiService.post(`/laundry-records/${id}/update/status`, {
            ...body,
            created_at: GeneralHelper.getLimaPeruTime()
        }, true);

    }

}

export default LaundryRecordService;