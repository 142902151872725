import { Card } from "antd";
import CustomerAccountFilter from "../components/CustomerAccountComponents/CustomerAccountFilter";
import CustomerAccountRecords from "../components/CustomerAccountComponents/CustomerAccountRecords";
import { CustomerAccountProvider } from "../contexts/CustomerAccountContext";

const CustomerAccountPage = () => {
    return (
        <>
            <h1 className="TituloContenido">Cuentas de clientes</h1>
            <CustomerAccountProvider>
                <Card title={'LISTA DE CLIENTES'}>
                    <CustomerAccountFilter />
                    <CustomerAccountRecords />
                </Card>
            </CustomerAccountProvider>
        </>
    );
}

export default CustomerAccountPage;