import { Dropdown, Layout, Space } from "antd";
import { DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { LayoutContext } from "../../contexts/LayoutContext";
import { useContext } from "react";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";

const LayoutHeader = () => {
    const { logoutService, logoutEvent } = useContext(LayoutContext);
    const user = JSON.parse(LocalStorageHelper.getUserData());
    return (
        <Layout.Header className={'HeaderLayout'}>
            <div style={{ marginRight: '10px' }}>
                <Dropdown
                    menu={{
                        items: [
                            {
                                key: '0', label: 'Salir', icon: <LogoutOutlined />, onClick: () => {
                                    logoutService();
                                    logoutEvent();
                                }
                            }
                        ]
                    }}
                    trigger={['click']}
                >
                    <a onClick={(e) => e.preventDefault()} style={{ color: '#000' }}>
                        <Space>
                            <UserOutlined />
                            {user.email}
                            <DownOutlined />
                        </Space>
                    </a>
                </Dropdown>
            </div>
        </Layout.Header>
    );
};

export default LayoutHeader;