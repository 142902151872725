import { useContext, useEffect, useState } from "react";
import { ConsumptionContext } from "../../contexts/ConsumptionContext";
import { Card, Descriptions } from "antd";
import GeneralUtil from "../../utils/GeneralUtil";

const ConsumptionCustomerAccount = () => {
    const { customerAccountModel } = useContext(ConsumptionContext);
    const [items, setItems] = useState([]);
    useEffect(() => {
        if (customerAccountModel) {
            setItems([
                { key: 1, label: 'Codigo', children: `${customerAccountModel.code}` },
                { key: 2, label: 'Cliente', children: `${customerAccountModel.name} ${customerAccountModel.lastname}` },
                { key: 3, label: 'Documento', children: `${customerAccountModel.document_type} ${customerAccountModel.document_number}` },
                { key: 4, label: 'Empresa', children: `${customerAccountModel.company ? customerAccountModel.company : ''}` },
                { key: 5, label: 'Monto', children: `${GeneralUtil.getMoney(customerAccountModel.total)}` },
                { key: 6, label: 'Deuda', children: `${customerAccountModel.paid ? 'PAGADO' : 'PENDIENTE'}` },
            ])
        }
    }, [customerAccountModel]);
    return (
        <div style={{ marginBottom: '20px' }}>
            <Card bordered={false} title={'DETALLE DE CUENTA'}>
                <Descriptions items={items} bordered />
            </Card>
        </div>
    );
};

export default ConsumptionCustomerAccount;