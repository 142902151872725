import { createContext, useEffect, useState } from "react";
import GeneralHelper from "../helpers/GeneralHelper";
import CustomerAccountService from "../services/CustomerAccountService";
import { message } from "antd";

export const CustomerAccountContext = createContext();

export const CustomerAccountProvider = ({ children }) => {

    const [data, setData] = useState([]);

    const [switchDataType, setSwitchDataType] = useState(true);

    const [tableParams, setTableParams] = useState(GeneralHelper.getTablePaginationDefault);

    const [formFilters, setFormFilters] = useState({
        full_name: '',
        company: '',
        document_type: '',
        document_number: '',
        code: '',
        paid: false
    });

    const getDataDefault = () => {
        CustomerAccountService.pagination(
            formFilters,
            tableParams.pagination.current,
            tableParams.pagination.pageSize,
            switchDataType
        ).then(response => {
            setData(response.data);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    total: response.count
                }
            });
        }).catch(error => {
            console.log(error);
            message.error('Ocurrió un error en el servicio.');
        });
    };

    const getDataByFilter = (values) => {
        CustomerAccountService.pagination(
            values,
            1,
            tableParams.pagination.pageSize,
            switchDataType
        ).then(response => {
            setData(response.data);
            setFormFilters(values);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    total: response.count,
                    current: 1,
                }
            });
        }).catch(error => {
            console.log(error);
            message.error('Ocurrion un error con el servicio.');
        });
    };

    const getDataByPagination = (page, pageSize) => {
        CustomerAccountService.pagination(
            formFilters,
            page,
            pageSize,
            switchDataType
        ).then(response => {
            setData(response.data);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    pageSize: pageSize,
                    current: page,
                }
            });
        }).catch(error => {
            console.log(error);
            message.error('Ocurrió un error en el servicio.');
        });
    };

    useEffect(() => {
        getDataDefault();
    }, [switchDataType]);

    return (
        <CustomerAccountContext.Provider value={{
            data, tableParams,
            switchDataType, setSwitchDataType,
            getDataDefault, getDataByFilter, getDataByPagination
        }}>
            {children}
        </CustomerAccountContext.Provider>
    );

};
