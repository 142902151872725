import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import RulesHelper from "../../helpers/RulesHelper";
import { Form, Input, Modal } from "antd";

const UserPasswordModal = () => {
    const { formUpdatePassword, openUpdatePassword, setOpenUpdatePassword, setModel, updateUserPassword } = useContext(UserContext);
    const onCancel = () => {
        setOpenUpdatePassword(false);
        setModel(null);
        formUpdatePassword.resetFields();
    };
    const onFinish = (values) => {
        updateUserPassword(values.password);
    };
    return (
        <Modal title={'Actualizar Contraseña'} open={openUpdatePassword} onCancel={onCancel} onOk={() => formUpdatePassword.submit()}>
            <Form layout="vertical" form={formUpdatePassword} onFinish={onFinish} autoComplete="off">
                <Form.Item label={'Contraseña'} name={'password'} rules={RulesHelper.password()}>
                    <Input.Password />
                </Form.Item>
                <Form.Item label={'Confirmar Contraseña'} name={'confirm_password'} rules={RulesHelper.confirmPassword()} dependencies={['password']}>
                    <Input.Password />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default UserPasswordModal;