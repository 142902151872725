import { Form, message } from "antd";
import { createContext, useEffect, useState } from "react";
import LocalStorageHelper from "../helpers/LocalStorageHelper";
import UserService from "../services/UserService";
import ServiceError from "../errors/ServiceError";
import GeneralHelper from "../helpers/GeneralHelper";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const user = JSON.parse(LocalStorageHelper.getUserData());
    const [form] = Form.useForm();
    const [formUpdatePassword] = Form.useForm();
    const [switchDataType, setSwitchDataType] = useState(true);
    const [data, setData] = useState([]);
    const [model, setModel] = useState(null);
    const [open, setOpen] = useState(false);
    const [openUpdatePassword, setOpenUpdatePassword] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [tableParams, setTableParams] = useState(GeneralHelper.getTablePaginationDefault());
    const [formFilters, setFormFilters] = useState({ full_name: '', email: '', role: '' });
    const getUsersDefault = () => {
        UserService.pagination(
            { email: formFilters.email, full_name: formFilters.full_name, role: formFilters.role },
            tableParams.pagination.current,
            tableParams.pagination.pageSize,
            switchDataType
        ).then(response => {
            setData(response.data);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    total: response.count
                }
            });
        }).catch(err => {
            message.error('Ocurrion un error con el servicio.');
        });
    };
    const getUsersByFilter = (values) => {
        UserService.pagination(values, 1, tableParams.pagination.pageSize, switchDataType).then(response => {
            setData(response.data);
            setFormFilters(values);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    total: response.count,
                    current: 1,
                }
            });
        }).catch(err => {
            message.error('Ocurrion un error con el servicio.');
        });
    };
    const getUsersByPagination = (page, pageSize) => {
        UserService.pagination(
            { email: formFilters.email, full_name: formFilters.full_name, role: formFilters.role },
            page,
            pageSize,
            switchDataType
        ).then(response => {
            setData(response.data);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    pageSize: pageSize,
                    current: page,
                }
            });
        }).catch(err => {
            message.error('Ocurrion un error con el servicio.');
        });
    };
    const createUser = (values) => {
        UserService.create(values).then(response => {
            getUsersDefault();
            setOpen(false);
            form.resetFields();
            message.success('Se registro el usuario.');
        }).catch((err) => {
            if (err instanceof ServiceError) {
                if (err.statusCode === 422) {
                    message.warning('Existe un usuario con el mismo correo electrónico.');
                } else {
                    message.error('Ocurrion un error con el servicio.');
                }
            }
        });
    };
    const updateUser = (values) => {
        UserService.update(model.id, values).then(response => {
            getUsersDefault();
            setOpen(false);
            setModel(null);
            form.resetFields();
            message.success('Se actualizó el usuario.');
        }).catch((err) => {
            message.error('Ocurrion un error con el servicio.');
        });
    };
    const updateUserPassword = (value) => {
        UserService.updatePassword(model.id, value).then(response => {
            getUsersDefault();
            setOpenUpdatePassword(false);
            setModel(null);
            formUpdatePassword.resetFields();
            message.success('Se actualizó la contraseña.');
        }).catch(err => {
            message.error('Ocurrion un error con el servicio.');
        });
    };
    const updateUserActive = (value) => {
        UserService.updateActive(model.id, value).then(response => {
            getUsersDefault();
            setOpenDelete(false);
            setModel(null);
            message.success(`Se ${value ? 'recuperó' : 'eliminó'} el registro.`);
        }).catch(err => {
            message.error('Ocurrion un error con el servicio.');
        });
    };
    const getMenu = (value) => {
        let items = {};
        if (switchDataType) {
            items = [
                {
                    key: 'edit', label: 'Editar', onClick: () => {
                        setModel(value);
                        form.setFieldsValue({ ...value });
                        setOpen(true);
                    }
                },
                {
                    key: 'change_password', label: 'Cambiar Contraseña', onClick: () => {
                        setModel(value);
                        setOpenUpdatePassword(true);
                    }
                },
                {
                    key: 'delete', label: 'Eliminar', onClick: () => {
                        setModel(value);
                        setOpenDelete(true);
                    }
                }
            ];
            if (value.email === user.email || value.email === 'admin@hotel.com') {
                delete items[2];
            }
            if (value.email === 'admin@hotel.com') {
                delete items[1];
            }
        } else {
            items = [
                {
                    key: 'enable', label: 'Recuperar', onClick: () => {
                        setModel(value);
                        setOpenDelete(true);
                    }
                }
            ];
        }
        const menu = { items };
        return menu;
    };
    useEffect(() => {
        getUsersDefault();
    }, [switchDataType]);
    return (
        <UserContext.Provider value={{
            form, formUpdatePassword,
            openDelete, setOpenDelete,
            data, setData,
            tableParams, setTableParams,
            formFilters, setFormFilters,
            openUpdatePassword, setOpenUpdatePassword,
            model, setModel,
            switchDataType, setSwitchDataType,
            open, setOpen,
            getUsersDefault, getUsersByFilter, getUsersByPagination,
            createUser, updateUser, updateUserPassword, updateUserActive, getMenu
        }}>
            {children}
        </UserContext.Provider>
    );
};