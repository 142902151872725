import { useEffect, useState } from "react";
import { createContext } from "react";
import ConsumptionService from "../services/ConsumptionService";
import { Form, message } from "antd";

export const PartialPaymentContext = createContext();

export const PartialPaymentProvider = ({ children, consumption_id }) => {

    const [consumption, setConsumption] = useState(null);

    const [partialPayment, setPartialPayment] = useState(null);

    const [partialPaymentDetails, setPartialPaymentDetails] = useState([]);

    const [formCreatePartialPaymentDetail] = Form.useForm();

    const [showCreatePartialPaymentDetail, setShowCreatePartialPaymentDetail] = useState(false);

    const [deletePartialPaymentDetailModel, setDeletePartialPaymentDetailModel] = useState(null);

    const [showDeletePartialPaymentDetail, setShowDeletePartialPaymentDetail] = useState(false);

    const getDefaultData = () => {

        ConsumptionService.getPartialPaymentsDetails(consumption_id).then(response => {

            const { consumption, partial_payment, partial_payment_details } = response;

            setConsumption(consumption);

            setPartialPayment(partial_payment);

            setPartialPaymentDetails(partial_payment_details);

        }).catch(error => {
            console.log(error);
            message.error('Ocurrió un error con el servicio.');
        });

    };

    const createPartialPaymentDetail = () => {

        const amount = formCreatePartialPaymentDetail.getFieldValue('amount');

        ConsumptionService.createPartialPaymentsDetail(consumption_id, amount).then(response => {
            message.success('Se registró el pago parcial.');
            getDefaultData();
            setShowCreatePartialPaymentDetail(false);
            formCreatePartialPaymentDetail.resetFields();
        }).catch(error => {
            console.log(error);
            message.error('Ocurrió un error con el servicio.');
        });

    };

    const deletePartialPaymentDetail = () => {

        ConsumptionService.deletePartialPaymentsDetail(consumption_id, deletePartialPaymentDetailModel.id).then(response => {
            message.success('Se eliminó el pago parcial.');
            getDefaultData();
            setShowDeletePartialPaymentDetail(false);
            setDeletePartialPaymentDetailModel(null);
        }).catch(error => {
            console.log(error);
            message.error('Ocurrió un error con el servicio.');
        });

    };

    useEffect(() => {
        getDefaultData();
    }, []);

    return (
        <PartialPaymentContext.Provider value={{
            consumption, partialPayment, partialPaymentDetails,
            formCreatePartialPaymentDetail, createPartialPaymentDetail,
            showCreatePartialPaymentDetail, setShowCreatePartialPaymentDetail,
            deletePartialPaymentDetailModel, setDeletePartialPaymentDetailModel,
            showDeletePartialPaymentDetail, setShowDeletePartialPaymentDetail,
            deletePartialPaymentDetail
        }}>
            {children}
        </PartialPaymentContext.Provider>
    )

};