import { useMediaQuery } from "react-responsive";
import { RoomHistoricCleaningContext } from "../../contexts/RoomHistoricCleaningContext";
import DateUtil from "../../utils/DateUtil";
import { useContext } from "react";
import { Card, List, Pagination, Table } from "antd";

const RoomHistoricCleaningRecords = () => {

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const {
        data, tableParams,
        getHistoricByPagination
    } = useContext(RoomHistoricCleaningContext);

    const onChangeTable = (pagination, filters, sorter) => {
        getHistoricByPagination(pagination.current, pagination.pageSize);
    };

    const onChangeList = (page, pageSize) => {
        getHistoricByPagination(page, pageSize);
    };

    const onShowSizeChange = (current, size) => {
        getHistoricByPagination(current, size);
    };

    return (isMobile ?
        <>
            <List
                itemLayout="vertical"
                dataSource={data}
                renderItem={(model, index) => (
                    <List.Item>
                        <Card bordered={false}>
                            <div className="RowContainer">
                                <div className="ColumnContainer">
                                    <div><strong>Registrado:</strong> {DateUtil.ToSimpleString(model.created_at)}</div>
                                    <div><strong>Tipo:</strong> {model.is_current ? <>Actual</> : <>Pasado</>}</div>
                                    <div><strong>Estado:</strong> {model.status}</div>
                                    <div><strong>Nota:</strong> {model.note}</div>
                                    <div><strong>Solicitante:</strong> {model.applicant}</div>
                                    <div><strong>Responsable:</strong> {model.cleaner}</div>
                                </div>
                            </div>
                        </Card>
                    </List.Item>
                )}
            />
            <Pagination
                pageSize={tableParams.pagination.pageSize}
                showSizeChanger={tableParams.pagination.showSizeChanger}
                onShowSizeChange={onShowSizeChange}
                total={tableParams.pagination.total}
                pageSizeOptions={tableParams.pagination.pageSizeOptions}
                current={tableParams.pagination.current}
                onChange={onChangeList}
            />
        </> :
        <Table
            pagination={tableParams.pagination}
            bordered
            dataSource={data}
            rowKey={(record) => record.id}
            onChange={onChangeTable}
            columns={[
                {
                    title: 'Registrado', dataIndex: 'created_at', width: 200, key: 'created_at', render: (value) => {
                        return (<>{DateUtil.ToSimpleString(value)}</>);
                    }
                },
                {
                    title: 'Tipo', key: 'is_current', render: (value) => {
                        return (<>{value.is_current ? 'Actual' : 'Pasado'}</>);
                    }
                },
                { title: 'Estado', dataIndex: 'status', key: 'status' },
                { title: 'Nota', dataIndex: 'note', key: 'note' },
                { title: 'Solicitante', dataIndex: 'applicant', key: 'applicant' },
                { title: 'Responsable', dataIndex: 'cleaner', key: 'cleaner' }
            ]}
        />
    );

}

export default RoomHistoricCleaningRecords;