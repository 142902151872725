import { useContext, useEffect, useState } from "react";
import { Descriptions, Modal } from "antd";
import GeneralUtil from "../../utils/GeneralUtil";
import { SaleDetailContext } from "../../contexts/SaleDetailContext";

const SaleDetailModal = () => {

    const { modelDetail, openDetailModal, setOpenDetailModal } = useContext(SaleDetailContext);

    const [items, setItems] = useState([]);

    const [total, setTotal] = useState(null);

    useEffect(() => {

        if (modelDetail) {

            const details = [];

            modelDetail.details.map((detail, index) => {

                if (modelDetail.type === 'ROOM') {
                    const item = [
                        { key: `${index}`, label: 'Descripción', children: 'HABITACIÓN' },
                        { key: `${index + 1}`, label: 'Cantidad', children: detail.quantity },
                        { key: `${index + 2}`, label: 'Precio Unidad', children: GeneralUtil.getMoney(detail.unit_price) },
                        { key: `${index + 3}`, label: 'Total', children: GeneralUtil.getMoney(detail.total) }
                    ];
                    details.push(item);
                }

                if (modelDetail.type === 'PRODUCT') {
                    const item = [
                        { key: `${index + 1}`, label: 'Descripción', children: `${detail.product_name}` },
                        { key: `${index + 2}`, label: 'Cantidad', children: detail.quantity },
                        { key: `${index + 3}`, label: 'Precio Unidad', children: GeneralUtil.getMoney(detail.unit_price) },
                        { key: `${index + 4}`, label: 'Total', children: GeneralUtil.getMoney(detail.total) }
                    ];
                    details.push(item);
                }

            });

            setItems(details);

            setTotal([{
                key: 1, label: 'Monto Total', children: GeneralUtil.getMoney(modelDetail.amount)
            }]);

        }
    }, [modelDetail]);

    const onCancel = () => {
        setOpenDetailModal(false);
    };

    return (
        <>
            {modelDetail && (
                <Modal
                    width={1100}
                    title='Detalle de consumo'
                    open={openDetailModal}
                    onCancel={onCancel}
                    footer={null}
                >
                    <div style={{ marginBottom: '10px', padding: '20px' }}>
                        {items.map(item => (<Descriptions column={{ xs: 1, sm: 2, md: 2, lg: 4, xl: 4, xxl: 4 }} items={item} bordered />))}
                    </div>
                    {total && <Descriptions items={total} bordered />}
                </Modal>
            )}
        </>
    );

};

export default SaleDetailModal;