import { useContext } from "react";
import { GuestFormContext } from "../../contexts/GuestFormContext";
import ValidationHelper from "../../helpers/ValidationHelper";
import { Card, Form, Input, message, Select } from "antd";
import RulesHelper from "../../helpers/RulesHelper";
import GeneralHelper from "../../helpers/GeneralHelper";

const GuestFormCustomerSection = () => {

    const { form, getCustomer, disableCustomerInputs } = useContext(GuestFormContext);

    const onSearchCustomer = () => {

        const { document_number, document_type } = form.getFieldsValue();

        if (!ValidationHelper.hasData(document_type)) return message.warning('Por favor, seleccione un tipo de documento.');

        const firstValidation = ValidationHelper.hasData(document_number)

        if (!firstValidation) return message.warning('Por favor, ingrese un número de documento.');

        const secondValidation = ValidationHelper.maxLength(document_number, 20);

        if (!secondValidation) return message.warning('Por favor, ingrese un número de documento de máximo 20 caracteres.');

        getCustomer({ document_type, document_number });

    };

    return (
        <Card title={'Datos del cliente'} style={{ marginBottom: '20px' }}>
            <Form.Item label="Tipo de documento" name="document_type" rules={RulesHelper.required()}>
                <Select options={GeneralHelper.getCustomerDocumentSelectOptions()} disabled={disableCustomerInputs} />
            </Form.Item>
            <Form.Item label="Número de documento" name="document_number" rules={RulesHelper.max(20)}>
                <Input.Search onSearch={onSearchCustomer} disabled={disableCustomerInputs} />
            </Form.Item>
            <Form.Item label="Nombres" name="name" rules={RulesHelper.max(50)}>
                <Input disabled={disableCustomerInputs} />
            </Form.Item>
            <Form.Item label="Apellidos" name="lastname" rules={RulesHelper.max(50)}>
                <Input disabled={disableCustomerInputs} />
            </Form.Item>
            <Form.Item label="Género" name="gender" rules={RulesHelper.required()}>
                <Select options={GeneralHelper.getGenderSelectOptions()} disabled={disableCustomerInputs} />
            </Form.Item>
            <Form.Item label="Número de celular" name="phone" rules={RulesHelper.maxNotRequired(40)}>
                <Input disabled={disableCustomerInputs} />
            </Form.Item>
            <Form.Item label="Dirección" name="address" rules={RulesHelper.maxNotRequired(50)}>
                <Input disabled={disableCustomerInputs} />
            </Form.Item>
            <Form.Item label="Empresa" name="company" rules={RulesHelper.maxNotRequired(50)}>
                <Input disabled={disableCustomerInputs} />
            </Form.Item>
        </Card>
    );
};

export default GuestFormCustomerSection;