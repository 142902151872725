import { Form, message } from "antd";
import { createContext } from "react";
import CustomerAccountService from "../services/CustomerAccountService";

export const LaundryConsumptionContext = createContext();

export const LaundryConsumptionProvider = ({ children, customer_account_id, onBack }) => {

    const [form] = Form.useForm();

    const createLaundryConsumption = (values) => {

        CustomerAccountService.createLaundryConsumption(customer_account_id, values).then(response => {
            message.success('Se registró el consumo por lavandería.');
            form.resetFields();
            if (onBack) onBack();
        }).catch(error => {
            console.log(error);
            message.error('Ocurrió un error con el servicio.');
        });

    };

    return (
        <LaundryConsumptionContext.Provider value={{
            form, createLaundryConsumption
        }}>
            {children}
        </LaundryConsumptionContext.Provider>
    );

};