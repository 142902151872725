class ParseHelper {
    parseBoolean(value) {
        if (value === 'true' || value === '1' || value === true) {
            return true;
        }
        return false;
    }
    parseInteger(value) {
        return parseInt(value, 10);
    }
    parseIntegerOrNull(value) {
        if (value === '' || value === undefined || value === null) {
            return null;
        }
        return parseInt(value, 10);
    }
    parseIntegerOrEmptyString(value) {
        if (value === '' || value === undefined || value === null) {
            return '';
        }
        return parseInt(value, 10);
    }
    parseStringOrNull(value) {
        if (value === '' || value === undefined) {
            return null;
        }
        return value;
    }
    parseString(value) {
        if (value === undefined || value === null) {
            return '';
        }
        return value;
    }
}

export default new ParseHelper();