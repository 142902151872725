import { Modal } from "antd";
import { PartialPaymentContext } from "../../contexts/PartialPaymentContext";
import { useContext } from "react";
import DateUtil from "../../utils/DateUtil";
import GeneralUtil from "../../utils/GeneralUtil";

const PartialPaymentDeleteModal = () => {

    const {
        deletePartialPaymentDetailModel, setDeletePartialPaymentDetailModel,
        showDeletePartialPaymentDetail, setShowDeletePartialPaymentDetail,
        deletePartialPaymentDetail
    } = useContext(PartialPaymentContext);

    const onCancel = () => {
        setDeletePartialPaymentDetailModel(null);
        setShowDeletePartialPaymentDetail(false);
    };

    const onOk = () => {
        deletePartialPaymentDetail();
    };

    return (
        deletePartialPaymentDetailModel &&
        <Modal
            title={'Eliminar Pago Parcial'}
            open={showDeletePartialPaymentDetail}
            onCancel={onCancel}
            onOk={onOk}
        >
            <p>¿Está seguro que quiere eliminar el pago parcial con monto
                {` ${GeneralUtil.getMoney(Number(deletePartialPaymentDetailModel.amount).toFixed(2))} `}
                registrado el {DateUtil.ToSimpleString(deletePartialPaymentDetailModel.created_at)}?
            </p>
        </Modal>
    );

};

export default PartialPaymentDeleteModal;