import { useContext } from "react";
import { ConsumptionProductContext } from "../../contexts/ConsumptionProductContext";
import { Button, Card, Dropdown, List, Table } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import GeneralUtil from "../../utils/GeneralUtil";

const ConsumptionProductSelected = () => {

    const {
        dataSelected,
        setProductSelected,
        setModalProductSelected,
        setOpenDeleteProduct
    } = useContext(ConsumptionProductContext);

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const getMenu = (value) => {

        const menu = {
            items: [
                {
                    key: 'edit', label: 'Editar', onClick: () => {
                        setProductSelected({ ...value, type: 'edit' });
                        setModalProductSelected(true);
                    }
                },
                {
                    key: 'delete', label: 'Eliminar', onClick: () => {
                        setProductSelected(value);
                        setOpenDeleteProduct(true);
                    }
                }
            ]
        };

        return menu;

    };

    return (isMobile ?
        (
            <>
                <List
                    itemLayout="vertical"
                    dataSource={dataSelected}
                    renderItem={(model, index) => (
                        <List.Item key={index}>
                            <Card bordered={false}>
                                <div className="RowContainer">
                                    <div className="ColumnContainer">
                                        <div><strong>Código:</strong> {model.product_code}</div>
                                        <div><strong>Nombre:</strong> {model.product_name}</div>
                                        <div><strong>Precio:</strong> {GeneralUtil.getMoney(model.product_price)}</div>
                                        <div><strong>Total:</strong> {GeneralUtil.getMoney(model.total)}</div>
                                        <div><strong>Cantidad:</strong> {model.count}</div>
                                    </div>
                                    <div className="ColumnContainer ColumnContainerVentas" style={{ width: '100%' }}>
                                        <Dropdown menu={getMenu(model)} trigger={['click']} >
                                            <Button icon={<SettingOutlined />}>Acciones</Button>
                                        </Dropdown>
                                    </div>
                                </div>
                            </Card>
                        </List.Item>
                    )}
                />
            </>
        ) :
        (
            <Table
                pagination={false}
                bordered
                dataSource={dataSelected}
                rowKey={(record) => record.id}
                columns={[
                    { title: 'Nombre', dataIndex: 'product_name', key: 'product_name', fixed: true, width: 300 },
                    { title: 'Código', dataIndex: 'product_code', key: 'product_code' },
                    { title: 'Precio', dataIndex: 'product_price', key: 'price', render: (value) => GeneralUtil.getMoney(value) },
                    { title: 'Cantidad', dataIndex: 'count', key: 'count' },
                    { title: 'Total', dataIndex: 'total', key: 'total', render: (value) => GeneralUtil.getMoney(value) },
                    {
                        title: 'Opciones', key: 'Acciones', width: 150, render: (value) => (
                            <Dropdown trigger={['click']} menu={getMenu(value)}>
                                <Button icon={<SettingOutlined />}>Acciones</Button>
                            </Dropdown>
                        )
                    },
                ]}
            />
        )
    );

};

export default ConsumptionProductSelected;