import { Button } from "antd";
import { useContext } from "react";
import { ConsumptionProductContext } from "../../contexts/ConsumptionProductContext";

const ConsumptionProductAdd = () => {

    const { handlerAddConsumption } = useContext(ConsumptionProductContext);

    const onClick = () => {
        handlerAddConsumption();
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button onClick={onClick}>Agregar Consumo</Button>
        </div>
    )

};

export default ConsumptionProductAdd;