import { useContext } from "react";
import { CustomerAccountContext } from "../../contexts/CustomerAccountContext";
import ParseHelper from "../../helpers/ParseHelper";
import GeneralHelper from "../../helpers/GeneralHelper";
import { Button, Form, Input, Select } from "antd";

const CustomerAccountFilter = () => {

    const { getDataByFilter } = useContext(CustomerAccountContext);

    const onFinish = (values) => {
        getDataByFilter({
            full_name: ParseHelper.parseString(values.full_name),
            company: ParseHelper.parseString(values.company),
            document_type: ParseHelper.parseString(values.document_type),
            document_number: ParseHelper.parseString(values.document_number),
            code: ParseHelper.parseString(values.code),
            paid: ParseHelper.parseBoolean(values.paid)
        });
    };

    return (
        <Form
            className="FilterContainer"
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item name={"full_name"} label={"Filtro por nombre completo del cliente"}>
                <Input />
            </Form.Item>
            <Form.Item name={"company"} label={"Filtro por empresa"}>
                <Input />
            </Form.Item>
            <Form.Item name={"document_type"} label={"Filtro por tipo de documento"}>
                <Select allowClear options={GeneralHelper.getCustomerDocumentSelectOptions()} />
            </Form.Item>
            <Form.Item name={"document_number"} label={"Filtro por número de documento"}>
                <Input />
            </Form.Item>
            <Form.Item name={"code"} label={"Filtro por código de cuenta"}>
                <Input />
            </Form.Item>
            <Form.Item name={"paid"} label={"Filtro por estado"}>
                <Select defaultValue={false} options={[
                    { label: 'PAGADO', value: true },
                    { label: 'PENDIENTE', value: false },
                ]} />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">Buscar</Button>
            </Form.Item>
        </Form>
    );

}

export default CustomerAccountFilter;