import EnvConfig from "../config/EnvConfig";
import ServiceError from "../errors/ServiceError";
import LocalStorageHelper from "../helpers/LocalStorageHelper";
class ApiService {
    constructor() {
        this.baseURL = EnvConfig.getUrl();
    }
    handleErrors(response) {
        if (!response.ok) {
            throw new ServiceError(`Service Error`, response.status);
        }
        return response.json();
    }
    getToken() {
        return localStorage.getItem(LocalStorageHelper.getAccessToken());
    }
    getRefreshToken() {
        return localStorage.getItem(LocalStorageHelper.getRefreshToken());
    }
    async request(endpoint, method = 'GET', body = null, authRequired = false) {
        const url = (method === 'GET') ? endpoint : new URL(`${this.baseURL}${endpoint}`);
        const headers = { 'Content-Type': 'application/json' };
        if (authRequired) {
            const token = this.getToken();
            if (token) {
                headers['authorization'] = token;
            } else {
                throw new Error('No token available for authenticated request.');
            }
        }
        const options = { method, headers };
        if (body) options.body = JSON.stringify(body);
        try {
            let response = await fetch(url, options);
            if (authRequired && response.status === 401) {
                const tokens = await this.updateTokens();
                if (tokens === null) {
                    throw new Error('Error refresh token.');
                }
                options.headers['authorization'] = tokens.accessToken;
                response = await fetch(url, options);
            }
            const data = this.handleErrors(response);
            return data;
        } catch (error) {
            if (error instanceof ServiceError) {
            } else {
                localStorage.clear();
            }
            throw error;
        }
    }
    get(endpoint, params = {}, authRequired = false) {
        const url = new URL(`${this.baseURL}${endpoint}`);
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        return this.request(url, 'GET', null, authRequired);
    }
    post(endpoint, body, authRequired = false) {
        return this.request(endpoint, 'POST', body, authRequired);
    }
    put(endpoint, body, authRequired = false) {
        return this.request(endpoint, 'PUT', body, authRequired);
    }
    patch(endpoint, body, authRequired = false) {
        return this.request(endpoint, 'PATCH', body, authRequired);
    }
    delete(endpoint, authRequired = false) {
        return this.request(endpoint, 'DELETE', null, authRequired);
    }
    login(username, password) {
        return this.post('/auth/login', { username, password });
    }
    async updateTokens() {

        try {

            const response = await fetch(`${this.baseURL}/auth/refresh-token`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ refreshToken: this.getRefreshToken() }),
            });

            if (!response.ok) {
                localStorage.clear();
                return null;
            }

            const data = await response.json();

            const {
                accessToken,
                refreshToken,
                access_token_expiration_datetime,
                refresh_token_expiration_datetime
            } = data;

            localStorage.setItem(LocalStorageHelper.keyAccessToken, accessToken);
            localStorage.setItem(LocalStorageHelper.keyRefreshToken, refreshToken);
            localStorage.setItem(LocalStorageHelper.keyAccessTokenExpiration, access_token_expiration_datetime);
            localStorage.setItem(LocalStorageHelper.keyRefreshTokenExpiration, refresh_token_expiration_datetime);

            return { accessToken, refreshToken, refresh_token_expiration_datetime };

        } catch (err) {
            localStorage.clear();
            return null;
        }

    }
}

export default new ApiService();