import { useContext } from "react";
import { PartialPaymentContext } from "../../contexts/PartialPaymentContext";
import { useMediaQuery } from "react-responsive";
import GeneralUtil from "../../utils/GeneralUtil";
import { Button, Card, List, Table } from "antd";
import DateUtil from "../../utils/DateUtil";

const PartialPaymentDetailRecords = () => {

    const {
        partialPaymentDetails,
        setDeletePartialPaymentDetailModel, setShowDeletePartialPaymentDetail,
    } = useContext(PartialPaymentContext);

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const handleDelete = (model) => {

        setDeletePartialPaymentDetailModel(model);

        setShowDeletePartialPaymentDetail(true);

    };

    return (
        <Card title={'LISTA DE PAGOS'}>
            {isMobile ?
                (
                    <>
                        <List
                            itemLayout="vertical"
                            dataSource={partialPaymentDetails}
                            renderItem={(model, index) => (
                                <List.Item key={index}>
                                    <Card bordered={false}>
                                        <div className="RowContainer">
                                            <div className="ColumnContainer">
                                                <div><strong>Monto:</strong> {GeneralUtil.getMoney(model.amount)}</div>
                                                <div><strong>Registrado:</strong> {DateUtil.ToSimpleString(model.created_at)}</div>
                                                <div><Button onClick={() => { handleDelete(model); }}>Eliminar</Button></div>
                                            </div>
                                        </div>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </>
                ) :
                (
                    <Table
                        pagination={false}
                        bordered
                        dataSource={partialPaymentDetails}
                        rowKey={(record) => record.id}
                        columns={[
                            {
                                title: 'Registrado', dataIndex: 'created_at', key: 'created_at', render: (model) => {
                                    return (<>{DateUtil.ToSimpleString(model)}</>);
                                }
                            },
                            {
                                title: 'Monto', key: 'amount', render: (model) => {
                                    return (<>{GeneralUtil.getMoney(model.amount)}</>);
                                }
                            },
                            {
                                title: 'Opciones', key: 'options', render: (model) => {
                                    return (<Button onClick={() => { handleDelete(model); }}>Eliminar</Button>);
                                }
                            }
                        ]}
                    />
                )}
        </Card>
    );

};

export default PartialPaymentDetailRecords;