import { useContext } from "react";
import { LaundryConsumptionContext } from "../../contexts/LaundryConsumptionContext";
import { Button, Card, Form, Input } from "antd";
import RulesHelper from "../../helpers/RulesHelper";

const LaundryConsumptionForm = () => {

    const { form, createLaundryConsumption } = useContext(LaundryConsumptionContext);

    const onFinish = (values) => {
        createLaundryConsumption(values);
    };

    return (
        <Card title={'FORMULARIO CONSUMO DE LAVADO'}>
            <Form
                form={form}
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item label="Peso" name="weight" rules={RulesHelper.weight()}>
                    <Input prefix={'k'} />
                </Form.Item>
                <Form.Item label="Precio" name="amount" rules={RulesHelper.money()}>
                    <Input prefix={'S/'} />
                </Form.Item>
                <Form.Item label="Nota" name="note" rules={RulesHelper.maxNotRequired(250)}>
                    <Input.TextArea rows={5} />
                </Form.Item>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button type="primary" size="large" htmlType="submit" >Registrar</Button>
                </div>
            </Form>
        </Card>
    );

}

export default LaundryConsumptionForm;