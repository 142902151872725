import { useContext } from "react";
import { CleaningMonitoringContext } from "../../contexts/CleaningMonitoringContext";
import { Button, Card, Empty } from "antd";
import RoomTypeHelper from "../../helpers/RoomTypeHelper";

const CleaningMonitoringRecords = () => {

    const { data, setShowView, setUpdateStatusModel } = useContext(CleaningMonitoringContext);

    const handleUpdateRecord = (model) => {
        setUpdateStatusModel(model);
        setShowView('cleaning_monitoring_form');
    }

    return (
        <>
            {data.length === 0 && (
                <Empty
                    style={{ maxWidth: '100%' }}
                    description={'No hay solicitudes de limpieza.'}
                />
            )}

            <div className="MonitoringList">
                {data.map((model, index) => (
                    <div key={index}>

                        {model.status === 'LIMPIO' && (
                            <Card bordered={false} style={{ backgroundColor: '#0c5707', color: '#FFF' }}>
                                <div style={{ marginBottom: '10px' }}>
                                    <div><strong>Habitación:</strong> {model.room_number}</div>
                                    <div><strong>Tipo:</strong> {RoomTypeHelper.getDescription(model.room_type)}</div>
                                    <div><strong>Estado:</strong> {model.status}</div>
                                </div>
                            </Card>
                        )}

                        {model.status === 'EN LIMPIEZA' && (
                            <Card bordered={false} style={{ backgroundColor: '#ced130' }}>
                                <div style={{ marginBottom: '10px' }}>
                                    <div><strong>Habitación:</strong> {model.room_number}</div>
                                    <div><strong>Tipo:</strong> {RoomTypeHelper.getDescription(model.room_type)}</div>
                                    <div><strong>Estado:</strong> {model.status}</div>
                                    <div><strong>Encargado:</strong> {model.cleaner}</div>
                                </div>
                                <div className="ColumnContainer ColumnContainerVentas" style={{ width: '100%' }}>
                                    <Button onClick={() => { handleUpdateRecord(model); }}>FINALIZAR LIMPIEZA</Button>
                                </div>
                            </Card>
                        )}

                        {model.status === 'SUCIO' && (
                            <Card bordered={false} style={{ backgroundColor: '#730a0a', color: '#FFF' }}>
                                <div style={{ marginBottom: '10px' }}>
                                    <div><strong>Habitación:</strong> {model.room_number}</div>
                                    <div><strong>Tipo:</strong> {RoomTypeHelper.getDescription(model.room_type)}</div>
                                    <div><strong>Estado:</strong> {model.status}</div>
                                </div>
                                <div className="ColumnContainer ColumnContainerVentas" style={{ width: '100%' }}>
                                    <Button onClick={() => { handleUpdateRecord(model); }}>LIMPIAR</Button>
                                </div>
                            </Card>
                        )}

                    </div>
                ))}
            </div>
        </>
    );

};

export default CleaningMonitoringRecords;