import ApiService from "./ApiService";

class AuthService {
    login(request) {
        const { email, password } = request;
        return ApiService.login(email, password);
    }
    logout(token_id) {
        return ApiService.post('/auth/logout', { token_id }, true);
    }
}

export default new AuthService();