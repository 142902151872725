import { createContext, useEffect, useState } from "react";
import SaleService from "../services/SaleService";
import { message } from "antd";

export const SaleDetailContext = createContext();

export const SaleDetailProvider = ({ children, sale }) => {

    const [data, setData] = useState([]);

    const [openDetailModal, setOpenDetailModal] = useState(false);

    const [modelDetail, setModelDetail] = useState(null);

    const getSaleDetail = () => {
        SaleService.detail(sale.id).then(response => {
            setData(response.consumptions);
        }).catch(error => {
            console.log(error);
            message.error('Ocurrió un error con el servicio.');
        });
    };

    useEffect(() => {
        if (sale) getSaleDetail();
    }, [sale]);

    return (
        <SaleDetailContext.Provider value={{
            data, sale,
            openDetailModal, setOpenDetailModal,
            modelDetail, setModelDetail
        }}>
            {children}
        </SaleDetailContext.Provider>
    );

};