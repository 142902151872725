import { Button, Form, Input, Modal, Select } from "antd";
import { useContext, useState } from "react";
import { GuestContext } from "../../contexts/GuestContext";
import RulesHelper from "../../helpers/RulesHelper";

const GuestExitModal = () => {

    const { exitModal, setExitModal, exitService, formSale } = useContext(GuestContext);

    const [showExtraInputs, setShowExtraInputs] = useState(false);

    const { model, open } = exitModal;

    const onCancel = () => {
        setExitModal({ open: false, model: null });
        formSale.resetFields();
    };

    const onFinish = (values) => {
        exitService(values);
    };

    const onOk = () => {
        exitService({
            sale_type: 'BOLETA',
            type_of_transfer: 'YAPE'
        });
    };

    if (model && model.customer_account_paid) {
        return (
            <Modal
                title={'Registro de salida'}
                open={open}
                onOk={onOk}
                onCancel={onCancel}
            >
                <p>Seguro que quiere registrar la salida de {model.name} {model.lastname} de la habitación {model.room_number}</p>
            </Modal>
        );
    }

    return (model && (
        <Modal
            title={'Registro de salida'}
            open={open}
            footer={null}
            onCancel={onCancel}
        >
            <Form
                form={formSale}
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item name={"sale_type"} label={"Tipo de venta"} rules={RulesHelper.required()}>
                    <Select
                        placeholder='Por favor, selecciona un tipo de pago.'
                        allowClear
                        options={[
                            { label: 'Factura', value: 'FACTURA' },
                            { label: 'Boleta', value: 'BOLETA' }
                        ]}
                        onChange={(value) => {
                            setShowExtraInputs(value === 'FACTURA');
                        }}
                    />
                </Form.Item>
                <Form.Item name={"type_of_transfer"} label={"Tipo de transferencia"} rules={RulesHelper.required()}>
                    <Select
                        placeholder='Por favor, selecciona un tipo de transferencia.'
                        allowClear
                        options={[
                            { label: 'YAPE', value: 'YAPE' },
                            { label: 'PLIN', value: 'PLIN' },
                            { label: 'TRANSFERENCIA', value: 'TRANSFERENCIA' },
                            { label: 'EFECTIVO', value: 'EFECTIVO' },
                            { label: 'TARJETA DEBITO', value: 'TARJETA DEBITO' },
                            { label: 'TARJETA CREDITO', value: 'TARJETA CREDITO' }
                        ]}
                    />
                </Form.Item>
                {showExtraInputs && (
                    <>
                        <Form.Item name={'ruc'} label='RUC' rules={RulesHelper.max(11)}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={'razon_social'} label='Razón Social' rules={RulesHelper.max(250)}>
                            <Input />
                        </Form.Item>
                    </>
                )}
                <Form.Item>
                    <Button type="primary" htmlType="submit">Registrar</Button>
                </Form.Item>
            </Form>
        </Modal>
    ));

};

export default GuestExitModal;