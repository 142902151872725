import { Button } from "antd";
import RoomTypeHelper from "../../helpers/RoomTypeHelper";

const DisponibilityCard = ({ model, handlerCleanRoom }) => (
    <div style={{
        backgroundColor: '#0c5707',
        borderRadius: '10px',
        color: '#FFF',
        height: '100%',
        minHeight: '180px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '20px'
    }}>
        <div>
            <div><strong>Habitación:</strong> {model.room_number}</div>
            <div><strong>Tipo:</strong> {RoomTypeHelper.getDescription(model.room_type)}</div>
            <div><strong>Estado:</strong> DISPONIBLE</div>
            {model.room_cleaning_status === 'LIMPIO' && (
                <div>
                    <strong>Limpieza:</strong> {model.room_cleaning_status}
                </div>
            )}
            {model.room_cleaning_status !== 'LIMPIO' && (
                <div style={{ background: '#d15d04', color: '#000' }}>
                    <strong>Limpieza:</strong> {model.room_cleaning_status}
                </div>
            )}
        </div>
        {model.room_cleaning_status === 'LIMPIO' && (
            <div className="ColumnContainer ColumnContainerVentas" style={{ width: '100%' }}>
                <Button onClick={() => { handlerCleanRoom(model.room_cleaning_record_id); }}>Solicitar Limpieza</Button>
            </div>
        )}
    </div>
);

export default DisponibilityCard;
