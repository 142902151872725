import React from "react";
import { LoginProvider } from "../contexts/LoginContext";
import LoginForm from "../components/LoginComponents/LoginForm";
import { Card } from "antd";

const LoginPage = () => (
    <div className="LoginPage">
        <LoginProvider>
            <div style={{ textAlign: 'center' }}>
                <img src="./logo.png" width={'250px'} style={{ marginBottom: '40px' }} />
                <div style={{ textAlign: 'left' }}>
                    <Card className="LoginCard">
                        <LoginForm />
                    </Card>
                </div>
            </div>
        </LoginProvider>
    </div>
);

export default LoginPage;