import GeneralHelper from "../helpers/GeneralHelper";
import ApiService from "./ApiService";

class UserService {
    pagination(params, pageNumber, pageSize, active) {
        return ApiService.get('/users', { ...params, page_number: pageNumber, page_size: pageSize, active: active }, true);
    }
    create(body) {
        return ApiService.post('/users', { ...body, created_at: GeneralHelper.getLimaPeruTime() }, true);
    }
    update(id, body) {
        return ApiService.patch(`/users/${id}`, { ...body, type: 'data' }, true);
    }
    updatePassword(id, password) {
        return ApiService.patch(`/users/${id}`, { password, type: 'password' }, true);
    }
    updateActive(id, active) {
        return ApiService.patch(`/users/${id}`, { active, type: 'active' }, true);
    }
}

export default new UserService();