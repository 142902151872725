import { Form, message } from "antd";
import { createContext, useEffect, useState } from "react";
import RoomCleaningService from "../services/RoomCleaningService";
import LocalStorageHelper from "../helpers/LocalStorageHelper";

export const CleaningMonitoringFormContext = createContext();

export const CleaningMonitoringFormProvider = ({ children, room_cleaning_record, type_form, onBack }) => {

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    useEffect(() => {

        if (room_cleaning_record) {

            form.setFieldValue('old_note', room_cleaning_record.note);

            if (room_cleaning_record.status === 'SUCIO') {
                form.setFieldValue('status', 'EN LIMPIEZA');
            }

            if (room_cleaning_record.status === 'LIMPIO') {
                form.setFieldValue('status', 'SUCIO');
            }

            if (room_cleaning_record.status === 'EN LIMPIEZA') {
                form.setFieldValue('status', 'LIMPIO');
            }

        }

    }, [room_cleaning_record])

    const updateStatus = () => {

        setLoading(true);

        const { status, note } = form.getFieldsValue();

        const user = JSON.parse(LocalStorageHelper.getUserData());

        if (type_form === 'request') {
            RoomCleaningService.updateStatusByApplicant(room_cleaning_record.id, user.email, { status, note }).then(response => {

                message.success('Se actualizó el registro de limpieza.');

                form.resetFields();

                if (onBack) onBack();

            }).catch(error => {

                console.log(error);

                message.error('Ocurrio un error con el servicio.');

            }).finally(() => {
                setLoading(true);
            });
        }

        if (type_form === 'cleaner') {

            RoomCleaningService.updateStatusByCleaner(room_cleaning_record.id, user.email, { status, note }).then(response => {

                message.success('Se actualizó el registro de limpieza.');

                form.resetFields();

                if (onBack) onBack();

            }).catch(error => {

                console.log(error);

                message.error('Ocurrio un error con el servicio.');

            }).finally(() => {
                setLoading(true);
            });

        }

    };

    return (
        <CleaningMonitoringFormContext.Provider value={{
            form, updateStatus, room_cleaning_record, loading
        }}>
            {children}
        </CleaningMonitoringFormContext.Provider>
    );

}