import { Form, message } from "antd";
import { createContext, useEffect } from "react";
import LaundryRecordService from "../services/LaundryRecordService";
import LocalStorageHelper from "../helpers/LocalStorageHelper";

export const LaundryRecordFormContext = createContext();

export const LaundryRecordFormProvider = ({ children, laundry_record, onBack }) => {

    const [form] = Form.useForm();

    const updateStatus = (values) => {

        const user = JSON.parse(LocalStorageHelper.getUserData());

        LaundryRecordService.updateStatus(laundry_record.id, {
            ...values,
            email: user.email
        }).then(response => {
            message.success('Se actulizó el regitro de lavado.');
            form.resetFields();
            if (onBack) onBack();
        }).catch(error => {
            console.log(error);
            message.error('Ocurrió un error con el servicio.');
        });

    };

    useEffect(() => {
        if (laundry_record) {
            if (laundry_record.laundry_status === 'PENDIENTE') form.setFieldValue('laundry_status', 'EN LAVADO');
            if (laundry_record.laundry_status === 'EN LAVADO') form.setFieldValue('laundry_status', 'LIMPIO');
            if (laundry_record.laundry_note) form.setFieldValue('old_note', laundry_record.laundry_note);
        }
    }, [laundry_record]);

    return (
        <LaundryRecordFormContext.Provider value={{
            form, updateStatus, laundry_record
        }}>
            {children}
        </LaundryRecordFormContext.Provider>
    );

};