import { Button, Form, Input } from "antd";
import { SettingContext } from "../../contexts/SettingContext";
import { useContext } from "react";
import ParseHelper from "../../helpers/ParseHelper";

const SettingFilter = () => {
    const { getSettingsByFilters } = useContext(SettingContext);
    const onFinish = (values) => {
        const description = ParseHelper.parseString(values.description);
        getSettingsByFilters({ description });
    };
    return (
        <Form className="FilterContainer" layout="vertical" onFinish={onFinish} autoComplete="off">
            <Form.Item name={"description"} label={"Filtro por configuración"}>
                <Input />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">Buscar</Button>
            </Form.Item>
        </Form>
    );
};

export default SettingFilter;