import { Form, Input, Modal } from "antd";
import RulesHelper from "../../helpers/RulesHelper";
import { ProductStockContext } from "../../contexts/ProductStockContext";
import { useContext } from "react";

const ProductStockModal = () => {
    const { form, createType, model, open, setOpen, setModel, createProductStock, updateProductStock } = useContext(ProductStockContext);
    const onCancel = () => {
        setOpen(false);
        setModel(null);
        form.resetFields();
    };
    const onFinish = (values) => {
        !model ? createProductStock(values) : updateProductStock(values);
    };
    return (
        <Modal title={(createType === 'add' || createType === 'reduce') ? 'Actualizar Stock' : 'Actualizar Registro'} open={open} onCancel={onCancel} onOk={() => form.submit()}>
            <Form form={form} name="basic" layout="vertical" onFinish={onFinish} autoComplete="off">
                {(createType === 'add' || createType === 'reduce') && (
                    <Form.Item label="Stock Actual" name={'current_stock'}>
                        <Input disabled />
                    </Form.Item>
                )}
                {createType === 'add' && (
                    <Form.Item label="Agregar Stock" name="new_stock" rules={RulesHelper.positiveNumber()}>
                        <Input />
                    </Form.Item>
                )}
                {createType === 'reduce' && (
                    <Form.Item label="Quitar Stock" name="new_stock" rules={RulesHelper.positiveNumber()}>
                        <Input />
                    </Form.Item>
                )}
                <Form.Item label="Nota" name="note" rules={RulesHelper.maxNotRequired(250)}>
                    <Input.TextArea rows={5} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ProductStockModal;