import { LineChart } from "@mui/x-charts";
import { Form, Select } from "antd";
import DateUtil from "../../utils/DateUtil";

const YearlySalesChart = ({ data, isDesktop, form, registrosPorYearService }) => (
    <>
        {isDesktop && (
            <div style={{ backgroundColor: '#FFF', padding: '10px 25px' }}>
                <Form form={form} layout="vertical">
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <Form.Item label={'Año'} name={'year'}>
                            <Select options={DateUtil.GetYearsForSelect()} style={{ width: '250px' }} onChange={(value) => {
                                registrosPorYearService(value);
                            }} />
                        </Form.Item>
                    </div>
                </Form>
                <LineChart
                    height={300}
                    series={[
                        { data: data, label: `VENTAS ${form.getFieldValue('year')}`, area: true },
                    ]}
                    xAxis={[{ scaleType: 'point', data: DateUtil.GetMonthsSpanish() }]}
                />
            </div>
        )}
    </>
);

export default YearlySalesChart;