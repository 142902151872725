import BookingCalendarContainer from "../components/BookingCalendarComponents/BookingCalendarContainer";
import BookingCalendarModal from "../components/BookingCalendarComponents/BookingCalendarModal";
import { BookingCalendarProvider } from "../contexts/BookingCalendarContext";

const BookingCalendarPage = () => {
    return (
        <>
            <h1 className="TituloContenido">Calendario de reservas</h1>
            <BookingCalendarProvider>
                <BookingCalendarContainer />
                <BookingCalendarModal />
            </BookingCalendarProvider>
        </>
    );
};

export default BookingCalendarPage;