import { useContext } from "react";
import BookingActiveModal from "./BookingActiveModal";
import BookingChangeData from "./BookingChangeData";
import BookingCreate from "./BookingCreate";
import BookingFilter from "./BookingFilter";
import BookingRecords from "./BookingRecords";
import { BookingContext } from "../../contexts/BookingContext";
import { Breadcrumb, Card } from "antd";
import BookingForm from "../BookingFormComponents/BookingForm";
import { BookingFormProvider } from "../../contexts/BookingFormContext";

const BookingContainer = () => {
    const { showView, setShowView, model, getBookingsDefault } = useContext(BookingContext);
    return (
        <>
            <h1 className="TituloContenido">Reservas</h1>
            {showView === 'bookings' && (
                <>
                    <BookingChangeData />
                    <BookingCreate />
                    <Card title={'LISTA DE RESERVAS'}>
                        <BookingFilter />
                        <BookingRecords />
                    </Card>
                    <BookingActiveModal />
                </>
            )}
            {showView === 'create' && (
                <>
                    <Breadcrumb
                        style={{ marginBottom: '20px' }}
                        items={[
                            {
                                title: <div style={{ cursor: 'pointer' }}>Reservas</div>,
                                onClick: () => {
                                    setShowView('bookings');
                                    getBookingsDefault();
                                }
                            },
                            {
                                title: <div style={{ cursor: 'default' }}>Nueva Reservar</div>,
                            },
                        ]}
                    />
                    <BookingFormProvider booking={model} onBack={() => {
                        setShowView('bookings');
                        getBookingsDefault();
                    }}>
                        <BookingForm />
                    </BookingFormProvider>
                </>
            )}
        </>
    );
};

export default BookingContainer;