import { Button } from "antd";
import { ProductStockContext } from "../../contexts/ProductStockContext";
import { useContext } from "react";

export const ProductStockCreate = () => {
    const { form, setOpen, setModel, setCreateType, currentRecord } = useContext(ProductStockContext);
    const onClick = (type) => {
        setCreateType(type);
        setModel(null);
        form.resetFields();
        form.setFieldsValue({ current_stock: currentRecord.stock });
        setOpen(true);
    };
    return (
        <div style={{ marginBottom: '10px', textAlign: 'end' }}>
            <div style={{ display: 'flex', gap: '10px', justifyContent: 'end' }}>
                <Button type="primary" onClick={() => { onClick('add') }}>Agregar Stock</Button>
                <Button danger type="primary" onClick={() => { onClick('reduce') }}>Quitar Stock</Button>
            </div>
        </div>
    );
};