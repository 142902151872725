import ApiService from "./ApiService";

class SettingService {
    getList(params) {
        return ApiService.get('/settings', params, true);
    }
    update(id, body) {
        return ApiService.patch(`/settings/${id}`, body, true);
    }
}

export default new SettingService();