import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import RoleKeyHelper from "../../helpers/RoleKeyHelper";
import { Modal } from "antd";

const UserActiveModal = () => {
    const { switchDataType, openDelete, model, setOpenDelete, setModel, updateUserActive } = useContext(UserContext);
    const onCancel = () => {
        setModel(null);
        setOpenDelete(false);
    };
    const onOk = () => {
        updateUserActive(!switchDataType);
    };
    return (model && (
        <Modal title={switchDataType ? 'Eliminar usuario' : 'Recuperar usuario'} open={openDelete} onCancel={onCancel} onOk={onOk}>
            <p>¿Está seguro que quiere {switchDataType ? 'eliminar' : 'recuperar'} a {model.full_name} con rol {RoleKeyHelper.getDescription(model.role)}?</p>
        </Modal>
    ));
};

export default UserActiveModal;