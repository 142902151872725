class RoleKeyHelper {
    getAdminKey() {
        return 'ADMINISTRADOR';
    }
    getHotelReceptionist() {
        return 'RECEPCIONISTA_HOTEL';
    }
    getLaundryReceptionist() {
        return 'RECEPCIONISTA_LAVANDERIA';
    }
    getHotelCleaningStaff() {
        return 'LIMPIEZA_HOTEL';
    }
    getAllKeys() {
        return [
            this.getAdminKey(),
            this.getHotelReceptionist(),
            this.getLaundryReceptionist(),
            this.getHotelCleaningStaff()
        ];
    }
    getDescription(role) {
        switch (role) {
            case this.getAdminKey(): {
                return 'Administrador';
            }
            case this.getHotelReceptionist(): {
                return 'Recepcionista Hotel';
            }
            case this.getLaundryReceptionist(): {
                return 'Recepcionista Lavanderia';
            }
            case this.getHotelCleaningStaff(): {
                return 'Limpieza Hotel';
            }
            default: {
                return 'SIN ROL';
            }
        }
    }
    getSelectOptions() {
        return [
            { label: this.getDescription(this.getAdminKey()), value: this.getAdminKey() },
            { label: this.getDescription(this.getHotelReceptionist()), value: this.getHotelReceptionist() },
            { label: this.getDescription(this.getLaundryReceptionist()), value: this.getLaundryReceptionist() },
            { label: this.getDescription(this.getHotelCleaningStaff()), value: this.getHotelCleaningStaff() },
        ]
    }
}

export default new RoleKeyHelper();