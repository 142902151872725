import { createContext, useEffect, useState } from "react";
import ConsumptionService from "../services/ConsumptionService";
import { message } from "antd";
import SaleService from "../services/SaleService";

export const ConsumptionContext = createContext();

export const ConsumptionProvider = ({ children, customer_account_id }) => {

    const [data, setData] = useState([]);

    const [modalSale, setModalSale] = useState(false);

    const [modelDetail, setModelDetail] = useState(null);

    const [openDetailModal, setOpenDetailModal] = useState(false);

    const [customerAccountModel, setCustomerAccountModel] = useState(null);

    const [showView, setShowView] = useState('consumptions');

    const [consumptionsSelected, setConsumptionsSelected] = useState([]);

    const [openDisableModal, setOpenDisableModal] = useState(false);

    const [disableModel, setDisableModel] = useState(null);

    const [consumptionModel, setConsumptionModel] = useState(null);

    const [openDisableLaundry, setOpenDisableLaundry] = useState(false);

    const getDataDefault = () => {
        ConsumptionService.getListByCustomerAccountId({ customer_account_id }).then(response => {
            setData(response.data);
            setCustomerAccountModel(response.customer_account);
        }).catch(err => {
            message.error('Ocurrió un error en el servicio.');
        });
    };

    useEffect(() => {
        if (customer_account_id) {
            getDataDefault();
        }
    }, []);

    const createSaleByConsumptions = (values) => {

        let tempConsumptionsSelected = [...consumptionsSelected];

        if (tempConsumptionsSelected.length === 0) return message.warning('Por favor, selecciona un consumo.');

        tempConsumptionsSelected = tempConsumptionsSelected.filter(item => item.paid !== 1);

        const consumption_ids = tempConsumptionsSelected.map(item => item.consumption_id).join(',');

        SaleService.createByConsumptions({
            ...values,
            customer_account_id,
            consumption_ids
        }).then(response => {
            message.success('Se registró la venta.');
            getDataDefault();
            setModalSale(false);
            setConsumptionsSelected([]);
        }).catch(error => {
            console.log(error);
            message.error('Ocurrió un error con el servicio.');
        });

    };

    const disableConsumption = () => {

        ConsumptionService.disable(disableModel.consumption_id).then(response => {
            message.success('Se eliminó el consumo.');
            getDataDefault();
            setOpenDisableModal(false);
            setDisableModel(null);
        }).catch(error => {
            console.log(error);
            message.error('Ocurrió un error con el servicio.');
        });

    };

    const disableLaundry = () => {

        ConsumptionService.disableLaundryConsumption(disableModel.consumption_id).then(response => {
            message.success('Se eliminó el consumo.');
            getDataDefault();
            setOpenDisableLaundry(false);
            setDisableModel(null);
        }).catch(error => {
            console.log(error);
            message.error('Ocurrió un error con el servicio.');
        });

    };

    return (
        <ConsumptionContext.Provider value={{
            data,
            openDetailModal, setOpenDetailModal,
            modelDetail, setModelDetail,
            showView, setShowView,
            customerAccountModel, getDataDefault,
            consumptionsSelected, setConsumptionsSelected,
            createSaleByConsumptions,
            modalSale, setModalSale,
            openDisableModal, setOpenDisableModal,
            disableModel, setDisableModel,
            disableConsumption,
            consumptionModel, setConsumptionModel,
            openDisableLaundry, setOpenDisableLaundry,
            disableLaundry
        }}>
            {children}
        </ConsumptionContext.Provider >
    )
};