import GeneralHelper from "../helpers/GeneralHelper";
import ApiService from "./ApiService";

class GuestService {

    static pagination(params, page_number, page_size, guest_active) {
        return ApiService.get('/guests', { ...params, page_number, page_size, guest_active }, true);
    }

    static create(body) {
        return ApiService.post('/guests', { ...body, type: 'by_self', created_at: GeneralHelper.getLimaPeruTime(), with_sale: false }, true);
    }

    static createWithSale(body) {
        return ApiService.post('/guests', { ...body, type: 'by_self', created_at: GeneralHelper.getLimaPeruTime(), with_sale: true }, true);
    }

    static createByBooking(body) {
        return ApiService.post('/guests', { ...body, type: 'by_booking', created_at: GeneralHelper.getLimaPeruTime(), with_sale: false }, true);
    }

    static createByBookingWithSale(body) {
        return ApiService.post('/guests', { ...body, type: 'by_booking', created_at: GeneralHelper.getLimaPeruTime(), with_sale: true }, true);
    }

    static updateData(id, body) {
        return ApiService.patch(`/guests/${id}`, { ...body, type: 'data', created_at: GeneralHelper.getLimaPeruTime() }, true);
    }

    static updateActive(id, active) {
        return ApiService.patch(`/guests/${id}`, { guest_active: active, type: 'active' }, true);
    }

    static updateExit(id, body) {
        return ApiService.patch(`/guests/${id}`, { type: 'exit', ...body }, true);
    }

}

export default GuestService;