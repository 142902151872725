import { useContext } from "react";
import { Modal } from "antd";
import DateUtil from "../../utils/DateUtil";
import { BookingContext } from "../../contexts/BookingContext";

const BookingActiveModal = () => {
    const { switchDataType, openDelete, model, setOpenDelete, setModel, updateBookingActive } = useContext(BookingContext);
    const onCancel = () => {
        setModel(null);
        setOpenDelete(false);
    };
    const onOk = () => {
        updateBookingActive(!switchDataType);
    };
    return (
        model &&
        <Modal title={`${switchDataType ? 'Eliminar' : 'Recuperar'} reserva`} open={openDelete} onCancel={onCancel} onOk={onOk}>
            <p>¿Está seguro que quiere {switchDataType ? 'eliminar' : 'recuperar'} la reserva de {model.name} {model.lastname} del {DateUtil.ToSimpleString(model.start_date)} al {DateUtil.ToSimpleString(model.end_date)} en la habitación {model.room_number}?</p>
        </Modal>
    );
};

export default BookingActiveModal;