import { createContext, useEffect, useState } from "react";
import EnvConfig from "../config/EnvConfig";
import LocalStorageHelper from "../helpers/LocalStorageHelper";

export const LaundryMonitoringContext = createContext();

export const LaundryMonitoringProvider = ({ children }) => {

    const [websocketData, setWebsocketData] = useState({});

    const [data, setData] = useState([]);

    const [showView, setShowView] = useState('laundry_monitoring_records');

    const [laundryRecordModel, setLaundryRecordModel] = useState(null);

    useEffect(() => {

        const ws = new WebSocket(EnvConfig.getWebsocketUrl());

        ws.onopen = () => {
            const accessToken = LocalStorageHelper.getAccessTokenData();
            const service = 'SERVICE_MONITORING_LAUNDRY';
            ws.send(JSON.stringify({ accessToken, service }));
        };

        ws.onmessage = (event) => {
            const { data } = event;
            const json_data = JSON.parse(data);
            setWebsocketData(json_data);
        };

        return () => {
            if (ws) ws.close();
        };

    }, []);

    useEffect(() => {

        const { event } = websocketData;

        if (event === "MONITORING_LAUNDRY_DATA") setData(websocketData.data);

    }, [websocketData]);

    return (
        <LaundryMonitoringContext.Provider value={{
            data,
            showView, setShowView,
            laundryRecordModel, setLaundryRecordModel
        }}>
            {children}
        </LaundryMonitoringContext.Provider>
    );
}