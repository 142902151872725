import GeneralHelper from "../helpers/GeneralHelper";
import ApiService from "./ApiService";

class CustomerAccountService {

    pagination(params, page_number, page_size, active) {
        return ApiService.get('/customerAccounts', { ...params, page_number, page_size, active }, true);
    }

    createLaundryConsumption(id, body) {

        return ApiService.post(`/customerAccounts/${id}/create/laundry-consumption`, {
            ...body,
            created_at: GeneralHelper.getLimaPeruTime()
        }, true);

    }
}

export default new CustomerAccountService();