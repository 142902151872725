import { Button } from "antd";
import { useContext } from "react";
import { ConsumptionContext } from "../../contexts/ConsumptionContext";

const ConsumptionButtonsCreate = () => {

    const { setShowView } = useContext(ConsumptionContext);

    const handlerAddProductConsumption = () => {
        setShowView('product');
    };

    const handlerAddLaundryConsumption = () => {
        setShowView('laundry');
    };

    return (
        <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', marginBottom: '20px' }}>
            <Button onClick={handlerAddProductConsumption}>Agregar consumo de producto</Button>
            <Button onClick={handlerAddLaundryConsumption}>Agregar consumo de lavandería</Button>
        </div>
    );

};

export default ConsumptionButtonsCreate;