import { useContext } from "react";
import { ConsumptionProductContext } from "../../contexts/ConsumptionProductContext";
import { Descriptions } from "antd";

const ConsumptionProductDescription = () => {

    const { items } = useContext(ConsumptionProductContext);

    return (<Descriptions items={items} bordered />);

};

export default ConsumptionProductDescription;