import dayjs from "dayjs";
import GeneralHelper from "./GeneralHelper";
import LocalStorageHelper from "./LocalStorageHelper";

class TokenHelper {

    getTokenTimeRemaining(token_expiration) {
        const token_datetime = dayjs(token_expiration);
        const now_datetime = dayjs(GeneralHelper.getLimaPeruTime());
        const milliseconds = token_datetime.diff(now_datetime, 'millisecond');
        return milliseconds;
    }

    checkTokenExpiration() {

        const accessTokenExpiration = LocalStorageHelper.getAccessTokenExpirationData();
        const refreshTokenExpiration = LocalStorageHelper.getRefreshTokenExpirationData();

        if (!accessTokenExpiration || !refreshTokenExpiration) {
            return 'logout';
        }

        const refreshTokenMs = this.getTokenTimeRemaining(refreshTokenExpiration);

        if (refreshTokenMs <= 0) {
            return 'logout';
        }

        const accessTokenMs = this.getTokenTimeRemaining(accessTokenExpiration);

        if (accessTokenMs <= 0) {
            return 'logout';
        }

        if (accessTokenMs <= 5000) {
            return 'update';
        }

        return 'active';

    }
}

export default new TokenHelper(); 