class LocalStorageHelper {

    constructor() {
        this.keyTokenId = 'token_id';
        this.keyRefreshTokenExpiration = 'refresh_token_expiration';
        this.keyAccessTokenExpiration = 'access_token_expiration';
        this.keyAccessToken = 'access_token';
        this.keyRefreshToken = 'refresh_token';
        this.keyUser = 'user';
    }

    updateTokenId(value) {
        return localStorage.setItem(this.keyTokenId, value);
    }

    getAccessToken() {
        return 'access_token';
    }
    getRefreshToken() {
        return 'refresh_token';
    }
    getAccessTokenExpiration() {
        return 'access_token_expiration';
    }
    getRefreshTokenExpiration() {
        return 'refresh_token_expiration';
    }
    getRoute() {
        return 'web_route';
    }
    getDataByKey(key) {
        return localStorage.getItem(key);
    }
    setDataByKey(key, value) {
        return localStorage.setItem(key, value);
    }
    getTokenId() {
        return localStorage.getItem(this.keyTokenId);
    }
    getRouteData() {
        return this.getDataByKey(this.getRoute());
    }
    getUserData() {
        return this.getDataByKey(this.keyUser);
    }
    getAccessTokenData() {
        return this.getDataByKey(this.getAccessToken());
    }
    getRefreshTokenData() {
        return this.getDataByKey(this.getRefreshToken());
    }
    getAccessTokenExpirationData() {
        return localStorage.getItem(this.keyAccessTokenExpiration);
    }
    getRefreshTokenExpirationData() {
        return localStorage.getItem(this.keyRefreshTokenExpiration);
    }
    updateRoute(route) {
        this.setDataByKey(this.getRoute(), route);
    }
    updateUser(user) {
        this.setDataByKey(this.keyUser, user);
    }
    updateAccessToken(accessToken) {
        this.setDataByKey(this.getAccessToken(), accessToken);
    }
    updateRefreshToken(refreshToken) {
        this.setDataByKey(this.getRefreshToken(), refreshToken);
    }
    updateAccessTokenExpiration(accessTokenExpiration) {
        this.setDataByKey(this.getAccessTokenExpiration(), accessTokenExpiration);
    }
    updateRefreshTokenExpiration(refreshTokenExpiration) {
        this.setDataByKey(this.getRefreshTokenExpiration(), refreshTokenExpiration);
    }
}

export default new LocalStorageHelper();