import { useContext } from "react";
import { RoomContext } from "../../contexts/RoomContext";
import { Button } from "antd";

const RoomCreate = () => {
    const { form, setOpen, setModel } = useContext(RoomContext);
    const onClick = () => {
        setModel(null);
        form.resetFields();
        setOpen(true);
    };
    return (
        <div style={{ textAlign: "right", marginBottom: '10px' }}>
            <Button type="primary" onClick={onClick}>Nuevo registro</Button>
        </div>
    );
};

export default RoomCreate;