import dayjs from "dayjs";
import ValidationHelper from "../helpers/ValidationHelper";

class DateUtil {

    ToString(datetime) {
        const firstValidation = ValidationHelper.hasData(datetime);
        if (!firstValidation) return '';
        const secondValidation = ValidationHelper.isValidDateTime(datetime);
        if (!secondValidation) return '';
        const dayjs_object = dayjs(datetime);
        return dayjs_object.format('DD/MM/YYYY hh:mm:ss a');
    }

    ToSimpleString(datetime) {
        const firstValidation = ValidationHelper.hasData(datetime);
        if (!firstValidation) return '';
        const dayjs_object = dayjs(datetime);
        return dayjs_object.format('DD/MM/YYYY hh:mm:ss a');
    }

    ToServerString(datetime) {
        const firstValidation = ValidationHelper.hasData(datetime);
        if (!firstValidation) return '';
        const secondValidation = ValidationHelper.isValidDateTime(datetime);
        if (!secondValidation) return '';
        const dayjs_object = dayjs(datetime);
        return dayjs_object.format('YYYY-MM-DD HH:mm:ss');
    }

    ToServerDateString(datetime) {
        const firstValidation = ValidationHelper.hasData(datetime);
        if (!firstValidation) return '';
        const secondValidation = ValidationHelper.isValidDateTime(datetime);
        if (!secondValidation) return '';
        const dayjs_object = dayjs(datetime);
        return dayjs_object.format('YYYY-MM-DD');
    }

    ToServerEndString(datetime) {
        const firstValidation = ValidationHelper.hasData(datetime);
        if (!firstValidation) return '';
        const secondValidation = ValidationHelper.isValidDateTime(datetime);
        if (!secondValidation) return '';
        const dayjs_object = dayjs(datetime);
        return dayjs_object.format('YYYY-MM-DD 23:59:59');
    }

    GetMonthsSpanish() {

        const months = [];

        for (let i = 0; i < 12; i++) {
            const month = dayjs().month(i).format('MMMM');
            const firstLetter = month.charAt(0).toUpperCase();
            months.push(`${firstLetter}${month.slice(1)}`);
        }

        return months;

    }

    GetMonthsForSelect() {

        const months = [];

        for (let i = 0; i < 12; i++) {
            const month = dayjs().month(i).format('MMMM');
            const firstLetter = month.charAt(0).toUpperCase();
            months.push({
                label: `${firstLetter}${month.slice(1)}`,
                value: i + 1
            });
        }

        return months;

    }

    GetYearsForSelect() {

        const years = [];

        const year_range = 5;

        const current_year = dayjs().year();

        const year = current_year - year_range;

        for (let index = 1; index < year_range * 2; index++) {
            const year_value = year + index;
            years.push({ label: year_value, value: year_value });
        }

        return years;

    }

    GetMonthName(month) {
        if (month === undefined) return '';
        const date = dayjs().month(Number(month) - 1);
        return date.format('MMMM').toUpperCase();
    }

    GeyDaysByMonthSelect(month, year) {

        const date = dayjs(`${year}-${month}-01`);

        const count = date.daysInMonth();

        const days = [];

        for (let index = 1; index <= count; index++) {
            days.push(index);
        }

        return days;

    }

    GetMonthsAndYearSpanishCurrent() {

        const monthsAndYear = [];
        const now = dayjs();

        for (let i = 0; i < 12; i++) {
            const monthAndYear = now.add(i, 'month').format('MMMM YYYY');
            const formatMonthAndYear = monthAndYear.charAt(0).toUpperCase() + monthAndYear.slice(1);
            monthsAndYear.push(formatMonthAndYear);
        }

        return monthsAndYear;

    }
}

export default new DateUtil();