import { Layout, Spin } from "antd";
import { useContext } from "react";
import { LayoutContext } from "../../contexts/LayoutContext";
import { useMediaQuery } from "react-responsive";
import LayoutSider from "./LayoutSider";
import LayoutHeader from "./LayoutHeader";

const LayoutContainer = ({ children }) => {
    const { collapseMenu, loading, showLayout } = useContext(LayoutContext);
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return (
        <>
            {
                loading ?
                    <div className="LayoutPageLoading fade-in">
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ marginBottom: '20px' }}>
                                <Spin size="large" />
                            </div>
                            <div style={{ color: '#FFF' }}>
                                Cargando, por favor espere...
                            </div>
                        </div>
                    </div>
                    :
                    <Layout className="fade-in" style={{ minHeight: '100vh', opacity: showLayout ? 1 : 0 }}>
                        <LayoutSider />
                        <Layout>
                            <LayoutHeader />
                            <Layout.Content style={{
                                paddingTop: isMobile ? '50px' : '20px',
                                paddingLeft: isMobile ? '10px' : '30px',
                                paddingRight: isMobile ? '10px' : '30px'
                            }}>
                                <div className="fade-in" style={{ display: (!collapseMenu && isMobile) ? 'none' : 'initial' }}>
                                    {children}
                                </div>
                            </Layout.Content>
                            <div className="fade-in" style={{ display: (!collapseMenu && isMobile) ? 'none' : 'initial' }}>
                                <Layout.Footer style={{
                                    textAlign: 'center',
                                    paddingTop: isMobile ? '20px' : '24px',
                                    paddingBottom: isMobile ? '20px' : '24px',
                                    paddingRight: isMobile ? '0' : '0',
                                    paddingLeft: isMobile ? '0' : '0'
                                }}>
                                    Hotel Admin ©{new Date().getFullYear()}
                                </Layout.Footer>
                            </div>
                        </Layout>
                    </Layout>
            }
        </>
    );
};

export default LayoutContainer;