import { useContext } from "react";
import GuestActiveModal from "./GuestActiveModal";
import GuestCreate from "./GuestCreate";
import GuestFilter from "./GuestFilter";
import GuestRecords from "./GuestRecords";
import { GuestContext } from "../../contexts/GuestContext";
import { Breadcrumb, Card } from "antd";
import { GuestFormProvider } from "../../contexts/GuestFormContext";
import GuestForm from "../GuestFormComponents/GuestForm";
import GuestSaleModal from "../GuestFormComponents/GuestSaleModal";
import GuestExitModal from "./GuestExitModal";

const GuestContainer = () => {

    const { showView, setShowView, model, getGuestsDefault } = useContext(GuestContext);

    return (
        <>
            <h1 className="TituloContenido">Huespedes</h1>

            {showView === 'guests' && (
                <>
                    <GuestCreate />
                    <Card title={'LISTA DE HUESPEDES'}>
                        <GuestFilter />
                        <GuestRecords />
                        <GuestExitModal />
                    </Card>
                    <GuestActiveModal />
                </>
            )}

            {showView === 'create' && (
                <>
                    <Breadcrumb
                        style={{ marginBottom: '20px' }}
                        items={[
                            {
                                title: <div style={{ cursor: 'pointer' }}>Huespedes</div>,
                                onClick: () => {
                                    setShowView('guests');
                                    getGuestsDefault();
                                }
                            },
                            {
                                title: <div style={{ cursor: 'default' }}>{model ? 'Editar' : 'Crear'} Huesped</div>,
                            },
                        ]}
                    />
                    <GuestFormProvider guest={model} onBack={() => {
                        setShowView('guests');
                        getGuestsDefault();
                    }}>
                        <GuestForm />
                        <GuestSaleModal />
                    </GuestFormProvider>
                </>
            )}

        </>
    );
};

export default GuestContainer;