import { useContext } from "react";
import { CleaningMonitoringContext } from "../../contexts/CleaningMonitoringContext";
import CleaningMonitoringRecords from "./CleaningMonitoringRecords";
import { Breadcrumb, Card } from "antd";
import { CleaningMonitoringFormProvider } from "../../contexts/CleaningMonitoringFormContext";
import CleaningMonitoringFormDetail from "../CleaningMonitoringFormComponents/CleaningMonitoringFormDetail";
import CleaningMonitoringForm from "../CleaningMonitoringFormComponents/CleaningMonitoringForm";
import CleaningMonitoringFilter from "./CleaningMonitoringFilter";

const CleaningMonitoringContainer = () => {

    const {
        showView, setShowView,
        updateStatusModel, setUpdateStatusModel
    } = useContext(CleaningMonitoringContext);

    return (
        <>
            {showView === 'cleaning_monitoring_records' && (
                <>
                    <h1 className="TituloContenido">Monitoreo Limpieza</h1>
                    <Card>
                        {/* <CleaningMonitoringFilter /> */}
                        <CleaningMonitoringRecords />
                    </Card>
                </>
            )}

            {showView === 'cleaning_monitoring_form' && (
                <>
                    <Breadcrumb
                        style={{ marginBottom: '20px' }}
                        items={[
                            {
                                title: <div style={{ cursor: 'pointer' }}>Monitoreo Limpieza</div>,
                                onClick: () => {
                                    setShowView('cleaning_monitoring_records');
                                }
                            },
                            {
                                title: <div style={{ cursor: 'default' }}>Actualización de registro de limpieza</div>,
                            },
                        ]}
                    />
                    <CleaningMonitoringFormProvider
                        room_cleaning_record={updateStatusModel}
                        type_form={'cleaner'}
                        onBack={() => {
                            setShowView('cleaning_monitoring_records');
                            setUpdateStatusModel(null);
                        }}
                    >
                        <CleaningMonitoringFormDetail />
                        <CleaningMonitoringForm />
                    </CleaningMonitoringFormProvider>
                </>
            )}
        </>
    );

}

export default CleaningMonitoringContainer;