import { createContext, useEffect, useState } from "react";
import GeneralHelper from "../helpers/GeneralHelper";
import GuestService from "../services/GuestService";
import { Form, message } from "antd";
import { useNavigate } from "react-router-dom";
import RoutePathHelper from "../helpers/RoutePathHelper";

export const GuestContext = createContext();

export const GuestProvider = ({ children }) => {

    const navigate = useNavigate();

    const [showView, setShowView] = useState('guests');

    const [data, setData] = useState([]);

    const [model, setModel] = useState(null);

    const [openDelete, setOpenDelete] = useState(false);

    const [switchDataType, setSwitchDataType] = useState(true);

    const [tableParams, setTableParams] = useState(GeneralHelper.getTablePaginationDefault);

    const [exitModal, setExitModal] = useState({ open: false, model: null });

    const [formFilters, setFormFilters] = useState({
        full_name: '',
        company: '',
        document_type: '',
        document_number: '',
        start_date: '',
        end_date: '',
        room_code: '',
        guest_status: 'HOSPEDADO'
    });

    const [formSale] = Form.useForm();

    const exitService = (values) => {
        GuestService.updateExit(exitModal.model.id, values).then(response => {
            message.success('Se registró la salida del huesped.');
            setExitModal({ open: false, model: null });
            formSale.resetFields();
            getGuestsDefault();
        }).catch(error => {
            console.log('Ocurrio un error con el servicio.', error);
        });
    };

    const getGuestsDefault = () => {
        GuestService.pagination(formFilters, tableParams.pagination.current, tableParams.pagination.pageSize, switchDataType).then(response => {
            setData(response.data);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    total: response.count
                }
            });
        }).catch(error => {
            console.log(error);
            message.error('Ocurrió un error con el servicio.');
        });
    };

    const getGuestsByFilter = (values) => {
        GuestService.pagination(values, 1, tableParams.pagination.pageSize, switchDataType).then(response => {
            setData(response.data);
            setFormFilters(values);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    total: response.count,
                    current: 1,
                }
            });
        }).catch(error => {
            console.log(error);
            message.error('Ocurrió un error con el servicio.');
        });
    };

    const getGuestsByPagination = (page, pageSize) => {
        GuestService.pagination(formFilters, page, pageSize, switchDataType).then(response => {
            setData(response.data);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    pageSize: pageSize,
                    current: page,
                }
            });
        }).catch(error => {
            console.log(error);
            message.error('Ocurrió un error con el servicio.');
        });
    };

    const updateGuestActive = (value) => {
        GuestService.updateActive(model.id, value).then(response => {
            getGuestsDefault();
            setOpenDelete(false);
            setModel(null);
            message.success(`Se ${value ? 'recuperó' : 'eliminó'} el registro.`);
        }).catch(error => {
            console.log(error);
            message.error('Ocurrió un error en el servicio.');
        });
    };

    const getMenu = (value) => {

        const menu = { items: [] };

        if (switchDataType) {

            const optionEdit = {
                key: 'edit', label: 'Editar', onClick: () => {
                    setModel(value);
                    setShowView('create');
                }
            };

            const optionDelete = {
                key: 'delete', label: 'Eliminar', onClick: () => {
                    setModel(value);
                    setOpenDelete(true);
                }
            };

            const optionConsumptions = {
                key: 'consumption', label: 'Ver Consumos', onClick: () => {
                    navigate(RoutePathHelper.getConsumptionByCustomerAccountId(value.customer_account_id));
                }
            };

            const optionExit = {
                key: 'exit', label: 'Registrar Salida', onClick: () => {
                    setExitModal({ open: true, model: value });
                    formSale.resetFields();
                }
            };

            if (value.guest_status !== 'SALIDA') {
                menu.items.push(optionEdit);
            }

            menu.items.push(optionExit);
            menu.items.push(optionConsumptions);
            menu.items.push(optionDelete);

        } else {

            const optionEnable = {
                key: 'enable', label: 'Recuperar', onClick: () => {
                    setModel(value);
                    setOpenDelete(true);
                }
            };

            menu.items.push(optionEnable);

        }

        return menu;

    };

    useEffect(() => {
        getGuestsDefault();
    }, [switchDataType]);

    return (
        <GuestContext.Provider value={{
            openDelete, setOpenDelete,
            data, setData,
            tableParams, setTableParams,
            formFilters, setFormFilters,
            model, setModel,
            switchDataType, setSwitchDataType,
            showView, setShowView,
            getGuestsDefault, getGuestsByFilter, getGuestsByPagination, updateGuestActive,
            getMenu,
            formSale, exitModal, setExitModal, exitService
        }}>
            {children}
        </GuestContext.Provider>
    );

};