import { Form, message } from "antd";
import { createContext, useEffect, useState } from "react";
import RoomService from "../services/RoomService";
import ServiceError from "../errors/ServiceError";
import GeneralHelper from "../helpers/GeneralHelper";

export const RoomContext = createContext();

export const RoomProvider = ({ children }) => {

    const [form] = Form.useForm();

    const [switchDataType, setSwitchDataType] = useState(true);

    const [data, setData] = useState([]);

    const [model, setModel] = useState(null);

    const [open, setOpen] = useState(false);

    const [openDelete, setOpenDelete] = useState(false);

    const [tableParams, setTableParams] = useState(GeneralHelper.getTablePaginationDefault());

    const [formFilters, setFormFilters] = useState({ room_number: '', room_type: '' });

    const [showView, setShowView] = useState('rooms');

    const getRoomsDefault = () => {
        RoomService.pagination({
            room_number: formFilters.room_number,
            room_type: formFilters.room_type
        }, tableParams.pagination.current, tableParams.pagination.pageSize, switchDataType).then(response => {
            setData(response.data);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    total: response.count
                }
            });
        }).catch(err => {
            message.error('Ocurrion un error con el servicio.');
        });
    };
    const getRoomsByFilter = (values) => {
        RoomService.pagination(values, 1, tableParams.pagination.pageSize, switchDataType).then(response => {
            setData(response.data);
            setFormFilters(values);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    total: response.count,
                    current: 1,
                }
            });
        }).catch(err => {
            message.error('Ocurrion un error con el servicio.');
        });
    };
    const getRoomsByPagination = (page, pageSize) => {
        RoomService.pagination({
            room_number: formFilters.room_number,
            room_type: formFilters.room_type,
        }, page, pageSize, switchDataType).then(response => {
            setData(response.data);
            setTableParams({
                ...tableParams, pagination: {
                    ...tableParams.pagination,
                    pageSize: pageSize,
                    current: page,
                }
            });
        }).catch(err => {
            message.error('Ocurrion un error con el servicio.');
        });
    };
    const createRoom = (values) => {
        RoomService.create(values).then(response => {
            getRoomsDefault();
            setOpen(false);
            setModel(null);
            form.resetFields();
            message.success('Se agregó la habitación.');
        }).catch((err) => {
            if (err instanceof ServiceError) {
                if (err.statusCode === 422) {
                    message.warning('Existe una habitación con el número.');
                } else {
                    message.error('Ocurrion un error con el servicio.');
                }
            }
        });
    };
    const updateRoom = (values) => {
        RoomService.update(model.id, values).then(response => {
            getRoomsDefault();
            setOpen(false);
            form.resetFields();
            setModel(null);
            message.success('Se actualizó la habitación.');
        }).catch(err => {
            message.error('Ocurrion un error con el servicio.');
        });
    };
    const updateRoomActive = (value) => {
        RoomService.updateActive(model.id, value).then(response => {
            getRoomsDefault();
            setOpenDelete(false);
            setModel(null);
            message.success(`Se ${value ? 'recuperó' : 'eliminó'} el registro.`);
        }).catch(err => {
            message.error('Ocurrion un error con el servicio.');
        });
    };
    useEffect(() => {
        getRoomsDefault();
    }, [switchDataType]);
    return (
        <RoomContext.Provider value={{
            form,
            openDelete, setOpenDelete,
            data, setData,
            tableParams, setTableParams,
            formFilters, setFormFilters,
            model, setModel,
            switchDataType, setSwitchDataType,
            open, setOpen,
            getRoomsDefault, getRoomsByFilter, getRoomsByPagination,
            createRoom, updateRoom, updateRoomActive,
            showView, setShowView
        }}>
            {children}
        </RoomContext.Provider>
    );
};