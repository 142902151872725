import GeneralHelper from "../helpers/GeneralHelper";
import ApiService from "./ApiService";

class SaleService {

    static detail(id) {
        return ApiService.get(`/sales/detail/${id}`, {}, true);
    }

    static pagination(params, page_number, page_size) {
        return ApiService.get('/sales', { ...params, page_number, page_size }, true);
    }

    static createByCustomerAccount(customer_account_id, body) {
        return ApiService.post('/sales', {
            ...body,
            customer_account_id,
            type: 'by_customer_account',
            created_at: GeneralHelper.getLimaPeruTime()
        }, true);
    }

    static createByConsumptions(body) {
        return ApiService.post('/sales', {
            ...body,
            type: 'by_consumption',
            created_at: GeneralHelper.getLimaPeruTime()
        }, true);
    }

    static registrosPorMes(month, year) {
        return ApiService.get(`/sales/data-grafico-month/${month}/${year}`, {}, true);
    }

    static registrosPorYear(year) {
        return ApiService.get(`/sales/data-grafico-year/${year}`, {}, true);
    }

}

export default SaleService;