import { useContext, useEffect, useState } from "react";
import { LaundryRecordFormContext } from "../../contexts/LaundryRecordFormContext";
import DateUtil from "../../utils/DateUtil";
import { Card, Descriptions } from "antd";

const LaundryRecordFormDetail = () => {

    const { laundry_record } = useContext(LaundryRecordFormContext);

    const [items, setItems] = useState([]);

    useEffect(() => {

        if (laundry_record) {

            const dataDefault = [
                { key: 1, label: 'Cliente', children: `${laundry_record.customer_full_name}` },
                { key: 2, label: 'Peso', children: `${laundry_record.laundry_weight}` },
                { key: 3, label: 'Registrado', children: `${DateUtil.ToSimpleString(laundry_record.laundry_created_at)}` }
            ];

            setItems(dataDefault);

        }

    }, [laundry_record]);

    return (
        <>
            {items.length > 0 &&
                <div style={{ marginBottom: '20px' }}>
                    <Card bordered={false} title={'DETALLE DE SOLICITUD DE LAVADO'}>
                        <Descriptions items={items} bordered />
                    </Card>
                </div>
            }
        </>
    );

};

export default LaundryRecordFormDetail;