import { LayoutProvider } from "../contexts/LayoutContext";
import LayoutContainer from "../components/LayoutComponents/LayoutContainer";

const AppLayout = ({ children }) => (
    <LayoutProvider>
        <LayoutContainer>
            {children}
        </LayoutContainer>
    </LayoutProvider>
);

export default AppLayout;