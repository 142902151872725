import { Button, Form, Input, Select } from "antd";
import RoomTypeHelper from "../../helpers/RoomTypeHelper";
import { RoomContext } from "../../contexts/RoomContext";
import { useContext } from "react";
import ParseHelper from "../../helpers/ParseHelper";

const RoomFilter = () => {

    const { getRoomsByFilter } = useContext(RoomContext);

    const onFinish = (values) => {
        const room_number = ParseHelper.parseString(values.room_number);
        const room_type = ParseHelper.parseString(values.room_type);
        getRoomsByFilter({ room_number, room_type });
    };

    return (
        <Form
            className="FilterContainer"
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item name={"room_number"} label={"Filtro por número"}>
                <Input />
            </Form.Item>
            <Form.Item name={"room_type"} label={"Filtro de tipo de habitación"}>
                <Select style={{ width: '250px' }} options={RoomTypeHelper.getSelectOptions()} allowClear={true} />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">Buscar</Button>
            </Form.Item>
        </Form>
    );
};

export default RoomFilter;