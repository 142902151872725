import { useContext, useEffect, useState } from "react";
import { SaleDetailContext } from "../../contexts/SaleDetailContext";
import DateUtil from "../../utils/DateUtil";
import GeneralUtil from "../../utils/GeneralUtil";
import { Card, Descriptions } from "antd";

const SaleDetailDescription = () => {

    const { sale } = useContext(SaleDetailContext);

    const [items, setItems] = useState([]);

    useEffect(() => {
        if (sale) {

            const dataDefault = [
                { key: 1, label: 'Codigo', children: `${sale.code}` },
                { key: 2, label: 'Tipo', children: `${sale.type}` },
                { key: 3, label: 'Cliente', children: `${sale.name} ${sale.lastname}` },
                { key: 4, label: 'Tipo de transferencia', children: `${sale.type_of_transfer}` },
                { key: 5, label: 'Documento', children: `${sale.document_type} ${sale.document_number}` },
                { key: 6, label: 'Monto', children: `${GeneralUtil.getMoney(sale.total)}` },
                { key: 7, label: 'Registrado', children: `${DateUtil.ToSimpleString(sale.created_at)}` },
            ];

            if (sale.ruc && sale.razon_social) {
                dataDefault.push({ key: 7, label: 'Ruc', children: `${sale.ruc}` });
                dataDefault.push({ key: 8, label: 'Razon Social', children: `${sale.razon_social}` });
            }

            setItems(dataDefault);

        }
    }, [sale]);

    return (
        <div style={{ marginBottom: '20px' }}>
            <Card bordered={false} title={'DETALLE DE VENTA'}>
                <Descriptions items={items} bordered />
            </Card>
        </div>
    );

};

export default SaleDetailDescription;