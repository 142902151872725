import { Switch } from "antd";
import { useContext } from "react";
import { BookingContext } from "../../contexts/BookingContext";

const BookingChangeData = () => {
    const { setSwitchDataType, switchDataType } = useContext(BookingContext);
    const onChange = (value) => {
        setSwitchDataType(value);
    };
    return (
        <div style={{ textAlign: 'end', marginBottom: '10px' }}>
            <Switch checkedChildren="Activos" unCheckedChildren="Eliminados" checked={switchDataType} onChange={onChange} />
        </div>
    );
};

export default BookingChangeData;