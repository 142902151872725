import { Button, Form, Input, message, Modal, Select } from "antd";
import { GuestFormContext } from "../../contexts/GuestFormContext";
import RulesHelper from "../../helpers/RulesHelper";
import { useContext, useState } from "react";
import ParseHelper from "../../helpers/ParseHelper";
import dayjs from "dayjs";

const GuestSaleModal = () => {

    const {
        form, formSale, openSaleModal, setOpenSaleModal,
        createGuestWithSale, customerId, createGuestByBookingWithSale, booking,
        setLoading
    } = useContext(GuestFormContext);

    const [showExtraInputs, setShowExtraInputs] = useState(false);

    const onFinish = () => {

        const {
            start_day, start_time, end_day, end_time, habitacion, habitacionPrecio,
            document_type, document_number, name, lastname, gender, phone, address, company,
            guest_vehicle_plate, guest_observations
        } = form.getFieldsValue();

        const customer_data = {
            id: customerId,
            document_type, document_number, name, lastname, gender,
            phone: ParseHelper.parseStringOrNull(phone),
            address: ParseHelper.parseStringOrNull(address),
            company: ParseHelper.parseStringOrNull(company)
        };

        const start_date_dayjs = dayjs(`${start_day.format('YYYY-MM-DD')} ${start_time.format('HH:mm:00')}`);

        const end_date_dayjs = dayjs(`${end_day.format('YYYY-MM-DD')} ${end_time.format('HH:mm:00')}`);

        if (dayjs().isBefore(start_date_dayjs)) {
            message.error('Si la fecha de inicio es mayor a la fecha actual, registra una reserva.');
            return;
        }

        if (end_date_dayjs.isBefore(start_date_dayjs)) {
            message.error('La fecha y hora de salida tienen que ser después de la fecha y hora de llegada.');
            return;
        }

        const start_date = start_date_dayjs.format(`YYYY-MM-DD HH:mm:00`);

        const end_date = end_date_dayjs.format(`YYYY-MM-DD HH:mm:00`);

        const room_id = habitacion;

        const room_price = habitacionPrecio;

        if (booking) {

            const body = {
                booking_id: booking.id,
                guest_vehicle_plate,
                guest_observations
            };

            setLoading(true);

            createGuestByBookingWithSale(body);

            return;

        }

        const body = { customer_data, start_date, end_date, room_id, room_price, guest_vehicle_plate, guest_observations };

        setLoading(true);

        createGuestWithSale(body);

    };

    const onCancel = () => {
        setOpenSaleModal(false);
        formSale.resetFields();
    };

    return (
        <Modal
            title={'Registro de huesped con venta'}
            open={openSaleModal}
            footer={null}
            onCancel={onCancel}
        >
            <Form
                form={formSale}
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item name={"sale_type"} label={"Tipo de venta"} rules={RulesHelper.required()}>
                    <Select
                        placeholder='Por favor, selecciona un tipo de pago.'
                        allowClear
                        options={[
                            { label: 'Factura', value: 'FACTURA' },
                            { label: 'Boleta', value: 'BOLETA' }
                        ]}
                        onChange={(value) => {
                            setShowExtraInputs(value === 'FACTURA');
                        }}
                    />
                </Form.Item>
                <Form.Item name={"type_of_transfer"} label={"Tipo de transferencia"} rules={RulesHelper.required()}>
                    <Select
                        placeholder='Por favor, selecciona un tipo de transferencia.'
                        allowClear
                        options={[
                            { label: 'YAPE', value: 'YAPE' },
                            { label: 'PLIN', value: 'PLIN' },
                            { label: 'TRANSFERENCIA', value: 'TRANSFERENCIA' },
                            { label: 'EFECTIVO', value: 'EFECTIVO' },
                            { label: 'TARJETA DEBITO', value: 'TARJETA DEBITO' },
                            { label: 'TARJETA CREDITO', value: 'TARJETA CREDITO' }
                        ]}
                    />
                </Form.Item>
                {showExtraInputs && (
                    <>
                        <Form.Item name={'ruc'} label='RUC' rules={RulesHelper.max(11)}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={'razon_social'} label='Razón Social' rules={RulesHelper.max(250)}>
                            <Input />
                        </Form.Item>
                    </>
                )}
                <Form.Item>
                    <Button type="primary" htmlType="submit">Registrar</Button>
                </Form.Item>
            </Form>
        </Modal>
    );

};

export default GuestSaleModal;