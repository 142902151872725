import { createContext, useEffect, useState } from "react";
import GeneralHelper from "../helpers/GeneralHelper";
import BookingService from "../services/BookingService";
import { message } from "antd";

export const BookingCalendarContext = createContext();

export const BookingCalendarProvider = ({ children }) => {

    const [data, setData] = useState([]);

    const [currentDate, setCurrentDate] = useState(GeneralHelper.getDayJsLimaPeru());

    const [model, setModel] = useState(null);

    const [open, setOpen] = useState(false);

    const [showView, setShowView] = useState('calendar');

    const [modelBooking, setModelBooking] = useState(null);

    const [showDeleteBookingModal, setShowDeleteBookingModal] = useState(false);

    const getListByMonthAndYear = (values) => {

        BookingService.listByMonthAndYear(values).then(response => {
            setData(response);
        }).catch(err => {
            console.log('Ocurrio un error con el servicio.');
        });

    };

    const getDefaultData = () => {
        getListByMonthAndYear({ month: currentDate.month() + 1, year: currentDate.year() });
    };

    const deleteBooking = () => {

        BookingService.updateActive(modelBooking.id, false).then(response => {
            message.success(`Se eliminó la reserva.`);
            getDefaultData();
            setShowDeleteBookingModal(false);
            setModelBooking(null);
        }).catch(error => {
            console.log(error);
            message.error('Ocurrió un error en el servicio.');
        });

    };

    useEffect(() => {
        if (currentDate) getDefaultData();
    }, [currentDate]);

    return (
        <BookingCalendarContext.Provider value={{
            data, currentDate, setCurrentDate,
            getListByMonthAndYear,
            model, setModel,
            open, setOpen,
            showView, setShowView,
            modelBooking, setModelBooking,
            getDefaultData,
            showDeleteBookingModal, setShowDeleteBookingModal,
            deleteBooking
        }}>
            {children}
        </BookingCalendarContext.Provider>
    )
};