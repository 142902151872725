import DisponibilityRecords from "../components/MonitoringComponents/DisponibilityRecords";
import ModalExistGuest from "../components/MonitoringComponents/ModalExitGuest";
import { MonitoringProvider } from "../contexts/MonitoringContext";

const MonitoringPage = () => {
    return (
        <>
            <h1 className="TituloContenido">Monitoreo</h1>
            <MonitoringProvider>
                <DisponibilityRecords />
                <ModalExistGuest />
            </MonitoringProvider>
        </>
    );
}

export default MonitoringPage;