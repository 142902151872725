import React from "react";
import { UserProvider } from "../contexts/UserContext";
import UserChangeData from "../components/UserComponents/UserChangeData";
import UserCreate from "../components/UserComponents/UserCreate";
import UserFilter from "../components/UserComponents/UserFilter";
import UserRecords from "../components/UserComponents/UserRecords";
import UserModal from "../components/UserComponents/UserModal";
import UserPasswordModal from "../components/UserComponents/UserPasswordModal";
import UserActiveModal from "../components/UserComponents/UserActiveModal";
import { Card } from "antd";

const UserPage = () => (
    <UserProvider>
        <h1 className="TituloContenido">Usuarios</h1>
        <UserChangeData />
        <UserCreate />
        <Card title={'LISTA DE USUARIOS'}>
            <UserFilter />
            <UserRecords />
        </Card>
        <UserModal />
        <UserPasswordModal />
        <UserActiveModal />
    </UserProvider>
);

export default UserPage;