import { Modal } from "antd";
import { BookingCalendarContext } from "../../contexts/BookingCalendarContext";
import DateUtil from "../../utils/DateUtil";
import { useContext } from "react";

const BookingCalendarDelete = () => {

    const {
        modelBooking, setModelBooking,
        showDeleteBookingModal, setShowDeleteBookingModal,
        deleteBooking
    } = useContext(BookingCalendarContext);

    const onCancel = () => {
        setShowDeleteBookingModal(false);
        setModelBooking(null);
    };

    const onOk = () => {
        deleteBooking();
    };

    return (
        modelBooking &&
        <Modal
            title={`Eliminar reserva`}
            open={showDeleteBookingModal}
            onCancel={onCancel}
            onOk={onOk}
        >
            <p>¿Está seguro que quiere eliminar la reserva de
                {` ${modelBooking.name} ${modelBooking.lastname}`} del
                {` ${DateUtil.ToSimpleString(modelBooking.start_date)}`} al
                {` ${DateUtil.ToSimpleString(modelBooking.end_date)}`} en la habitación
                {` ${modelBooking.room_number}`}?
            </p>
        </Modal>
    );

};

export default BookingCalendarDelete;