import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { CustomerAccountContext } from "../../contexts/CustomerAccountContext";
import { Button, Card, Dropdown, List, Pagination, Table } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import DateUtil from "../../utils/DateUtil";
import GeneralUtil from "../../utils/GeneralUtil";
import { useNavigate } from "react-router-dom";
import RoutePathHelper from "../../helpers/RoutePathHelper";

const CustomerAccountRecords = () => {

    const navigate = useNavigate();

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const {
        data, tableParams,
        getDataByPagination
    } = useContext(CustomerAccountContext);

    const onChangeTable = (pagination, filters, sorter) => {
        getDataByPagination(pagination.current, pagination.pageSize);
    };

    const onChangeList = (page, pageSize) => {
        getDataByPagination(page, pageSize);
    };

    const onShowSizeChange = (current, size) => {
        getDataByPagination(current, size);
    };

    const getMenu = (value) => {

        const menu = {
            items: [
                {
                    key: 'consumption', label: 'Ver Consumos', onClick: () => {
                        navigate(RoutePathHelper.getConsumptionByCustomerAccountId(value.id));
                    }
                }
            ]
        };

        return menu;

    };

    return (isMobile ?
        <>
            <List
                itemLayout="vertical"
                dataSource={data}
                renderItem={(model, index) => (
                    <List.Item key={index}>
                        <Card bordered={false}>
                            <div className="RowContainer">
                                <div className="ColumnContainer">
                                    <div><strong>Código</strong>: {model.code}</div>
                                    <div><strong>Cliente</strong>: {model.name} {model.lastname}</div>
                                    <div><strong>{model.document_type}</strong>: {model.document_number}</div>
                                    {model.company && <div><strong>Empresa</strong>: {model.company}</div>}
                                    <div><strong>Monto</strong>: {model.total}</div>
                                    <div><strong>Estado</strong>: {model.paid ? 'PAGADO' : 'PENDIENTE'}</div>
                                    <div><strong>Registrado:</strong> {DateUtil.ToSimpleString(model.created_at)}</div>
                                </div>
                                <div className="ColumnContainer ColumnContainerVentas" style={{ width: '100%' }}>
                                    <Dropdown trigger={['click']} menu={getMenu(model)}>
                                        <Button icon={<SettingOutlined />}>Acciones</Button>
                                    </Dropdown>
                                </div>
                            </div>
                        </Card>
                    </List.Item>
                )}
            />
            <Pagination
                pageSize={tableParams.pagination.pageSize}
                showSizeChanger={tableParams.pagination.showSizeChanger}
                onShowSizeChange={onShowSizeChange}
                total={tableParams.pagination.total}
                pageSizeOptions={tableParams.pagination.pageSizeOptions}
                current={tableParams.pagination.current}
                onChange={onChangeList}
            />
        </> :
        <Table
            pagination={tableParams.pagination}
            bordered
            dataSource={data}
            rowKey={(record) => record.id}
            onChange={onChangeTable}
            columns={[
                { title: 'Código', width: 50, dataIndex: 'code', key: 'code' },
                {
                    title: 'Cliente', key: 'customer', render: (model) => {
                        return (
                            <>
                                <div><strong>{model.document_type}</strong>: {model.document_number}</div>
                                <div><strong>NOMBRE COMPLETO</strong>: {model.name} {model.lastname}</div>
                                {model.company && <div><strong>EMPRESA</strong>: {model.company}</div>}
                                <div><strong>REGISTRADO</strong>: {DateUtil.ToSimpleString(model.created_at)}</div>
                            </>
                        )
                    }
                },
                { title: 'Monto', width: 100, dataIndex: 'total', key: 'total', render: (value) => GeneralUtil.getMoney(value) },
                {
                    title: 'Estado', width: 100, dataIndex: 'paid', key: 'paid', render: (value) => {
                        return (<>{value ? 'PAGADO' : 'PENDIENTE'}</>)
                    }
                },
                {
                    title: 'Opciones', key: 'Acciones', width: 50, render: (value) => (
                        <Dropdown trigger={['click']} menu={getMenu(value)}>
                            <Button icon={<SettingOutlined />}>Acciones</Button>
                        </Dropdown>
                    )
                },
            ]}
        />
    );
};

export default CustomerAccountRecords;