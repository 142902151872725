import { useContext } from "react";
import { ConsumptionContext } from "../../contexts/ConsumptionContext";
import GeneralUtil from "../../utils/GeneralUtil";
import DateUtil from "../../utils/DateUtil";
import { Modal } from "antd";

const ConsumptionDisableLaundryModal = () => {

    const {
        openDisableLaundry, setOpenDisableLaundry,
        disableModel, setDisableModel,
        disableLaundry
    } = useContext(ConsumptionContext);

    const onCancel = () => {
        setDisableModel(null);
        setOpenDisableLaundry(false);
    };

    const onOk = () => {
        disableLaundry();
    };

    return (disableModel &&
        <Modal
            title={'Eliminar consumo'}
            open={openDisableLaundry}
            onCancel={onCancel}
            onOk={onOk}
        >
            <p>¿Está seguro que quiere eliminar el consumo por lavandería con monto
                {` ${GeneralUtil.getMoney(disableModel.amount)} `} registrado el
                {` ${DateUtil.ToSimpleString(disableModel.created_at)}`}?
            </p>
        </Modal>
    );

};

export default ConsumptionDisableLaundryModal;