class RoutePathHelper {
    getDashboard() {
        return '/dashboard';
    }
    getMonitoring() {
        return '/monitoreo';
    }
    getCleaningMonitoring() {
        return '/monitoreo-limpieza';
    }
    getLaundryMonitoring() {
        return '/monitoreo-lavanderia';
    }
    getUser() {
        return '/usuarios';
    }
    getRoom() {
        return '/habitaciones';
    }
    getProduct() {
        return '/productos';
    }
    getSetting() {
        return '/configuraciones';
    }
    getBookingFormUpdate() {
        return '/reservar/:bookingId';
    }
    getBooking() {
        return '/reservas';
    }
    getCustomerAccount() {
        return '/cuentas-clientes';
    }
    getConsumption() {
        return '/consumos/:customer_account_id';
    }
    getConsumptionByCustomerAccountId(customer_account_id) {
        return `/consumos/${customer_account_id}`;
    }
    getBookingCalendar() {
        return '/reservas/calendario';
    }
    getGuest() {
        return '/huespedes';
    }
    getSale() {
        return '/ventas';
    }
    getAllPaths() {
        return [
            this.getDashboard(),
            this.getMonitoring(),
            this.getCleaningMonitoring(),
            this.getLaundryMonitoring(),
            this.getUser(),
            this.getRoom(),
            this.getProduct(),
            this.getSetting(),
            this.getBooking(),
            this.getCustomerAccount(),
            this.getConsumption(),
            this.getBookingCalendar(),
            this.getGuest(),
            this.getSale()
        ];
    }
}

export default new RoutePathHelper();