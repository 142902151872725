import GeneralHelper from "../helpers/GeneralHelper";
import ApiService from "./ApiService";

class RoomService {

    pagination(params, page_number, page_size, room_active) {
        return ApiService.get('/rooms', { ...params, page_number, page_size, room_active }, true);
    }

    updateActive(id, active) {
        return ApiService.patch(`/rooms/${id}`, { room_active: active, type: 'active' }, true);
    }

    create(body) {
        return ApiService.post('/rooms', { ...body, created_at: GeneralHelper.getLimaPeruTime() }, true);
    }

    update(id, body) {
        return ApiService.patch(`/rooms/${id}`, { ...body, type: 'data' }, true);
    }

    available(params) {
        return ApiService.get('/rooms/available', params, true);
    }

    historic(params, page_number, page_size, id) {
        return ApiService.get(`/rooms/${id}/historic-cleaning`, { ...params, page_number, page_size }, true);
    }

}

export default new RoomService();