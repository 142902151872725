import { useMediaQuery } from "react-responsive";
import { UserContext } from "../../contexts/UserContext";
import { useContext } from "react";
import { Button, Card, Dropdown, List, Pagination, Table } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import DateUtil from "../../utils/DateUtil";
import RoleKeyHelper from "../../helpers/RoleKeyHelper";

const UserRecords = () => {

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const { data, getMenu, tableParams, getUsersByPagination } = useContext(UserContext);

    const onChangeTable = (pagination, filters, sorter) => {
        getUsersByPagination(pagination.current, pagination.pageSize);
    };

    const onChangeList = (page, pageSize) => {
        getUsersByPagination(page, pageSize);
    };

    const onShowSizeChange = (current, size) => {
        getUsersByPagination(current, size);
    };

    return (isMobile ?
        <>
            <List
                itemLayout="vertical"
                dataSource={data}
                renderItem={(model, index) => (
                    <List.Item>
                        <Card bordered={false}>
                            <div className="RowContainer">
                                <div className="ColumnContainer">
                                    <div><strong>Email:</strong> {model.email}</div>
                                    <div><strong>Rol: </strong> {RoleKeyHelper.getDescription(model.role)}</div>
                                    <div><strong>Usuario:</strong> {model.full_name}</div>
                                    <div><strong>Teléfono:</strong> {model.phone}</div>
                                    <div><strong>Registrado:</strong> {DateUtil.ToSimpleString(model.created_at)}</div>
                                </div>
                                <div className="ColumnContainer" style={{ width: '100%' }}>
                                    <Dropdown menu={getMenu(model)} trigger={['click']} >
                                        <Button icon={<SettingOutlined />}>Acciones</Button>
                                    </Dropdown>
                                </div>
                            </div>
                        </Card>
                    </List.Item>
                )}
            />
            <Pagination
                pageSize={tableParams.pagination.pageSize}
                showSizeChanger={tableParams.pagination.showSizeChanger}
                onShowSizeChange={onShowSizeChange}
                total={tableParams.pagination.total}
                pageSizeOptions={tableParams.pagination.pageSizeOptions}
                current={tableParams.pagination.current}
                onChange={onChangeList}
            />
        </> :
        <Table
            pagination={tableParams.pagination}
            bordered
            dataSource={data}
            rowKey={(record) => record.id}
            onChange={onChangeTable}
            columns={[
                {
                    title: 'Usuario', key: 'user', render: (model) => {
                        return (
                            <>
                                <div><strong>Email:</strong> {model.email}</div>
                                <div><strong>Usuario:</strong> {model.full_name}</div>
                                <div><strong>Teléfono:</strong> {model.phone}</div>
                                <div><strong>Registrado:</strong> {DateUtil.ToSimpleString(model.created_at)}</div>
                            </>
                        );
                    }
                },
                {
                    title: 'Rol', key: 'role', render: (model) => (RoleKeyHelper.getDescription(model.role))
                },
                {
                    title: 'Opciones', key: 'options', render: (value) => (
                        <Dropdown
                            menu={getMenu(value)}
                            trigger={['click']}
                        >
                            <Button icon={<SettingOutlined />}>Acciones</Button>
                        </Dropdown>
                    )
                },
            ]}
        />
    );
};

export default UserRecords;