import { useContext } from "react";
import { ProductContext } from "../../contexts/ProductContext";
import { Breadcrumb, Card } from "antd";
import ProductChangeData from "./ProductChangeData";
import ProductCreate from "./ProductCreate";
import ProductFilter from "./ProductFilter";
import ProductRecords from "./ProductRecords";
import ProductModal from "./ProductModal";
import ProductActiveModal from "./ProductActiveModal";
import { ProductStockProvider } from "../../contexts/ProductStockContext";
import { ProductStockCreate } from "../ProductStockComponents/ProductStockCreate";
import { ProductStockFilter } from "../ProductStockComponents/ProductStockFilter";
import ProductStockModal from "../ProductStockComponents/ProductStockModal";
import ProductStockRecords from "../ProductStockComponents/ProductStockRecords";

const ProductContainer = () => {
    const { model, showView, setShowView, getProductsDefault } = useContext(ProductContext);
    return (
        <>
            {showView === 'products' && (
                <>
                    <h1 className="TituloContenido">Productos</h1>
                    <ProductChangeData />
                    <ProductCreate />
                    <Card title={'LISTA DE PRODUCTOS'}>
                        <ProductFilter />
                        <ProductRecords />
                    </Card>
                    <ProductModal />
                    <ProductActiveModal />
                </>
            )}
            {showView === 'stocks' && (
                <>
                    <Breadcrumb
                        style={{ marginBottom: '20px' }}
                        items={[
                            {
                                title: <div style={{ cursor: 'pointer' }}>Productos</div>,
                                onClick: () => {
                                    getProductsDefault();
                                    setShowView('products');
                                }
                            },
                            {
                                title: 'Stock',
                            },
                        ]}
                    />
                    <ProductStockProvider product={model}>
                        <ProductStockCreate />
                        <ProductStockFilter />
                        <ProductStockRecords />
                        <ProductStockModal />
                    </ProductStockProvider>
                </>
            )}
        </>
    );
};

export default ProductContainer;