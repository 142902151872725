import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import ParseHelper from "../../helpers/ParseHelper";
import { Button, Form, Input, Select } from "antd";
import RoleKeyHelper from "../../helpers/RoleKeyHelper";

const UserFilter = () => {
    const { getUsersByFilter } = useContext(UserContext);
    const onFinish = (values) => {
        const full_name = ParseHelper.parseString(values.full_name);
        const email = ParseHelper.parseString(values.email);
        const role = ParseHelper.parseString(values.role)
        getUsersByFilter({ email, full_name, role });
    };
    return (
        <Form className="FilterContainer" layout="vertical" onFinish={onFinish} autoComplete="off">
            <Form.Item name={"full_name"} label={"Filtro por nombre"}>
                <Input placeholder="Ingresa un usuario" />
            </Form.Item>
            <Form.Item name={"email"} label={"Filtro por correo electrónico"}>
                <Input placeholder="Ingresa un email" />
            </Form.Item>
            <Form.Item name={"role"} label={"Filtro por rol"}>
                <Select allowClear placeholder={'Selecciona un rol'} options={RoleKeyHelper.getSelectOptions()} style={{ width: '250px' }} />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">Buscar</Button>
            </Form.Item>
        </Form>
    );
};

export default UserFilter;