import ValidationHelper from "./ValidationHelper";

class RulesHelper {
    required() {
        return [{ required: true, message: "Campo requerido." }];
    }
    password() {
        return this.max(40);
    }
    phone() {
        return this.max(40);
    }
    fullName() {
        return this.max(50);
    }
    email() {
        return [
            { required: true, message: "Campo requerido." },
            { type: 'email', message: "Formato email requerido." },
            { max: 50, message: "El campo debe tener máximo 50 caracteres." }
        ];
    }
    max(number) {
        return [
            { required: true, message: "Campo requerido." },
            { max: number, message: `El campo debe tener máximo ${number} caracteres.` }
        ];
    }
    maxNotRequired(number) {
        return [
            { max: number, message: `El campo debe tener máximo ${number} caracteres.` }
        ];
    }
    confirmPassword() {
        return [
            { required: true, message: "Campo requerido." },
            ({ getFieldValue }) => ({
                validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('La contraseña de confirmación no es igual a la contraseña'));
                },
            })
        ];
    }
    money() {
        return [
            { required: true, message: "Campo requerido." },
            () => ({
                validator(_, value) {
                    const validation = ValidationHelper.isValidMoney(value);
                    if (!validation) {
                        return Promise.reject(new Error('El campo no tiene el formato de moneda.'));
                    }
                    return Promise.resolve();
                },
            })
        ]
    }
    weight() {
        return [
            { required: true, message: "Campo requerido." },
            () => ({
                validator(_, value) {
                    const validation = ValidationHelper.isValidMoney(value);
                    if (!validation) {
                        return Promise.reject(new Error('El campo no tiene el formato de peso.'));
                    }
                    return Promise.resolve();
                },
            })
        ]
    }
    positiveNumber() {
        return [
            { required: true, message: "Campo requerido." },
            () => ({
                validator(_, value) {
                    const validation = ValidationHelper.isPositiveInteger(value);
                    if (!validation) {
                        return Promise.reject(new Error('El campo no tiene el formato de número.'));
                    }
                    return Promise.resolve();
                },
            })
        ]
    }
}

export default new RulesHelper();