import { useMediaQuery } from "react-responsive";
import { GuestFormContext } from "../../contexts/GuestFormContext";
import ValidationHelper from "../../helpers/ValidationHelper";
import { useContext } from "react";
import { Button, Card, DatePicker, Form, Input, Select, TimePicker } from "antd";
import RulesHelper from "../../helpers/RulesHelper";

const GuestFormRoomSection = () => {

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const { form, rooms, updatePrice, verifyAvailability } = useContext(GuestFormContext);

    const onChangePrice = (event) => {

        const value = event.target.value;

        const firstValidation = ValidationHelper.hasData(value);

        if (!firstValidation) return;

        const secondValidation = ValidationHelper.isValidMoney(value);

        if (!secondValidation) return;

        const price = Number(value);

        updatePrice(price);

    };

    const onChangeRoom = (value) => {

        const room = rooms.find(element => element.value === value);

        const price = Number(room.price);

        form.setFieldValue('habitacionPrecio', price.toFixed(2));

        updatePrice(price);

    };

    const onClick = () => {
        verifyAvailability(true);
    };

    const onChange = () => {
        form.setFieldValue('habitacion', '');
        form.setFieldValue('habitacionPrecio', '');
    };

    return (
        <Card title={'Datos de la habitación'} style={{ marginBottom: '20px' }}>
            <div style={{ display: 'flex', gap: isMobile ? '0' : '20px', flexDirection: isMobile ? 'column' : 'row' }}>
                <Form.Item label="Fecha de llegada" name="start_day" rules={RulesHelper.required()}>
                    <DatePicker format="DD/MM/YYYY" style={{ width: '250px' }} onChange={onChange} />
                </Form.Item>
                <Form.Item label="Hora de llegada" name="start_time" rules={RulesHelper.required()}>
                    <TimePicker format={'HH:mm'} style={{ width: '150px' }} onChange={onChange} />
                </Form.Item>
            </div>
            <div style={{ display: 'flex', gap: isMobile ? '0' : '20px', flexDirection: isMobile ? 'column' : 'row' }}>
                <Form.Item label="Fecha de salida" name="end_day" rules={RulesHelper.required()}>
                    <DatePicker format="DD/MM/YYYY" style={{ width: '250px' }} onChange={onChange} />
                </Form.Item>
                <Form.Item label="Hora de salida" name="end_time" rules={RulesHelper.required()}>
                    <TimePicker format={'HH:mm'} style={{ width: '150px' }} onChange={onChange} />
                </Form.Item>
            </div>
            <div style={{ marginBottom: '20px' }}>
                <Button type="primary" onClick={onClick}>VERIFICAR DISPONIBILIDAD</Button>
            </div>
            <Form.Item label="Número de la habitación" name="habitacion" rules={RulesHelper.required()}>
                <Select options={rooms} onChange={onChangeRoom} />
            </Form.Item>
            <Form.Item label="Precio de la habitación" name="habitacionPrecio" rules={RulesHelper.money()} >
                <Input prefix={'S/'} onChange={onChangePrice} />
            </Form.Item>
        </Card>
    );

};

export default GuestFormRoomSection;