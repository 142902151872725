import { Modal } from "antd";
import { ConsumptionProductContext } from "../../contexts/ConsumptionProductContext";
import { useContext } from "react";

const ConsumptionProductDelete = () => {

    const {
        productSelected, setProductSelected,
        openDeleteProduct, setOpenDeleteProduct,
        deleteProductSelected
    } = useContext(ConsumptionProductContext);

    const onCancel = () => {
        setProductSelected(null);
        setOpenDeleteProduct(false);
    };

    const onOk = () => {
        deleteProductSelected(productSelected.id);
    };

    return (productSelected &&
        <Modal
            title={'Eliminar producto seleccionado'}
            open={openDeleteProduct}
            onCancel={onCancel}
            onOk={onOk}
        >
            <p>¿Está seguro que quiere eliminar el producto {productSelected.product_name} de la lista de seleccionados?</p>
        </Modal>
    );

};

export default ConsumptionProductDelete;