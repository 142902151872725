import { Button, Form, Input, message, Modal } from "antd";
import { ConsumptionProductContext } from "../../contexts/ConsumptionProductContext";
import { useContext } from "react";
import RulesHelper from "../../helpers/RulesHelper";

const ConsumptionProductModal = () => {

    const {
        modalProductSelected,
        productSelected, setModalProductSelected, setProductSelected,
        addProductSelected, updateProductSelected
    } = useContext(ConsumptionProductContext);

    const onCancel = () => {
        setModalProductSelected(false);
        setProductSelected(null);
    };

    const onFinish = (values) => {

        if (productSelected.stock < values.product_count) {
            message.warning('La cantidad ha vender debe ser menor a la cantidad del stock.');
            return;
        }

        let total = Number(values.product_count) * Number(productSelected.product_price);

        total = total.toFixed(2);

        if (productSelected.type && productSelected.type === 'edit') {
            updateProductSelected({
                id: productSelected.id,
                product_code: productSelected.product_code,
                product_name: productSelected.product_name,
                product_price: productSelected.product_price,
                stock: productSelected.stock,
                count: values.product_count,
                total
            });
        }

        if (productSelected.type && productSelected.type === 'create') {

            addProductSelected({
                id: productSelected.id,
                product_code: productSelected.product_code,
                product_name: productSelected.product_name,
                product_price: productSelected.product_price,
                stock: productSelected.stock,
                count: values.product_count,
                total
            });
        }

    };

    return (
        productSelected && (
            <Modal
                open={modalProductSelected}
                title={productSelected.type === 'edit' ? 'Editar Producto' : 'Agregar Producto'}
                onCancel={onCancel}
                footer={false}
            >
                <Form
                    layout="vertical"
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item name={"product_name"} label={"Producto"} initialValue={productSelected.product_name}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name={"product_stock"} label={"Stock"} initialValue={productSelected.stock}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name={"product_count"} label={"Cantidad"} initialValue={productSelected?.count} rules={RulesHelper.positiveNumber()}>
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">{productSelected.type === 'edit' ? 'Actualizar' : 'Agregar'}</Button>
                    </Form.Item>
                </Form>
            </Modal>
        )
    );

};

export default ConsumptionProductModal;