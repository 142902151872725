import { Modal } from "antd";
import { RoomContext } from "../../contexts/RoomContext";
import RoomTypeHelper from "../../helpers/RoomTypeHelper";
import { useContext } from "react";

const RoomActiveModal = () => {

    const { switchDataType, openDelete, model, setOpenDelete, setModel, updateRoomActive } = useContext(RoomContext);

    const onCancel = () => {
        setModel(null);
        setOpenDelete(false);
    };

    const onOk = () => {
        updateRoomActive(!switchDataType);
    };

    return (model && (
        <Modal title={switchDataType ? 'Eliminar habitación' : 'Recuperar habitación'} open={openDelete} onCancel={onCancel} onOk={onOk}>
            <p>¿Está seguro que quiere {switchDataType ? 'eliminar' : 'recuperar'} la habitación {model.room_number} tipo {RoomTypeHelper.getDescription(model.room_type)}?</p>
        </Modal>
    ));

};

export default RoomActiveModal;