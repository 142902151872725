import dayjs from "dayjs";
import ParseHelper from "../../helpers/ParseHelper";
import { Button, Card, Descriptions, Form, message, Spin } from "antd";
import BookingFormRoomSection from "./BookingFormRoomSection";
import { useContext } from "react";
import BookingFormCustomerSection from "./BookingFormCustomerSection";
import { BookingFormContext } from "../../contexts/BookingFormContext";

const BookingForm = () => {

    const { form, loading, setLoading, customerId, booking, createBooking, updateBooking, items } = useContext(BookingFormContext);

    const onFinish = (values) => {

        const {
            start_day, start_time, end_day, end_time, habitacion, habitacionPrecio,
            document_type, document_number, name, lastname, gender, phone, address, company
        } = values;

        const customer_data = {
            id: customerId,
            document_type, document_number, name, lastname, gender,
            phone: ParseHelper.parseStringOrNull(phone),
            address: ParseHelper.parseStringOrNull(address),
            company: ParseHelper.parseStringOrNull(company)
        };

        const start_date_dayjs = dayjs(`${start_day.format('YYYY-MM-DD')} ${start_time.format('HH:mm:00')}`);
        const end_date_dayjs = dayjs(`${end_day.format('YYYY-MM-DD')} ${end_time.format('HH:mm:00')}`);

        if (start_date_dayjs.isBefore(dayjs())) {
            message.error('La fecha y hora de llegada de la reserva tiene que ser mayor a la actual.');
            setLoading(false);
            return;
        }

        if (end_date_dayjs.isBefore(start_date_dayjs)) {
            message.error('La fecha y hora de salida tienen que ser después de la fecha y hora de llegada.');
            setLoading(false);
            return;
        }

        const start_date = start_date_dayjs.format(`YYYY-MM-DD HH:mm:00`);
        const end_date = end_date_dayjs.format(`YYYY-MM-DD HH:mm:00`);

        const room_id = habitacion;
        const room_price = habitacionPrecio;

        const body = { customer_data, start_date, end_date, room_id, room_price };

        setLoading(true);

        booking ? updateBooking(body) : createBooking(body);
    };

    return (
        <Spin spinning={loading}>
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                onFinish={onFinish}
            >
                <BookingFormRoomSection />
                <BookingFormCustomerSection />
                <Card title={'Resumen de reserva'} style={{ marginBottom: '20px' }}>
                    <Descriptions layout="vertical" items={items} bordered />
                </Card>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button type="primary" size="large" htmlType="submit" >{booking ? 'Actualizar' : 'Reservar'}</Button>
                </div>
            </Form>
        </Spin>
    );
};

export default BookingForm;